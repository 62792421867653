import { useEffect, useState } from "react";
import httpsClient from "../config/https";
import { useMessagesContext, MessageType } from "../contexts/messagesContext";
import Loader from "../components/Loader";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "react-i18next";

export default function ActiveProfile() {
  const { addMessage } = useMessagesContext();
  const [user, setUser] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [invitedBy, setInvitedBy] = useState();
  const { t } = useTranslation();

  const getUser = async () => {
    await httpsClient
      .get(`/api/v1/user`)
      .then((response) => {
        const invitedByUser = response.data?.supervisors[0] && response.data?.supervisors[0].supervisors?.users;
        const invitedBy = (invitedByUser?.first_name || invitedByUser?.last_name) ? `${invitedByUser.first_name || ''} ${invitedByUser.last_name || ''} (${invitedByUser?.email})`.trim() : invitedByUser?.email;
        setUser(response.data);
        setInvitedBy(invitedBy);
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
        addMessage({
          type: MessageType.Error,
          title: "common.error.title",
          description: "common.error.description",
        });
      });
  };

  const setStudent = async () => {
    try {
      setIsUpdating(true);
      await httpsClient.patch("/api/v1/user", {
        students: {
          update: {
            data: {
              is_primary: true,
            },
            where: { id: user?.students[0]?.id },
          },
        },
      });
      window.location.reload();
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const setSupervisor = async () => {
    try {
      setIsUpdating(true);
      await httpsClient.patch("/api/v1/user", {
        supervisors: {
          update: {
            data: {
              is_primary: true,
            },
            where: { id: user?.supervisors[0]?.id },
          },
        },
      });
      window.location.reload();
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl text-center">
        {isFetching && (<div className="flex justify-center items-center"><Loader /></div>)}
        {!isFetching && user?.supervisors?.length > 0 && !user?.supervisors[0]?.is_primary && user?.students?.length > 0 && !user?.students[0]?.is_primary && (
          <>
            <AcademicCapIcon className="mx-auto h-12 w-12 text-gray-400 mb-6" />
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              {t('activeProfile.supervisor.title')}
            </h2>
            <p className="mx-auto mt-6 max-w-3xl text-gray-500 text-gray-500">
              <Trans
                i18nKey="activeProfile.supervisor.description"
                components={[<span className="font-semibold" />]}
                values={{ invitedBy }}
              />
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button onClick={setStudent} className="text-sm font-semibold leading-6 disabled:opacity-50" disabled={isUpdating}>
                {t('activeProfile.supervisor.studentRole')}
              </button>
              <button onClick={setSupervisor}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                disabled={isUpdating}
              >
                {t('activeProfile.supervisor.continue')}
              </button>
              {isUpdating && (<Loader />)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
