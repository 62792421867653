// FeedbackForm.js
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import httpsClient, { addAccessTokenInterceptor } from "../config/https";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { MessageType, useMessagesContext } from "../contexts/messagesContext";

export default function Course() {
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [message, setMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addMessage } = useMessagesContext();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsFetching(true);
    try {
      await httpsClient.post("/api/v1/feedback", {
        message,
      });
      setIsFetching(false);
      addMessage({
        type: MessageType.Success,
        title: "feedback.success.title",
        description: "feedback.success.description",
      });
      navigate("/?feedback=sent");
    } catch {
      setIsFetching(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return (
    <>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("feedback.title")}</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">{t("feedback.description")}</p>
      </div>
      {(isFetching || isLoading) && (
        <div className="flex justify-center items-center pt-12">
          <Loader />
        </div>
      )}
      {!isFetching && !isLoading && (
        <form autoComplete="off" className="mx-auto mt-12 max-w-xl">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("feedback.email")}
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={user?.email}
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                  disabled
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("feedback.message")} *
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
              disabled={!message}
              onClick={handleSubmit}
            >
              {t("feedback.button")}
            </button>
          </div>
        </form>
      )}
    </>
  );
}
