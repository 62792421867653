import { BookmarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

export default function NoSavedItems() {
  const { t } = useTranslation();

  return (
    <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-200 my-6 p-6 text-center hover:border-gray-300 focus:outline-none">
      <BookmarkIcon className="mx-auto h-7 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("common.noItems")}</h3>
    </div>
  );
}
