import axios from 'axios';
import {Auth0Client} from '@auth0/auth0-spa-js';
import {AdminCompaniesApiFactory, AdminCompanyExpertsApiFactory, AdminTopicsApiFactory, Configuration} from "./generated";
import {getConfig} from "../config";
import AdminCompanyUsers from "../containers/admin/companies/AdminCompanyUsers";

const config = getConfig();

const auth0 = new Auth0Client({
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
  cookieDomain: process.env.REACT_APP_AUTH0_COOKIE_DOMAIN,
});
// configuration, base path, axios instance
const configuration = new Configuration({
  accessToken: async () => {
    return await auth0.getTokenSilently();
  },
});
const basePath = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  AdminCompanies: AdminCompaniesApiFactory(configuration, basePath, axiosInstance),
  AdminCompanyExperts: AdminCompanyExpertsApiFactory(configuration, basePath, axiosInstance),
  AdminTopics: AdminTopicsApiFactory(configuration, basePath, axiosInstance),
}
