import React, { createContext, useState, useContext } from "react";

export enum MessageType {
  Success = "success",
  Error = "error",
  Warning = "warning"
}

interface Message {
  id: string;
  type: MessageType;
  title?: string;
  description?: string;
  retain?: boolean;
}

interface MessagesContextType {
  messages: Message[];
  addMessage: (message: Omit<Message, "id"> | Omit<Message, "id">[]) => void;
  removeMessage: (id: string) => void;
}

const MessagesContext = createContext<MessagesContextType | null>(null);

export const useMessagesContext = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error("useMessagesContext must be used within a MessagesProvider");
  }
  return context;
};

export const MessagesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const generateId = (): string => {
    return Math.random().toString(36).substring(2, 11);
  };

  const addMessage = (message: Omit<Message, "id"> | Omit<Message, "id">[]) => {
    if (Array.isArray(message)) {
      setMessages(() => [...message].map((e) => ({ ...e, id: generateId() })));
    } else {
      setMessages((prevMessages: any) => [...prevMessages, { ...message, id: generateId() }]);
    }
  };

  const removeMessage = (id: string) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
  };

  return (
    <MessagesContext.Provider value={{ messages, addMessage, removeMessage }}>{children}</MessagesContext.Provider>
  );
};

export default MessagesContext;
