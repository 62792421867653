import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { PlusIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { PencilIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";

export default function ExpertDashboard() {
  const { currentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [publishedTopics, setPublishedTopics] = useState([]);
  const [draftTopics, setDraftTopics] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getExpertTopics = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v2/expert/topics?type=me`);
      setPublishedTopics(response.data.items?.filter((e: any) => e.published));
      setDraftTopics(response.data.items?.filter((e: any) => !e.published));
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // if (currentUser?.id) {
      getExpertTopics();
    // }
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl text-center pb-12">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {t("dashboard.companies.welcome.title")}
        </h2>
        <p className="mx-auto mt-6 leading-6 text-gray-500">
          {t("dashboard.companies.welcome.description")}
        </p>
      </div>
      <div className="text-center py-12">
        <div className="mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:items-start justify-start lg:gap-12">
            <div className="pb-12 lg:pr-6 lg:pb-0">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.companies.profile.title")}
              </h2>
              <p className="mx-auto mt-6 leading-6 text-gray-500 text-left text-sm">
                {t("dashboard.companies.profile.description")}
              </p>
              <ul role="list" className="mt-6 max-w-xl space-y-6 text-gray-600 text-left">
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900 text-sm">
                      {t("dashboard.companies.profile.item1.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.companies.profile.item1.description")}</p>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-sm text-gray-900">
                      {t("dashboard.companies.profile.item2.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.companies.profile.item2.description")}</p>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="mt-0.5 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-sm text-gray-900">
                      {t("dashboard.companies.profile.item3.title")}
                    </strong>
                    <p className="text-sm text-gray-500">{t("dashboard.companies.profile.item3.description")}</p>
                  </span>
                </li>
              </ul>
              <div className="mt-12 text-center">
                <NavLink
                  to="/user/profile"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("dashboard.companies.profile.button")}
                </NavLink>
              </div>
            </div>
            <div className="pt-12 lg:pl-6 lg:pt-0">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {t("dashboard.companies.activity.title")}
              </h2>
              {isAuthenticated && !isLoading && isFetching && (
                <div className="my-6 flex justify-center items-center">
                  <Loader />
                </div>
              )}
              {isAuthenticated && !isLoading && !isFetching && (
                <div className="text-left pt-8">
                  <ul role="list" className="divide-y divide-gray-200 border-b border-gray-200">
                    <li key={"1"}>
                      <div className="group relative flex items-start space-x-3 pb-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-pink-500">
                            <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/user/topics/new">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {t("dashboard.companies.activity.publishedTopics.title")}
                            </NavLink>
                          </div>
                          {publishedTopics.length === 0 && (
                            <p className="text-sm text-gray-500">
                              {t("dashboard.companies.activity.publishedTopics.create.description")}
                            </p>
                          )}
                          {publishedTopics.length > 0 && (
                            <p className="text-sm text-gray-500">
                              <Trans
                                i18nKey={
                                  publishedTopics.length === 1
                                    ? "dashboard.companies.activity.publishedTopics.preview.single.description"
                                    : "dashboard.companies.activity.publishedTopics.preview.multiple.description"
                                }
                                components={[<span className="font-semibold" />]}
                                values={{ count: publishedTopics.length }}
                              />
                            </p>
                          )}
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                    {draftTopics.length > 0 && (
                      <li key={"2"}>
                        <div className="group relative flex items-start space-x-3 py-4">
                          <div className="flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500">
                              <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div className="text-sm font-semibold text-gray-900">
                              <NavLink to="/user/topics">
                                <span className="absolute inset-0" aria-hidden="true" />
                                {t("dashboard.companies.activity.draftTopics.title")}
                              </NavLink>
                            </div>
                            <p className="text-sm text-gray-500">
                              <Trans
                                i18nKey={
                                  draftTopics.length === 1
                                    ? "dashboard.companies.activity.draftTopics.preview.single.description"
                                    : "dashboard.companies.activity.draftTopics.preview.multiple.description"
                                }
                                components={[<span className="font-semibold" />]}
                                values={{ count: draftTopics.length }}
                              />
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center">
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </li>
                    )}
                    <li key={"3"}>
                      <div className="group relative flex items-start space-x-3 py-4">
                        <div className="flex-shrink-0">
                          <span className="inline-flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500">
                            <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="text-sm font-semibold text-gray-900">
                            <NavLink to="/company/experts">
                              <span className="absolute inset-0" aria-hidden="true" />
                              {currentUser.role === "admin"
                                ? t("dashboard.companies.activity.inviteExperts.admin.title")
                                : t("dashboard.companies.activity.inviteExperts.expert.title")}
                            </NavLink>
                          </div>
                          {currentUser.role === "admin" && (
                            <p className="text-sm text-gray-500">
                              {t("dashboard.companies.activity.inviteExperts.admin.description")}
                            </p>
                          )}
                          {currentUser.role !== "admin" && (
                            <p className="text-sm text-gray-500">
                              {t("dashboard.companies.activity.inviteExperts.expert.description")}
                            </p>
                          )}
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-6 flex">
                    <NavLink to="/company/topics" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      {t("dashboard.companies.activity.previewAllTopics")}
                      <span aria-hidden="true"> &rarr;</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
