import React, {PropsWithChildren} from "react";

// Define the prop types
export interface BreadcrumbProps {
  className?: string;
}

// Functional component with props
const Breadcrumb: React.FC<PropsWithChildren<BreadcrumbProps>> = ({className = '', children}) => {
  return (
    <nav aria-label="Breadcrumb" className={`${className}`}>
      {children}
    </nav>
  );
};

// Export the component
export default React.memo(Breadcrumb);
