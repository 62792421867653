import { mixed, object, string } from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useRef, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { CheckBadgeIcon, ChevronRightIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import TruncateText from "../../components/TruncateText";
import { ThesisProjectState } from "../../models";
import { isMobile } from "react-device-detect";
import { TextEditor } from "../../components/TipTap/TextEditor";

export const ThesisProjectFormStudents: React.FC<any> = () => {
  const { id } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetchingState, setIsFetchingState] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isFetchingTopic, setIsFetchingTopic] = useState(false);
  const [isFetchingThesisProject, setIsFetchingThesisProject] = useState(false);
  const [topic, setTopic] = useState<any>({});
  const [userThesisProjectsState, setUserThesisProjectsState] = useState<any>();
  const [userEligible, setUserEligible] = useState(false);
  const [company, setCompany] = useState<any>({});
  const [thesisProject, setThesisProject] = useState<any>({});
  const [student, setStudent] = useState<any>({});
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const { addMessage } = useMessagesContext();
  const cvInputRef = useRef(null);
  const [cv, setCV] = useState<any>();
  const [cvError, setCVError] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const topic_id = queryParams.get("topic");

  const validationSchema = object().shape({
    title: thesisProject.id ? string().required(t("common.required")) : string(),
    description: thesisProject.id ? string().required(t("common.required")) : string(),
    first_name: string().required(t("common.required")),
    last_name: string().required(t("common.required")),
    field_of_study: string().required(t("common.required")),
    motivation: string().required(t("common.required")),
    notes: string().required(t("common.required")),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      topic_id: "",
      title: "",
      description: "",
      first_name: "",
      last_name: "",
      field_of_study: "",
      motivation: "",
      notes: "",
    },
  });

  const getUser = async () => {
    try {
      setIsFetchingUser(true);
      const response = await httpsClient.get(`/api/v1/user`);
      setStudent(response.data);
      setIsFetchingUser(false);
    } catch {
      setIsFetchingUser(false);
    }
  };

  const getThesisProject = async () => {
    try {
      setIsFetchingThesisProject(true);
      const response = await httpsClient.get(`/api/v1/thesis-projects/${id}`);
      setThesisProject(response.data);
      setCompany(response.data.companies);
      setIsFetchingThesisProject(false);
    } catch (error: any) {
      setIsFetchingThesisProject(false);
      if (error?.response?.status === 403) {
        navigate("/error/forbidden");
      }
    }
  };

  const getUserThesisProjectsState = async () => {
    setIsFetchingState(true);
    await httpsClient
      .get(`/api/v1/user/thesis-projects/state?topic_id=${topic_id}`)
      .then((response) => {
        setUserThesisProjectsState(response.data);
        setIsFetchingState(false);
      })
      .catch((error) => {
        setIsFetchingState(false);
      });
  };

  const getTopic = async () => {
    try {
      setIsFetchingTopic(true);
      const response = await httpsClient.get(`/api/v1/topics/${topic_id}`);
      setTopic(response.data);
      setCompany(response.data.company);
      setIsFetchingTopic(false);
    } catch {
      setIsFetchingTopic(false);
    }
  };

  const createThesisProject = async (data: any) => {
    try {
      setIsUpdating(true);
      const _response = await httpsClient.post(`/api/v1/thesis-projects/presignedUrl`, {
        path: "cv",
        name: cv.name,
      });
      await axios.put(_response.data.signedUrl, cv, {
        headers: {
          "Content-Type": cv.type,
        },
      });
      await httpsClient.patch("/api/v1/user", {
        first_name: data?.first_name,
        last_name: data?.last_name,
        students: {
          update: {
            data: {
              field_of_study: data.field_of_study,
            },
            where: { id: student?.students[0]?.id },
          },
        },
      });
      const response = await httpsClient.post("/api/v1/thesis-projects/", {
        topic_id: data.topic_id,
        motivation: data.motivation,
        notes: data.notes,
        cv_file: _response.data?.key,
        cv_filename: cv.name,
      });
      setIsUpdating(false);
      reset();
      setCV(null);
      addMessage({
        type: MessageType.Success,
        title: "thesisProjects.apply.notification.title",
        description: "thesisProjects.apply.notification.description",
        retain: true,
      });
      navigate(`/user/thesis-projects/${response.data?.id}`);
    } catch {
      setIsUpdating(false);
      await getUserThesisProjectsState();
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const updateThesisProject = async (data: any) => {
    try {
      setIsUpdating(true);
      const _data: any = {
        title: data.title,
        description: data.description,
        motivation: data.motivation,
        notes: data.notes,
      };
      if (cv) {
        const _response = await httpsClient.post(`/api/v1/thesis-projects/presignedUrl`, {
          path: "cv",
          name: cv.name,
        });
        await axios.put(_response.data.signedUrl, cv, {
          headers: {
            "Content-Type": cv.type,
          },
        });
        _data.cv_file = _response.data?.key;
        _data.cv_filename = cv.name;
      }
      await httpsClient.patch("/api/v1/user", {
        first_name: data?.first_name,
        last_name: data?.last_name,
        students: {
          update: {
            data: {
              field_of_study: data.field_of_study,
            },
            where: { id: student?.students[0]?.id },
          },
        },
      });
      await httpsClient.patch(`/api/v1/thesis-projects/${id}`, _data);
      setIsUpdating(false);
      addMessage({
        type: MessageType.Success,
        title: "thesisProjects.update.notification.title",
        description: "thesisProjects.update.notification.description",
      });
      navigate(`/user/thesis-projects/${id}`);
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onCancel = () => {
    if (topic_id) {
      navigate(`/topics?id=${topic_id}`);
    }
    if (id) {
      navigate(`/user/thesis-projects/${id}`);
    }
  };

  const onSubmit = (data: any) => {
    if (!cv && !thesisProject.cv_file) {
      setCVError(true);
      return;
    }
    if (id) {
      updateThesisProject(data);
    }
    if (topic_id) {
      createThesisProject(data);
    }
  };

  const handleCVClick = () => {
    if (cvInputRef.current) {
      (cvInputRef.current as HTMLInputElement).click();
    }
  };

  const handleCVChange = (event: any) => {
    setCVError(false);
    setCV(event.target.files[0]);
  };

  const customValidation = (data: any) => {
    if (!cv && !thesisProject.cv_file) {
      setCVError(true);
    }
    return {};
  };

  const getResourcePresignedUrl = async (key: string, e: any) => {
    e.preventDefault();
    await httpsClient
      .post(`/api/v1/thesis-projects/resources/presignedUrl`, { key })
      .then((response) => {
        window.open(response.data.signedUrl, "_blank");
      })
      .catch(() => {});
  };

  const bytesToSize = (bytes: number): string => {
    const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    setUserEligible(
      (thesisProject?.id &&
        (thesisProject.state === ThesisProjectState.Agreed || thesisProject.state === ThesisProjectState.Applied)) ||
        (userThesisProjectsState?.eligible && userThesisProjectsState.topic?.eligible)
    );
  }, [thesisProject, userThesisProjectsState]);

  useEffect(() => {
    const defaultValues: any = {};
    if (topic_id) {
      defaultValues.topic_id = topic_id;
    }
    if (id) {
      defaultValues.topic_id = thesisProject.topic_id;
      defaultValues.motivation = thesisProject.motivation;
      defaultValues.notes = thesisProject.notes;
      defaultValues.title = thesisProject.title;
      defaultValues.description = thesisProject.description;
    }
    defaultValues.first_name = student.first_name;
    defaultValues.last_name = student.last_name;
    defaultValues.field_of_study =
      student.students && student.students[0] ? student.students[0].field_of_study : undefined;
    reset({ ...defaultValues });
  }, [student, topic, thesisProject]);

  useEffect(() => {
    getUser();
    if (topic_id) {
      getUserThesisProjectsState();
      getTopic();
    }
    if (id) {
      getThesisProject();
    }
  }, [currentUser]);

  return (
    <div className="bg-white">
      <nav aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <NavLink to={`/user/profile`} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                {t("common.myProfile")}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <NavLink
                to={"/user/thesis-projects"}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {t("common.myThesisProjects")}
              </NavLink>
            </div>
          </li>
          {id && !isFetchingThesisProject && !isFetchingTopic && (
            <li className="overflow-hidden">
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <NavLink
                  to={`/user/thesis-projects/${id}`}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 truncate"
                >
                  {thesisProject?.title}
                </NavLink>
              </div>
            </li>
          )}
        </ol>
      </nav>
      {(isFetchingUser || isFetchingState || isFetchingTopic || isFetchingThesisProject) && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated &&
        !isFetchingState &&
        !isLoading &&
        !isFetchingUser &&
        !isFetchingTopic &&
        !isFetchingThesisProject && (
          <>
            <div className="pt-6">
              {!userThesisProjectsState?.eligible && userThesisProjectsState?.topic?.eligible && (
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700 font-medium">{t("topics.topic.apply.notEligible")}</p>
                    </div>
                  </div>
                </div>
              )}
              {!userThesisProjectsState?.topic?.eligible &&
                userThesisProjectsState?.topic?.thesis_projects?.length === 0 && (
                  <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-yellow-700">
                          {t("topics.topic.apply.notAvailable.title")}{" "}
                          <button
                            // onClick={onBookmark}
                            className="font-medium text-left text-yellow-700 underline hover:text-yellow-600"
                          >
                            {t("topics.topic.apply.notAvailable.action")}
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <form autoComplete="off">
              <div className="">
                {!thesisProject.id && (
                  <div className="py-6">
                    <div className="flex items-start gap-x-6">
                      {company?.picture && (
                        <img
                          className="h-16 w-16 object-contain"
                          src={`https://resources.thesisnavigator.com/${company?.picture}`}
                          alt=""
                        />
                      )}
                      <h1>
                        <NavLink
                          to={`/companies/${company?.id}`}
                          className="whitespace-nowrap flex items-center gap-x-1 mb-2 hover:opacity-75"
                        >
                          <span className="font-semibold text-xs text-gray-900">{company?.name}</span>{" "}
                          <CheckBadgeIcon className="h-5 text-yellow-400" />
                        </NavLink>
                        <div className="mt-2 text-xl font-bold leading-6 text-gray-900">
                          {topic.title || thesisProject.title}
                        </div>
                      </h1>
                    </div>
                    <p className="pt-12 text-sm leading-6 text-gray-500 lg:col-span-7 whitespace-pre-line">
                      {topic.description && <TruncateText maxLength={isMobile ? 100 : 600} text={topic.description} />}
                      {thesisProject.description && (
                        <TruncateText maxLength={isMobile ? 100 : 600} text={thesisProject.description} />
                      )}
                    </p>
                  </div>
                )}
                {thesisProject.id && (
                  <div className="py-6">
                    <div className="flex items-start gap-x-6">
                      {company?.picture && (
                        <img
                          className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                          src={`https://resources.thesisnavigator.com/${company?.picture}`}
                          alt=""
                        />
                      )}
                      <div className="w-full">
                        <NavLink
                          to={`/companies/${thesisProject?.companies?.id}`}
                          className="whitespace-nowrap flex items-center gap-x-1 mb-2 hover:opacity-75"
                        >
                          <span className="font-semibold text-xs text-gray-900">{thesisProject?.companies?.name}</span>{" "}
                          <CheckBadgeIcon className="h-5 text-yellow-400" />
                        </NavLink>
                        <div>
                          <input
                            type="text"
                            autoComplete="given-name"
                            placeholder={t("thesisProjects.title.placeholder")}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                            {...register("title")}
                            disabled={!userEligible}
                          />
                          {errors.title && (
                            <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  {thesisProject.id && (
                    <div className="sm:col-span-full">
                      <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("thesisProjects.description.title")}
                      </label>
                      <div className="mt-2">
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <TextEditor
                              content={field.value}
                              placeholder={t("thesisProjects.description.placeholder")}
                              onChange={field.onChange}
                              disabled={!userEligible}
                            />
                          )}
                          name="description"
                        />
                        {errors.description && (
                          <p className="mt-2 text-xs text-red-600">{errors.description?.message?.toString()}</p>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.firstName")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        autoComplete="given-name"
                        placeholder={t("profile.user.firstName")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        {...register("first_name")}
                        disabled={!userEligible}
                      />
                      {errors.first_name && (
                        <p className="mt-2 text-xs text-red-600">{errors.first_name?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.lastName")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.user.lastName")}
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        {...register("last_name")}
                        disabled={!userEligible}
                      />
                      {errors.last_name && (
                        <p className="mt-2 text-xs text-red-600">{errors.last_name?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.student.info.field")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.student.info.field")}
                        className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        {...register("field_of_study")}
                        disabled={!userEligible}
                      />
                      {errors.field_of_study && (
                        <p className="mt-2 text-xs text-red-600">{errors.field_of_study?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="cv" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("thesisProjects.cv.title")}
                    </label>
                    <div className="mt-2 flex items-center gap-x-4 w-full rounded-md border-0 py-2.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-sm leading-6">
                      <input
                        type="file"
                        ref={cvInputRef}
                        onChange={handleCVChange}
                        className="hidden"
                        accept=".pdf,application/pdf"
                      />
                      <button
                        type="button"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                        onClick={handleCVClick}
                        disabled={!userEligible}
                      >
                        {thesisProject.cv_file
                          ? t("thesisProjects.cv.button.change")
                          : t("thesisProjects.cv.button.upload")}
                      </button>
                      <>
                        {cv?.name && (
                          <div className="text-sm leading-6 text-gray-900">
                            <span>{cv.name}</span>
                            <span className="pl-2">({bytesToSize(cv.size)})</span>
                          </div>
                        )}
                        {!cv?.name && (
                          <div className="block text-sm leading-6 text-gray-400">
                            {t("thesisProjects.cv.placeholder")}
                          </div>
                        )}
                      </>
                    </div>
                    <p className="mt-2 text-xs text-gray-400">{t("thesisProjects.cv.info")}</p>
                    {cvError && <p className="mt-2 text-xs text-red-600">{t("common.required")}</p>}
                    {thesisProject.cv_file && thesisProject.cv_filename && (
                      <div className="flex items-center py-4">
                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <button
                          onClick={(e: any) => getResourcePresignedUrl(thesisProject.cv_file, e)}
                          className="font-medium text-sm text-indigo-600 hover:text-indigo-500 ml-2 text-left"
                        >
                          {thesisProject.cv_filename}
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="sm:col-span-full">
                    <div className="flex items-center gap-x-2">
                      <label htmlFor="motivation" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("thesisProjects.motivation.title")}
                      </label>
                      <div className="inline-block relative group/motivation">
                        <InformationCircleIcon
                          className="text-gray-300 hover:text-gray-400 w-5 flex-shrink-0 z-10 cursor-pointer"
                          aria-hidden="true"
                        />
                        <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/motivation:opacity-100">
                          {t("thesisProjects.motivation.placeholder")}
                          <div className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <TextEditor
                            content={field.value}
                            placeholder={t("thesisProjects.motivation.placeholder")}
                            onChange={field.onChange}
                            disabled={!userEligible}
                          />
                        )}
                        name="motivation"
                      />
                      {errors.motivation && (
                        <p className="mt-2 text-xs text-red-600">{errors.motivation?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <div className="flex items-center gap-x-2">
                      <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
                        {t("thesisProjects.notes.title")}
                      </label>
                      <div className="inline-block relative group/notes">
                        <InformationCircleIcon
                          className="text-gray-400 hover:text-gray-500 w-5 flex-shrink-0 z-10 cursor-pointer"
                          aria-hidden="true"
                        />
                        <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/notes:opacity-100">
                          {t("thesisProjects.notes.placeholder")}
                          <div className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <TextEditor
                            content={field.value}
                            placeholder={t("thesisProjects.notes.placeholder")}
                            onChange={field.onChange}
                            disabled={!userEligible}
                          />
                        )}
                        name="notes"
                      />
                      <p
                        className="mt-2 text-xs text-gray-400"
                        dangerouslySetInnerHTML={{ __html: t("thesisProjects.notes.info") }}
                      />
                      {errors.notes && <p className="mt-2 text-xs text-red-600">{errors.notes?.message?.toString()}</p>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                {isUpdating && <Loader />}
                {!isUpdating && (
                  <>
                    <button type="button" onClick={onCancel} className="text-sm font-semibold leading-6 text-gray-900">
                      {t("common.cancel")}
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit, customValidation)}
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                      disabled={!userEligible}
                    >
                      {id ? t("common.save") : t("common.apply")}
                    </button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
    </div>
  );
};
