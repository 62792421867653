import { CheckCircleIcon, XCircleIcon, XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { MessageType, useMessagesContext } from "../contexts/messagesContext";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const Messages: React.FC = () => {
  const { messages, removeMessage } = useMessagesContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (messages.filter((e: any) => !e.retain).length > 0) {
      const timer = setTimeout(() => {
        removeMessage(messages.filter((e: any) => !e.retain)[0].id);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [messages, removeMessage]);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end top-20 px-4 py-6 sm:items-start sm:p-6 z-50"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {messages.map((message: any) => (
          <Transition
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {message.type === MessageType.Success ? (
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    ) : message.type === MessageType.Error ? (
                      <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    ) : message.type === MessageType.Warning ? (
                      <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
                    ) : null}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {message.title && <p className="text-sm font-semibold text-gray-900">{t(message.title)}</p>}
                    {message.description && (
                      <p
                        className="mt-2 text-sm text-gray-500"
                        dangerouslySetInnerHTML={{ __html: t(message.description) }}
                      />
                    )}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        removeMessage(message.id);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {message.retain && (
                  <div className="mt-3 flex">
                    <button
                      type="button"
                      className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none ml-auto"
                      onClick={() => {
                        removeMessage(message.id);
                      }}
                    >
                      {t("common.close")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default Messages;
