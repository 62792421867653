import React, { PropsWithChildren } from "react";
import { CalendarIcon } from "@heroicons/react/24/solid";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { User } from "@api/generated";

// Define the prop types
interface ListItemLineStatusProps {
  created?: Date | string;
  updated?: Date | string;
  users?: User[] | null;
  experts?: number | null;
  className?: string;
}

// Functional component with props
const ListItemLineStatus: React.FC<PropsWithChildren<ListItemLineStatusProps>> = ({ created, updated, users, experts, className = '', children }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className={`flex flex-wrap gap-x-4 gap-y-1 items-center ${className}`}>
      <div className="flex items-center text-sm text-gray-400 space-x-4">
        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
        {(!updated || created === updated) && <>{moment(created).locale(currentLanguage).format("lll")}</>}
        {updated && created !== updated && (
          <>
            {t("common.updated")} {moment(updated).locale(currentLanguage).format("lll")}
          </>
        )}
      </div>
      {users && (
        <div className="flex overflow-hidden -space-x-1.5 flex-shrink-0">
          {users.map((user) =>
            user.image ? (
              <img
                className="inline-block h-6 w-6 rounded-full border object-cover"
                src={`https://resources.thesisnavigator.com/${user.image}`}
                alt=""
              />
            ) : (
              <img
                className="inline-block h-6 w-6 rounded-full border object-cover"
                src={user.picture || undefined}
                alt=""
              />
            )
          )}
        </div>
      )}
      {experts > 0 && (
        <div className="flex items-center text-sm text-gray-400">
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300">
            <circle cx={1} cy={1} r={1} />
          </svg>
          <span className="px-1">
            {experts}
          </span>
          {experts === 1 ? t("common.expert") : t("common.experts")}
        </div>
      )}
    </div>
  );
};

// Export the component
export default React.memo(ListItemLineStatus);
