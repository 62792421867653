import React, {PropsWithChildren} from "react";
import {BuildingOffice2Icon} from "@heroicons/react/24/solid";

// Define the prop types
interface ListItemAvatarProps {
  avatar?: string | null;
  className?: string;
}

// Functional component with props
const ListItemAvatar: React.FC<PropsWithChildren<ListItemAvatarProps>> = ({avatar, className = '', children}) => {
  return (
    <div className={`mr-6 sm:mr-8 flex-shrink-0 ${className}`}>
      <div className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75">
        {avatar && (
          <img
            className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
            src={`https://resources.thesisnavigator.com/${avatar}`}
            alt=""
          />
        )}
        {!avatar && (
          <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
            <BuildingOffice2Icon className="fill-gray-200"/>
          </div>
        )}
      </div>
    </div>
  );
};

// Export the component
export default React.memo(ListItemAvatar);
