import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { useTranslation } from "react-i18next";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import "moment/locale/de";
import { ArrowPathIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import ConfirmationDialog, { ConfirmationDialogType } from "../../components/ConfirmationDialog";
import Modal from "../../components/Modal";
import { useMessagesContext, MessageType } from "../../contexts/messagesContext";

export default function CompanyExperts() {
  const { currentUser } = useContext(CurrentUserContext);
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    email: string()
      .email()
      .test("email-domain", "Email domain is not allowed", (value) => {
        if (!value) return true;
        const [, domain] = value.split("@");
        return domains.some((allowedDomain: string) =>
          domain === allowedDomain || domain?.endsWith(`.${allowedDomain}`)
        );
      }),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: null,
      role: "expert",
    },
  });

  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [company, setCompany] = useState<any>({});
  const [selectedIndustries, setSelectedIndustries] = useState<any>([]);
  const [domains, setDomains] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [expertIdToDelete, setExpertIdToDelete] = useState();
  const [expertEmailToDelete, setExpertEmailToDelete] = useState();
  const [isDeleteExpertConfirmationOpen, setIsDeleteExpertConfirmationOpen] = useState<boolean>(false);

  const getCompany = async () => {
    try {
      const response = await httpsClient.get(`/api/v1/company`);
      response.data.name = response.data.name;
      response.data.description = response.data.description;
      response.data.uid = response.data.uid;
      setCompany(response.data);
      const _selectedIndustries: any = {};
      response.data.company_industries?.forEach((e: any) => (_selectedIndustries[e.industry_id] = true));
      setSelectedIndustries(_selectedIndustries);
      const _domains: any = response.data.company_domains.map((e: any) => e.domain) || [];
      setDomains(_domains);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const reloadCompany = async () => {
    setIsFetching(true);
    getCompany();
  };

  const addExpert = async (data: any) => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.post("/api/v1/admin/expert", {
        email: data?.email,
        role: data?.role === "owner" ? "owner" : (data?.role === "admin" ? "admin" : null),
      });
      if (currentUser?.id) {
        getCompany();
      }
      reset({ email: null });
      setIsUpdating(false);
    } catch {
      setIsUpdating(false);
    }
  };

  const deleteExpert = async () => {
    try {
      closeDeleteExpertConfirmation();
      setIsUpdating(true);
      await httpsClient.delete(`/api/v1/admin/expert/${expertIdToDelete}`);
      if (currentUser?.id) {
        getCompany();
      }
      setIsUpdating(false);
    } catch {
      setIsUpdating(false);
    }
  };

  const onSubmit = (data: any) => {
    addExpert(data);
  };

  const closeDeleteExpertConfirmation = () => {
    setIsDeleteExpertConfirmationOpen(false);
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentUser?.id) {
      getCompany();
    }
  }, [currentUser]);

  return (
    <div className="bg-white">
      <div className="pb-12 sm:pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("company.experts")}</h2>
        </div>
      </div>
      {isAuthenticated && !isLoading && (
        <>
          {["owner", "admin"].includes(currentUser?.role) && (
            <>
              <div className="text-center">
                <UserGroupIcon className="mx-auto h-12 w-12 text-gray-400" />

                <p className="mt-1 text-sm text-gray-500">
                  <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">{t("company.team.title")}</h2>
                  <p className="mx-auto max-w-4xl">{t("company.team.subtitle")}</p>
                  <p className="text-xs pt-1">
                    {t("company.team.domainValidation")}{" "}
                    <span className="font-bold text-gray-700">{domains.join(", ")}</span>.
                  </p>
                </p>
              </div>
              <form className="my-6 sm:flex sm:items-center" action="#">
                <label htmlFor="emails" className="sr-only">
                  Email addresses
                </label>
                <div className="grid grid-cols-1 sm:flex-auto">
                  <input
                    type="email"
                    id="email"
                    className="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 text-xs"
                    placeholder={`${t("company.team.placeholder")} ${domains.join(", ")}`}
                    {...register("email")}
                    onPaste={() => trigger}
                    autoComplete="off"
                  />
                  {errors.email && <p className="mt-2 text-xs text-red-600">{errors.email?.message?.toString()}</p>}
                  <div
                    className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                    aria-hidden="true"
                  />
                  <div className="col-start-2 row-start-1 flex items-center">
                    <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true" />
                    <label htmlFor="role" className="sr-only">
                      Role
                    </label>
                    <select
                      id="role"
                      className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 font-semibold cursor-pointer"
                      {...register("role")}
                      defaultValue={getValues("role")}
                    >
                      <option value="expert">Expert</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                </div>
                <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                  {isUpdating && <Loader />}
                  {!isUpdating && (
                    <>
                      <button
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-60"
                        disabled={!isValid || !getValues("email")}
                      >
                        {t("company.team.button")}
                      </button>
                    </>
                  )}
                </div>
                <button
                  type="button"
                  className="hidden sm:inline-block ml-6 h-6 w-6 text-gray-400 hover:text-gray-600"
                  onClick={() => reloadCompany()}
                >
                  <ArrowPathIcon />
                </button>
              </form>
            </>
          )}
          {isFetching && (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          )}
          {!isFetching && (
            <ul role="list" className="divide-y divide-gray-100">
              {company?.company_experts?.map((e: any) => (
                <li key={e.id} className="flex justify-between gap-x-6 py-6">
                  <div className="flex shrink-0 items-center min-w-0 gap-x-4">
                    {e.experts.users.image ? (
                      <img
                        className="w-12 h-12 rounded-full border object-cover"
                        src={`https://resources.thesisnavigator.com/${e.experts.users.image}`}
                        alt=""
                      />
                    ) : (
                      <img className="w-12 h-12 flex-none rounded-full" src={e.experts.users.picture} alt="" />
                    )}
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-center space-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {(e.experts.users.first_name || e.experts.users.last_name) && (
                            <>
                              {e.experts.users.first_name} {e.experts.users.last_name}
                            </>
                          )}
                          {!e.experts.users.first_name && !e.experts.users.last_name && (
                            <>{e.experts.users.email.split("@")[0]}</>
                          )}
                        </p>
                        {e?.role && (
                          <span className={classNames(
                            e?.role === "owner" ? "bg-pink-50 text-pink-700" : "bg-green-100 text-green-700",
                            "inline-flex flex-shrink-0 items-center rounded-full px-3 py-1.5 text-xs font-medium capitalize"
                          )}>
                            {t(`common.role.${e?.role}`)}
                          </span>
                        )}
                        {e?.experts?.users?.id === currentUser?.id && (
                          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-yellow-100 px-3 py-1.5 text-xs font-medium text-yellow-800">
                            {t("common.you")}
                          </span>
                        )}
                      </div>

                      <p className="mt-1 flex text-xs leading-5 text-gray-500">{e.experts.users.email}</p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-3">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      {e.experts.title && <p className="text-sm leading-6 text-gray-900">{e.experts.title}</p>}
                      <p className="mt-1 text-xs leading-5 text-gray-500">
                        {t("common.created")} {moment(e.experts.users.created_on).locale(currentLanguage).format("lll")}
                      </p>
                    </div>
                    {["owner", "admin"].includes(currentUser?.role) && e.role !== "owner" && currentUser?.id !== e.experts.users.id && (
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block pl-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block w-full px-3 py-1 text-sm leading-6 text-red-600 text-left"
                                  )}
                                  onClick={() => {
                                    setExpertIdToDelete(e.experts.id);
                                    setExpertEmailToDelete(e.experts.users.email);
                                    setIsDeleteExpertConfirmationOpen(true);
                                  }}
                                >
                                  {t("common.delete")}
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
      <Modal isOpen={isDeleteExpertConfirmationOpen} onClose={closeDeleteExpertConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={`${t("company.team.delete.confirmation.title")} (${expertEmailToDelete})`}
          body={t("company.team.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteExpertConfirmation}
          onSave={deleteExpert}
        />
      </Modal>
    </div>
  );
}
