import React, {PropsWithChildren} from "react";

// Define the prop types
interface ListItemActionsProps {
  className?: string;
}

// Functional component with props
const ListItemActions: React.FC<PropsWithChildren<ListItemActionsProps>> = ({ className = '', children}) => {
  return (
    <div className={`grow-0 m-auto ${className}`}>
        {children}
    </div>
  );
};

// Export the component
export default React.memo(ListItemActions);
