import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/de";
import { thesisProjectStates } from "../../models";
import { FolderIcon } from "@heroicons/react/24/solid";
import { useContext } from "react";
import { CurrentUserContext } from "../../contexts/userContext";

interface Props {
  thesisProjects: any;
}

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

const TopicThesisProjects: React.FC<Props> = ({ thesisProjects }) => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);
  const currentLanguage = i18n.language;
  const location = useLocation();
  const isAdminRoute = location.pathname.includes("/admin/");
  return (
    <>
    {thesisProjects?.length > 0 && (
      <ul role="list" className="divide-y divide-gray-100 pt-3 pb-6">
        {thesisProjects?.map(
          (thesisProject: any) =>
            (["owner", "admin"].includes(currentUser?.role) ||
              thesisProject.experts?.some((e: any) => e?.user_id === currentUser?.id)) && (
              <li key={thesisProject.email} className="relative flex justify-between gap-x-6 py-5">
                <div className="flex min-w-0 gap-x-4 sm:flex-1">
                  <img
                    className="h-12 w-12 flex-none rounded-full border object-cover"
                    src={
                      thesisProject.student.user.image
                        ? `https://resources.thesisnavigator.com/${thesisProject.student.user.image}`
                        : thesisProject.student.user.picture
                    }
                    alt=""
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      <NavLink to={`${isAdminRoute ? '/admin' : '/user'}/thesis-projects/${thesisProject.id}`}>
                        <span className="absolute inset-x-0 -top-px bottom-0" />
                        {thesisProject.student.user.first_name} {thesisProject.student.user.last_name}
                      </NavLink>
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                      <span className="relative truncate">{thesisProject.student.user.email}</span>
                    </p>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4 sm:flex-1 sm:text-right sm:mt-0">
                  <div className="hidden sm:flex sm:flex-col sm:items-end w-full">
                    <p className="text-sm leading-6 text-gray-900">
                      {thesisProject.student.university.name} - {thesisProject.student.field_of_study}
                    </p>
                    <div className="mt-1 items-center text-xs leanding-5 text-gray-500">
                      {!thesisProject.updated && (
                        <span className="inline-flex pb-1.5">
                          {moment(thesisProject.created).locale(currentLanguage).format("lll")}
                        </span>
                      )}
                      {thesisProject.updated && (
                        <span className="inline-flex pb-1.5">
                          {t("common.updated")} {moment(thesisProject.updated).locale(currentLanguage).format("lll")}
                        </span>
                      )}
                      <span
                        className={classNames(
                          thesisProjectStates[thesisProject.state],
                          "inline-flex items-center rounded-full px-3 py-1.5 text-xs font-medium mt-1 ml-2 capitalize"
                        )}
                      >
                        {t(`thesisProjects.state.${thesisProject.state}`)}
                      </span>
                    </div>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </div>
              </li>
            )
        )}
      </ul>
    )}
      {thesisProjects?.length === 0 && (
        <div className="text-center pt-6">
          <FolderIcon className="mx-auto h-8 w-8 text-gray-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("common.noThesisProjects")}</h3>
        </div>
      )}
    </>
  );
};

export default TopicThesisProjects;
