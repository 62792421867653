import React, {PropsWithChildren} from "react";

// Define the prop types
export interface BreadcrumbListItemProps {
  className?: string;
}

// Functional component with props
const BreadcrumbListItem: React.FC<PropsWithChildren<BreadcrumbListItemProps>> = ({className = '', children}) => {
  return (
    <li role="list" className={`flex whitespace-nowrap ${className}`}>
      <div className="text-sm font-medium text-gray-500 hover:text-gray-700">
        {children}
      </div>
    </li>
  );
};

// Export the component
export default React.memo(BreadcrumbListItem);
