import {useForm} from "react-hook-form";
import {object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import Loader from "../../../components/Loader";
import {useTranslation} from "react-i18next";
import {MessageType, useMessagesContext} from "../../../contexts/messagesContext";
import {useParams} from "react-router-dom";
import Api from '../../../api/client';
import {AdminCompanyExpertCreate, AdminCompanyExpertPage} from "@api/generated";
import {PlusCircleIcon} from "@heroicons/react/24/outline";
import AdminCompanyUserEdit from "./AdminCompanyUserEdit";

export interface AdminCompanyUsersProps {
  domains?: string[];
}

export default function AdminCompanyUsers({domains}: AdminCompanyUsersProps) {
  const {id} = useParams();
  const {t, i18n} = useTranslation();
  const {addMessage} = useMessagesContext();

  // Define the validation schema
  const validationSchema = object({
    firstName: string().min(2).required(),
    lastName: string().min(2).required(),
    email: string().email().required()
      .test(
        "email-domain",
        t('form.error.string.test.allowedDomains', {domains: domains.join(', ')}),
        (value) => domains.some((domain: string) => value.endsWith(`@${domain}`))),
    role: string(),
  }).required();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: {isValid, errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "owner",
    },
    mode: "onChange"
  });

  const {isAuthenticated, isLoading} = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [companyExpertsPage, setCompanyExpertsPage] = useState<AdminCompanyExpertPage | undefined>(undefined);

  useEffect(() => {
    getUsers()
  }, []);

  const getUsers = async () => {
    setIsFetching(true);
    const response = await Api.AdminCompanyExperts.findAllAdminCompanyExperts(id)
    setCompanyExpertsPage(response.data);
    setIsFetching(false);
  };

  const addUser = async (data: AdminCompanyExpertCreate) => {
    setIsUpdating(true);
    try {
      //TODO: In case of undefined role, the value is coming as Expert
      if (!data.role) {
        delete data.role
      }
      await Api.AdminCompanyExperts.createAdminCompanyExpert(id, data);
      reset({
        firstName: "",
        lastName: "",
        email: "",
        role: "owner",
      })
      addMessage({
        type: MessageType.Success,
        title: "user.notification.create.title",
        description: "user.notification.create.description",
      });
      await getUsers();
      setIsUpdating(false);
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
      setIsUpdating(false);
    }
  };

  const onSubmit = async (data) => {
    await addUser(data);
  };

  function onUserDelete() {
    getUsers();
  }

  return (
    <div className="bg-white">
      {isFetching && (
        <div className="flex justify-center items-center mt-12">
          <Loader/>
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && companyExpertsPage && (
        <div className="pb-6 border-b border-gray-900/10">
          <div className="flex flex-shrink gap-x-6 items-center mt-6">
            <h2 className="text-lg h-6 font-bold text-gray-900 sm:truncate sm:text-xl tracking-tight">
              {t("common.users")}
            </h2>
            {isUpdating && (
              <div className="h-6">
                <Loader/>
              </div>
            )}
          </div>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 grid md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-4 border-b border-gray-100 mb-2 pb-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("user.form.firstName.label")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    autoComplete="given-name"
                    placeholder={t("user.form.firstName.placeholder")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                    {...register("firstName")}
                  />
                  {errors.firstName && <p className="mt-2 text-xs text-red-600">{errors.firstName.message}</p>}
                </div>
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("user.form.lastName.label")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    autoComplete="given-name"
                    placeholder={t("user.form.lastName.placeholder")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                    {...register("lastName")}
                  />
                  {errors.lastName && <p className="mt-2 text-xs text-red-600">{errors.lastName.message}</p>}
                </div>
              </div>
              <div className={"md:col-span-2"}>
                <div className="sm:flex justify-between gap-6">
                  <div className="w-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("user.form.email.label")}
                    </label>
                    <div className="relative mt-2">
                      <input
                        type="text"
                        autoComplete="given-name"
                        placeholder={t("user.form.email.placeholder")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        {...register("email")}
                      />
                      <div
                        className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                        aria-hidden="true"
                      />
                      <div className="absolute inset-y-0 right-0.5 flex items-center">
                        <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true"/>
                        <label htmlFor="role" className="sr-only">
                          Role
                        </label>
                        <select
                          id="role"
                          className="rounded-md border-0 bg-white py-1 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 font-semibold cursor-pointer"
                          {...register("role")}
                        >
                          <option value="owner">Owner</option>
                          <option value="admin">Admin</option>
                          <option value="">Expert</option>
                        </select>
                      </div>
                    </div>
                    {errors.email && <p className="mt-2 text-xs text-red-600">{errors.email.message}</p>}
                    {errors.role && <p className="mt-2 text-xs text-red-600">{errors.role.message}</p>}
                  </div>
                  <div className="mt-4 sm:mt-8">
                    <button
                      type="submit"
                      className="inline-flex items-center rounded-md text-sm font-semibold text-indigo-700 mt-2 disabled:opacity-75 truncate overflow-hidden"
                      disabled={isUpdating}
                    >
                      <PlusCircleIcon className="-mt-0.5 h-6" aria-hidden="true"/>
                      <span className="sm:hidden xl:block ml-1.5">{t("company.team.button")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {companyExpertsPage.items.map((user) => (
            <AdminCompanyUserEdit key={user.id} user={user} onDelete={onUserDelete}/>
          ))}
        </div>
      )}
    </div>
  );
}
