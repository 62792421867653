import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React from "react";

export enum ConfirmationDialogType {
  Confirm = "confirm",
  Delete = "delete",
}

interface ConfirmationDialogProps {
  type: ConfirmationDialogType;
  title: string;
  body: string;
  cancel: string;
  save: string;
  onCancel: () => void;
  onSave: () => void;
}

const classNames = (...classes: any) => classes.filter(Boolean).join(" ");

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  type,
  title,
  body,
  cancel,
  save,
  onCancel,
  onSave,
}) => {
  return (
    <>
      <div className="sm:flex sm:items-start">
        {type === ConfirmationDialogType.Delete && (
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        )}
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
          <p className="mt-3 text-sm text-gray-500">{body}</p>
        </div>
      </div>
      <div className="mt-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={classNames(
            type === ConfirmationDialogType.Delete
              ? "bg-red-600 hover:bg-red-500"
              : "bg-indigo-600 hover:bg-indigo-500",
            "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
          )}
          onClick={onSave}
        >
          {save}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={onCancel}
        >
          {cancel}
        </button>
      </div>
    </>
  );
};

export default ConfirmationDialog;
