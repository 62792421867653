import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Trans, useTranslation } from "react-i18next";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import moment from "moment";
import "moment/locale/de";
import { AcademicCapIcon, ArrowPathIcon, ClockIcon, UserCircleIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import ConfirmationDialog, { ConfirmationDialogType } from "../../components/ConfirmationDialog";
import Modal from "../../components/Modal";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";

export default function UniversitySupervisors() {
  const { currentUser } = useContext(CurrentUserContext);
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    email: string()
      .email()
      .test("email-domain", "Email domain is not allowed", (value) => {
        if (!value) return true;
        const [, domain] = value.split("@");
        return domains.some((allowedDomain: string) =>
          domain === "studyond.com" || domain === allowedDomain || domain?.endsWith(`.${allowedDomain}`)
        );
      }),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: null,
      role: "supervisor",
    },
  });

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [university, setUniversity] = useState<any>({});
  const [domains, setDomains] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [supervisorIdToDelete, setSupervisorIdToDelete] = useState();
  const [supervisorEmailToDelete, setSupervisorEmailToDelete] = useState();
  const [isDeleteSupervisorConfirmationOpen, setIsDeleteSupervisorConfirmationOpen] = useState<boolean>(false);

  const getUniversity = async () => {
    try {
      const response = await httpsClient.get(`/api/v1/university`);
      response.data.name = response.data.name;
      response.data.description = response.data.description;
      response.data.uid = response.data.uid;
      setUniversity(response.data);
      const _domains: any = response.data.university_domains.map((e: any) => e.domain) || [];
      setDomains(_domains);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const reloadUniversity = async () => {
    setIsFetching(true);
    getUniversity();
  };

  const addSupervisor = async (data: any) => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.post("/api/v2/supervisor/invite", {
        email: data?.email
      });
      if (currentUser?.id) {
        await getUniversity();
      }
      reset({ email: null });
      setIsUpdating(false);
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const deleteSupervisor = async () => {
    try {
      closeDeleteSupervisorConfirmation();
      setIsUpdating(true);
      await httpsClient.delete(`/api/v2/supervisor/${supervisorIdToDelete}`);
      if (currentUser?.id) {
        await getUniversity();
      }
      setIsUpdating(false);
    } catch (error) {
      console.log(error)
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onSubmit = (data: any) => {
    addSupervisor(data);
  };

  const closeDeleteSupervisorConfirmation = () => {
    setIsDeleteSupervisorConfirmationOpen(false);
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (currentUser?.id) {
      getUniversity();
    }
  }, [currentUser]);

  return (
    <div className="bg-white">
      <div className="pb-12 sm:pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("university.supervisors")}</h2>
        </div>
      </div>
      {isAuthenticated && !isLoading && (
        <>
          <>
            <div className="text-center">
              <AcademicCapIcon className="mx-auto h-12 w-12 text-gray-400" />

              <p className="mt-1 text-sm text-gray-500">
                <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">{t("university.team.title")}</h2>
                <p className="mx-auto max-w-4xl">{t("university.team.subtitle")}</p>
                <p className="text-xs pt-0.5">
                  {t("university.team.domainValidation")}{" "}
                  <span className="font-bold text-gray-700">{domains.join(", ")}</span>.
                </p>
              </p>
            </div>
            <form className="my-6 sm:flex sm:items-center" action="#">
              <label htmlFor="emails" className="sr-only">
                Email addresses
              </label>
              <div className="grid grid-cols-1 sm:flex-auto">
                <input
                  type="email"
                  id="email"
                  className="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 text-xs"
                  placeholder={`${t("university.team.placeholder")} ${domains.join(", ")}`}
                  {...register("email")}
                  onPaste={() => trigger}
                  autoComplete="off"
                />
                {errors.email && <p className="mt-2 text-xs text-red-600">{errors.email?.message?.toString()}</p>}
                <div
                  className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                  aria-hidden="true"
                />
                <div className="col-start-2 row-start-1 flex items-center">
                  <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true" />
                  <label htmlFor="role" className="sr-only">
                    Role
                  </label>
                  <select
                    id="role"
                    className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6 font-semibold cursor-pointer"
                    {...register("role")}
                    defaultValue={getValues("role")}
                  >
                    <option value="supervisor">{t("university.team.supervisor")}</option>
                  </select>
                </div>
              </div>
              <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                {isUpdating && <Loader />}
                {!isUpdating && (
                  <>
                    <button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-60"
                      disabled={!isValid || !getValues("email")}
                    >
                      {t("university.team.button")}
                    </button>
                  </>
                )}
              </div>
              <button
                type="button"
                className="hidden sm:inline-block ml-6 h-6 w-6 text-gray-400 hover:text-gray-600"
                onClick={() => reloadUniversity()}
              >
                <ArrowPathIcon />
              </button>
            </form>
          </>
          {isFetching && (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          )}
          {!isFetching && (
            <ul role="list" className="divide-y divide-gray-100">
              {university?.supervisors?.map((e: any) => {
                const inviterFirstName = e.supervisors?.users.first_name;
                const inviterLastName = e.supervisors?.users.last_name;
                const inviterEmail = e.supervisors?.users.email.split("@")[0];
                const invited = moment(e.invited).locale(currentLanguage).format("ll");
                const invitedBy = (inviterFirstName || inviterLastName) ? `${inviterFirstName || ''} ${inviterLastName || ''}`.trim() : inviterEmail;
                return (
                  <li key={e.id} className="sm:flex justify-between gap-x-6 py-5">
                    <div className="flex shrink-0 items-center min-w-0 gap-x-4">
                      {(e.users.image || e.users.picture) &&
                        (e.users.image ? (
                          <img
                            className="inline-block h-12 w-12 rounded-full border object-cover"
                            src={`https://resources.thesisnavigator.com/${e.users.image}`}
                            alt=""
                          />
                        ) : (
                          <img className="h-12 w-12 border rounded-full border object-cover" src={e.users?.picture} alt="" />
                        ))}
                      {!e.users.image && !e.users.picture && (
                        <UserCircleIcon className="inline-block h-12 w-12 shrink-0 text-gray-400" aria-hidden="true" />
                      )}
                      <div className="min-w-0 flex-auto">
                        <div className="flex items-center space-x-3">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {(e.users.first_name || e.users.last_name) && (
                              <>
                                {e.users.first_name} {e.users.last_name}
                              </>
                            )}
                            {!e.users.first_name && !e.users.last_name && (
                              <>{e.users.email.split("@")[0]}</>
                            )}
                          </p>
                          {e?.role && (
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700 capitalize">
                              {e?.role}
                            </span>
                          )}
                          {e?.users.id === currentUser.id && (
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-yellow-100 px-3 py-1.5 text-xs font-medium text-yellow-800">
                              {t("common.you")}
                            </span>
                          )}
                        </div>
                        <p className="mt-1 flex text-xs leading-5 text-gray-500">{e.users.email}</p>
                      </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-3 mt-2 sm:mt:0">
                      <div className="sm:flex sm:flex-col sm:items-end">
                        {e.title && <p className="text-sm leading-6 text-gray-900">{e.title}</p>}
                        {e.invited && e.supervisors && (
                          <p className="mt-1 text-xs text-gray-500 flex flex-wrap items-center gap-1">
                            {new Date(e.users.last_login) < new Date(e.invited) && (
                              <>
                                <ClockIcon className="inline-flex text-gray-300 w-5 mr-1" />
                              </>
                            )}
                            <Trans
                              i18nKey={"supervisor.invited"}
                              components={[<span className="font-medium text-gray-600" />]}
                              values={{ invitedBy, invited }}
                            />
                            {new Date(e.users.last_login) < new Date(e.invited) && (
                              <>
                                <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-1.5 text-xs font-medium text-gray-600 ml-2">Pending</span>
                              </>
                            )}
                          </p>
                        )}
                        {!e.invited_by && (
                          <p className="mt-1 text-xs leading-5 text-gray-500">
                            {t("common.created")} {moment(e.users.created_on).locale(currentLanguage).format("ll")}
                          </p>
                        )}
                      </div>
                      {new Date(e.users.last_login) < new Date(e.invited) && currentUser?.id === e.supervisors?.users?.id && (
                        <Menu as="div" className="relative flex-none ml-auto">
                          <MenuButton className="-m-2.5 block pl-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <MenuItems className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <MenuItem>
                                {({ active }) => (
                                  <button
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block w-full px-3 py-1 text-sm leading-6 text-red-600 text-left"
                                    )}
                                    onClick={() => {
                                      setSupervisorIdToDelete(e.id);
                                      setSupervisorEmailToDelete(e.users.email);
                                      setIsDeleteSupervisorConfirmationOpen(true);
                                    }}
                                  >
                                    {t("common.delete")}
                                  </button>
                                )}
                              </MenuItem>
                            </MenuItems>
                          </Transition>
                        </Menu>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </>
      )}
      <Modal isOpen={isDeleteSupervisorConfirmationOpen} onClose={closeDeleteSupervisorConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={`${t("university.team.delete.confirmation.title")} (${supervisorEmailToDelete})`}
          body={t("university.team.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteSupervisorConfirmation}
          onSave={deleteSupervisor}
        />
      </Modal>
    </div>
  );
}
