import React, {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";
// Define the prop types
interface ListPaginationProps {
  page: number;
  size: number;
  total: number;
  totalPage: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  singularKey: string;
  pluralKey: string;
  className?: string;
}

// Functional component with props
const ListPagination: React.FC<PropsWithChildren<ListPaginationProps>> = ({page, size, total, totalPage, onNextPage, onPrevPage, singularKey, pluralKey, className = '', children}) => {
  const {t, i18n} = useTranslation();
  return (
    <nav className={`flex items-center justify-between bg-white py-6 ${className}`} aria-label="Pagination">
      <div className="sm:block">
        <p className="text-sm text-gray-700">
          {total >= size && (
            <>
              <span className="font-semibold">{size * (page - 1) + 1}</span> {t("common.to")}{" "}
              <span className="font-semibold">{size * page}</span> {t("common.of")}
            </>
          )}{" "}
          <span className="font-semibold">{total}</span> {total === 1 ? t(singularKey) : t(pluralKey)}
        </p>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          onClick={onPrevPage}
          disabled={page === 1}
          className="relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-slate-100 focus-visible:outline-offset-0 disabled:opacity-50"
        >
          {t("common.previous")}
        </button>
        <button
          onClick={onNextPage}
          disabled={page >= totalPage}
          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-slate-100 focus-visible:outline-offset-0 disabled:opacity-50"
        >
          {t("common.next")}
        </button>
      </div>
    </nav>
  );
};

// Export the component
export default React.memo(ListPagination);
