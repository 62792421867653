import React, {PropsWithChildren} from "react";

// Define the prop types
interface ListItemProps {
  className?: string;
}

// Functional component with props
const ListItem: React.FC<PropsWithChildren<ListItemProps>> = ({className = '', children}) => {
  return (
    <li className={`flex items-start justify-between gap-y-8 py-8 ${className}`}>
      {children}
    </li>
  );
};

// Export the component
export default React.memo(ListItem);
