import { array, object, string } from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Controller, useForm } from "react-hook-form";
import { internships } from "../../models";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import { TextEditor } from "../../components/TipTap/TextEditor";

const levelOptions = [
  { value: "bsc", label: "bsc", checked: false },
  { value: "msc", label: "msc", checked: false },
  { value: "phd", label: "phd", checked: false },
];

export const ExpertTopicForm: React.FC<any> = () => {
  const { id } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(id ? true : false);
  const [topic, setTopic] = useState<any>({});
  const [levels, setLevels] = useState({
    bsc: true,
    msc: true,
    phd: true,
  });
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isCompanyRoute = location.pathname.includes("/company/topics");
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    title: string().required(t("common.required")),
    description: string().required(t("common.required")).notOneOf(['<p></p>'], t("common.required")).trim(),
    internship: string(),
    requirements: string().nullable(),
    levels: array()
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
      internship: "no",
      requirements: "",
      levels: [],
    },
  });

  const getTopic = async () => {
    setIsFetching(true);
    await httpsClient
      .get(`/api/v2/expert/topics/${id}`)
      .then((response) => {
        if (
          currentUser.companyId !== response.data?.company?.id ||
          (!["owner", "admin"].includes(currentUser?.role) &&
            !response.data.experts?.some((expert: any) => expert.id === currentUser.entityId))
        ) {
          navigate("/error/forbidden");
        }
        setTopic(response.data);
        setLevels({
          bsc: response.data.levels?.includes("bsc"),
          msc: response.data.levels?.includes("msc"),
          phd: response.data.levels?.includes("phd"),
        });
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        } else {
          addMessage({
            type: MessageType.Error,
            title: "common.error.title",
            description: "common.error.description",
          });
        }
        console.error("Error fetching data:", error);
      });
  };

  const createTopic = async (data: any) => {
    try {
      setIsUpdating(true);
      const sanitizedData = {
        ...data,
        description: data.description === '<p></p>' ? '' : data.description,
        requirements: data.requirements === '<p></p>' ? '' : data.requirements,
      };
      const response = await httpsClient.post("/api/v2/expert/topics", {
        title: sanitizedData.title,
        description: sanitizedData.description,
        internship: sanitizedData.internship,
        requirements: sanitizedData.requirements,
        levels: Object.keys(levels).filter((key) => {
          const option = key as keyof typeof levels;
          return levels[option];
        }),
        published: false,
      });
      setIsUpdating(false);
      reset();
      addMessage({
        type: MessageType.Success,
        title: "topics.topic.create.notification.title",
        description: "topics.topic.create.notification.description",
      });
      if (isCompanyRoute) {
        navigate(`/company/topics/${response?.data?.id}`);
      } else {
        navigate(`/user/topics/${response?.data?.id}`);
      }
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const updateTopic = async (data: any) => {
    setIsFetching(true);
    const sanitizedData = {
      ...data,
      description: data.description === '<p></p>' ? '' : data.description,
      requirements: data.requirements === '<p></p>' ? '' : data.requirements,
    };
    await httpsClient.patch(`/api/v2/expert/topics/${id}`, {
      title: sanitizedData.title,
      description: sanitizedData.description,
      internship: sanitizedData.internship,
      requirements: sanitizedData.requirements,
      levels: Object.keys(levels).filter((key) => {
        const option = key as keyof typeof levels;
        return levels[option];
      }),
    })
      .then(() => {
        setIsUpdating(false);
        addMessage({
          type: MessageType.Success,
          title: "topics.topic.update.notification.title",
          description: "topics.topic.update.notification.description",
        });
        if (isCompanyRoute) {
          navigate(`/company/topics/${id}`);
        } else {
          navigate(`/user/topics/${id}`);
        }
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        } else {
          addMessage({
            type: MessageType.Error,
            title: "common.error.title",
            description: "common.error.description",
          });
        }
        console.error("Error fetching data:", error);
      });
  };

  const onCancel = () => {
    if (id) {
      if (isCompanyRoute) {
        navigate(`/company/topics/${id}`);
      } else {
        navigate(`/user/topics/${id}`);
      }
    } else {
      if (isCompanyRoute) {
        navigate(`/company/topics`);
      } else {
        navigate(`/user/topics`);
      }
    }
  };

  const onSubmit = (data: any) => {
    if (id) {
      updateTopic(data);
    } else {
      createTopic(data);
    }
  };

  const handleCheckboxChange = (option: keyof typeof levels) => {
    setLevels((prevLevels) => ({
      ...prevLevels,
      [option]: !prevLevels[option],
    }));
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (topic?.id) {
      const defaultValues: any = {};
      defaultValues.title = topic?.title;
      defaultValues.description = topic?.description;
      defaultValues.internship = topic.internship;
      defaultValues.requirements = topic.requirements;
      reset({ ...defaultValues });
    }
  }, [topic]);

  useEffect(() => {
    if (currentUser.company && id) {
      getTopic();
    }
  }, [currentUser, id]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <nav aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex whitespace-nowrap">
              <NavLink
                to={isCompanyRoute ? `/company/profile` : `/user/profile`}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.profile") : t("common.myProfile")}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex whitespace-nowrap items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <NavLink
                to={isCompanyRoute ? "/company/topics" : "/user/topics"}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.topics") : t("common.myTopics")}
              </NavLink>
            </div>
          </li>
          {id && !isFetching && (
            <li className="overflow-hidden">
              <div className="flex whitespace-nowrap items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <NavLink
                  to={isCompanyRoute ? `/company/topics/${id}` : `/user/topics/${id}`}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 truncate"
                >
                  {topic?.title}
                </NavLink>
              </div>
            </li>
          )}
        </ol>
      </nav>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <form autoComplete="off">
          <div className="pb-6">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight mt-2">
              {id ? t("myTopics.form.editTopicTitle") : t("myTopics.form.newTopicTitle")}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">{t("myTopics.form.subtitle")}</p>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("myTopics.form.topic.title.title")}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder={t("myTopics.form.topic.title.placeholder")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                    defaultValue={""}
                    {...register("title")}
                  />
                  {errors.title && <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>}
                </div>
              </div>

              <div className="sm:col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">{t("Level")}</label>
                <div className="mt-2 sm:flex sm:space-x-6">
                  {levelOptions.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center mt-2 sm:mt-0">
                      <input
                        id={`level-${optionIdx}`}
                        name="levels[]"
                        defaultValue={option.value}
                        type="checkbox"
                        className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                        onChange={() => handleCheckboxChange(option.value as keyof typeof levels)}
                        checked={levels[option.value as keyof typeof levels]}
                        defaultChecked={option.checked}
                      />
                      <label
                        htmlFor={`level-${optionIdx}`}
                        className="ml-3 min-w-0 flex-1 text-sm font-medium leading-6 text-gray-900 cursor-pointer"
                      >
                        {t(`level.${option.label}`)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sm:col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  {t("myTopics.form.topic.internship.title")}
                </label>
                <p className="text-sm text-gray-500">{t("myTopics.form.topic.internship.subtitle")}</p>
                <fieldset className="mt-4">
                  <legend className="sr-only">{t("myTopics.form.topic.internship.title")}</legend>
                  <div className="flex space-x-6">
                    {internships.map((e) => (
                      <div key={e.id} className="flex items-center">
                        <input
                          id={e.id}
                          type="radio"
                          value={e.id}
                          // defaultChecked={e.id === "yes"}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          {...register("internship")}
                        />
                        <label htmlFor={e.id} className="ml-3 text-sm font-medium leading-6 text-gray-900">
                          {t(`myTopics.form.topic.internship.${e.id}`)}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="sm:col-span-full">
                <div className="flex items-center gap-x-2">
                  <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("myTopics.form.topic.description.title")}
                  </label>
                  <div className="inline-block relative group/info">
                    <InformationCircleIcon
                      className="text-gray-300 hover:text-gray-400 w-5 flex-shrink-0 z-10 cursor-pointer"
                      aria-hidden="true"
                    />
                    <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/info:opacity-100">
                      {t("myTopics.form.topic.description.placeholder")}
                      <div className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 whitespace-pre-line">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <TextEditor
                        content={field.value}
                        placeholder={t("myTopics.form.topic.description.placeholder")}
                        onChange={field.onChange}
                      />
                    )}
                    name="description"
                  />
                  {errors.description && (
                    <p className="mt-2 text-xs text-red-600">{errors.description?.message?.toString()}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-full">
                <div className="flex items-center gap-x-2">
                  <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    {t("myTopics.form.topic.requirements.title")}
                  </label>
                  <div className="inline-block relative group/info">
                    <InformationCircleIcon
                      className="text-gray-300 hover:text-gray-400 w-5 flex-shrink-0 z-10 cursor-pointer"
                      aria-hidden="true"
                    />
                    <div className="absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-60 z-50 pointer-events-none opacity-0 transition-opacity group-hover/info:opacity-100">
                      {t("myTopics.form.topic.requirements.placeholder")}
                      <div className="invisible absolute left-1/2 -translate-x-1 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 whitespace-pre-line">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <TextEditor
                        content={field.value}
                        placeholder={t("myTopics.form.topic.requirements.placeholder")}
                        onChange={field.onChange}
                      />
                    )}
                    name="requirements"
                  />
                  {errors.requirements && (
                    <p className="mt-2 text-xs text-red-600">{errors.requirements?.message?.toString()}</p>
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {isUpdating && <Loader />}
            {!isUpdating && (
              <>
                <button type="button" onClick={onCancel} className="text-sm font-semibold leading-6 text-gray-900">
                  {t("common.cancel")}
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("common.save")}
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
