import React, {PropsWithChildren} from "react";

// Define the prop types
interface ListItemContentProps {
  className?: string;
}

// Functional component with props
const ListItemContent: React.FC<PropsWithChildren<ListItemContentProps>> = ({ className = '', children}) => {
  return (
    <div className={`flex-auto ${className}`}>
      {children}
    </div>
  );
};

// Export the component
export default React.memo(ListItemContent);
