import React, {PropsWithChildren} from "react";

// Define the prop types
interface ListItemTitleProps {
  className?: string;
}

// Functional component with props
const ListItemTitle: React.FC<PropsWithChildren<ListItemTitleProps>> = ({ className = '', children}) => {
  return (
    <div className={`min-w-0 flex-auto ${className}`}>
      <div className={'flex items-start gap-x-3 mb-2'}>
        <h2 className={'text-xl font-bold leading-7 text-gray-900'}>
          {children}
        </h2>
      </div>
    </div>
  );
};

// Export the component
export default React.memo(ListItemTitle);
