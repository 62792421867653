import React, {PropsWithChildren} from "react";

// Define the prop types
export interface ListProps {
  className?: string;
}

// Functional component with props
const List: React.FC<PropsWithChildren<ListProps>> = ({ className = '', children }) => {
  return (
    <ul role="list" className={`divide-y divide-gray-100 ${className}`}>
      {children}
    </ul>
  );
};

// Export the component
export default React.memo(List);
