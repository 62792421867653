import { CameraIcon, CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import { languages } from "../../models";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Trans, useTranslation } from "react-i18next";
import { Combobox, Dialog, DialogPanel, DialogTitle, Switch, Transition, TransitionChild } from "@headlessui/react";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MessageType, useMessagesContext } from "../../contexts/messagesContext";
import { NavLink } from "react-router-dom";

const professionalNetworkProfileRegex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+|xing\.com\/profile\/[a-zA-Z0-9_-]+)\/?$/;

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function Expert() {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { t, i18n } = useTranslation();

  const validationSchema = object().shape({
    first_name: string().required(t("common.required")),
    last_name: string().required(t("common.required")),
    professional_network_profile: string()
      .notRequired()
      .matches(professionalNetworkProfileRegex, {
        message: t("profile.expert.expertise.professionalNetworkProfile.error"),
        excludeEmptyString: true,
      }),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingUniversities, setIsFetchingUniversities] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [token, setToken] = useState("");
  const [expert, setExpert] = useState({
    email: "",
    first_name: "",
    last_name: "",
    id: "",
    language: "en",
    university: {},
    image: "",
    picture: "",
    degree: "",
    title: "",
    expertise: "",
    professional_network_profile: "",
    offer_interviews: false,
    offer_interviews_description: "",
    offer_internships: false,
    offer_internships_description: "",
    company_experts: [],
  });
  const [company, setCompany] = useState<any>();
  const [query, setQuery] = useState("");
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState({});
  const [selectedUniversitiesOfInterest, setSelectedUniversitiesOfInterest] = useState([]);
  const [countries, setCountries] = useState<any>([]);
  const currentLanguage = i18n.language;
  const [offerInterviews, setOfferInterviews] = useState(false);
  const [offerInternships, setOfferInternships] = useState(false);
  const [profileImage, setProfileImage] = useState<any>();
  const profileImageInputRef = useRef(null);
  const [openProfile, setOpenProfile] = useState(false);
  const { addMessage } = useMessagesContext();

  useEffect(() => {
    getCountries();
  }, [currentLanguage]);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getUser();
  }, [currentUser]);

  useEffect(() => {
    if (expert?.id) {
      const defaultValues: any = {};
      defaultValues.first_name = expert?.first_name;
      defaultValues.last_name = expert?.last_name;
      defaultValues.email = expert?.email;
      defaultValues.language = expert?.language;
      defaultValues.university = expert?.university;
      defaultValues.degree = expert?.degree;
      defaultValues.title = expert?.title;
      defaultValues.expertise = expert?.expertise;
      defaultValues.professional_network_profile = expert?.professional_network_profile;
      defaultValues.offer_interviews = expert?.offer_interviews;
      defaultValues.offer_interviews_description = expert?.offer_interviews_description;
      defaultValues.offer_internships = expert?.offer_internships;
      defaultValues.offer_internships_description = expert?.offer_internships_description;
      reset({ ...defaultValues });
    }
  }, [expert]);

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const getUser = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v1/user`);
      response.data.university = response.data.experts[0]?.universities;
      response.data.degree = response.data.experts[0]?.degree;
      response.data.title = response.data.experts[0]?.title;
      response.data.expertise = response.data.experts[0]?.expertise;
      response.data.professional_network_profile = response.data.experts[0]?.professional_network_profile;
      response.data.offer_interviews = response.data.experts[0]?.offer_interviews;
      response.data.offer_interviews_description = response.data.experts[0]?.offer_interviews_description;
      response.data.offer_internships = response.data.experts[0]?.offer_internships;
      response.data.offer_internships_description = response.data.experts[0]?.offer_internships_description;
      setExpert(response.data);
      setOfferInterviews(response.data.offer_interviews);
      setOfferInternships(response.data.offer_internships);
      setSelectedUniversity(response.data.university);
      setCompany(response.data.experts[0]?.company_experts[0]?.companies);
      i18n.changeLanguage(response.data.language);
      document.documentElement.lang = response.data.language;
      const initialSelectedUniversitiesOfInterest =
        response.data.experts[0]?.expert_universities.map((e: any) => e.universities);
      setSelectedUniversitiesOfInterest(initialSelectedUniversitiesOfInterest);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const updateUser = async (data: any) => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/user", {
        email: data?.email,
        first_name: data?.first_name,
        last_name: data?.last_name,
        language: data?.language,
        experts: {
          update: {
            data: {
              university_id: data.university?.id || null,
              degree: data.degree,
              title: data.title,
              expertise: data.expertise,
              professional_network_profile: data.professional_network_profile,
              offer_interviews: data.offer_interviews,
              offer_interviews_description: data.offer_interviews_description,
              offer_internships: data.offer_internships,
              offer_internships_description: data.offer_internships_description,
              expert_universities: {
                deleteMany: {
                  expert_id: currentUser?.entityId,
                },
                createMany: {
                  data: selectedUniversitiesOfInterest.map((e: any) => ({ university_id: e.id })),
                  skipDuplicates: true,
                },
              },
            },
            where: { id: currentUser?.entityId },
          },
        },
      });
      getUser();
      setCurrentUser({
        ...currentUser,
        first_name: data?.first_name,
        last_name: data?.last_name
      });
      setIsUpdating(false);
      addMessage({
        type: MessageType.Success,
        title: "profile.notification.update.title",
        description: "profile.notification.update.description",
      });
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const getUniversities = async () => {
    try {
      setIsFetchingUniversities(true);
      const response = await httpsClient.get(`/api/v1/universities?search=${query?.trim()}`);
      setUniversities(response.data);
      setIsFetchingUniversities(false);
    } catch {
      setIsFetchingUniversities(false);
    }
  };

  useEffect(() => {
    if (query.length > 1) {
      getUniversities();
    } else {
      setUniversities([]);
    }
  }, [query]);

  const filteredUniversities =
    query === ""
      ? universities
      : universities.filter((e: any) => {
        return e.name.toLowerCase().includes(query.toLowerCase());
      });

  const removeUniversity = (id: string) => {
    const _universitiesOfInterest = [...selectedUniversitiesOfInterest];
    setSelectedUniversitiesOfInterest(_universitiesOfInterest.filter((e: any) => e.id !== id));
  };

  const getCountries = async () => {
    const response = await httpsClient.get(`/api/v1/countries?lang=${currentLanguage}`);
    setCountries(response.data);
  };

  const onCancel = () => {
    getUser();
  };

  const onSubmit = (data: any) => {
    updateUser(data);
  };

  const handleLanguageChange = (event: any) => {
    i18n.changeLanguage(event.currentTarget.value);
    document.documentElement.lang = event.currentTarget.value;
  };

  const debouncedFilter = useRef(debounce(setQuery, 300)).current;

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncedFilter(value);
  };

  useEffect(() => {
    if (profileImage) {
      uploadProfileImageToS3("profile/experts", profileImage.name, profileImage.type);
    }
  }, [profileImage]);

  const handleProfileImageChange = (event: any) => {
    setProfileImage(event.target.files[0]);
  };

  const handleProfileImageClick = () => {
    if (profileImageInputRef.current) {
      (profileImageInputRef.current as HTMLInputElement).click();
    }
  };

  const uploadProfileImageToS3 = async (path: string, name: string, type: string) => {
    try {
      setIsUpdating(true);
      const _response = await httpsClient.post(`/api/v1/presignedUrl`, {
        path,
        name,
        type,
      });
      await axios.put(_response.data.signedUrl, profileImage, {
        headers: {
          "Content-Type": profileImage.type,
        },
      });
      await httpsClient.patch("/api/v1/user", {
        image: _response.data?.key,
      });
      setIsUpdating(false);
      getUser();
      setCurrentUser({
        ...currentUser,
        image: _response.data?.key,
      });
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setOpenProfile(false);
    } catch (error) {
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
      setIsUpdating(false);
    }
  };

  const handleRemoveProfileImage = async () => {
    try {
      setIsUpdating(true);
      const response = await httpsClient.patch("/api/v1/user", {
        image: null,
      });
      setIsUpdating(false);
      getUser();
      setCurrentUser({
        ...currentUser,
        image: null,
      });
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    } catch (error) {
      setIsUpdating(false);
      setProfileImage(null);
      if (profileImageInputRef.current) {
        (profileImageInputRef.current as HTMLInputElement).value = "";
      }
    }
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <div className="pb-12 sm:pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("common.myProfile")}</h2>
        </div>
      </div>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <>
          <form autoComplete="off">
            <div className="space-y-8">
              <div className="border-b border-gray-900/10 pb-8">
                <div className="md:flex md:items-center md:justify-between md:space-x-5">
                  <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                      <div
                        className="relative cursor-pointer hover:opacity-75"
                        onClick={() => {
                          setOpenProfile(true);
                        }}
                      >
                        {(expert?.image || expert?.picture) &&
                          (expert?.image ? (
                            <img
                              className="w-16 h-16 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${expert.image}`}
                              alt=""
                            />
                          ) : (
                            <img className="w-16 h-16 rounded-full" src={expert?.picture} alt="" />
                          ))}
                        {!expert?.image && !expert?.picture && (
                          <img className="w-16 h-16 rounded-full border object-cover" src={user?.picture} alt="" />
                        )}
                        {!expert?.image && (
                          <button
                            type="button"
                            className="absolute bottom-0 -right-1 ring-1 ring-gray-100 p-1 rounded-full bg-white hover:opacity-75"
                          >
                            <CameraIcon className="text-indigo-600 h-4" />
                          </button>
                        )}
                        <input
                          type="file"
                          ref={profileImageInputRef}
                          onChange={handleProfileImageChange}
                          className="hidden"
                          accept="image/*"
                        />
                        <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="flex items-center space-x-4 pt-1.5">
                      <h1 className="text-lg font-bold text-gray-900">
                        {(expert?.first_name || expert?.last_name) && (
                          <>
                            {expert?.first_name} {expert?.last_name}
                          </>
                        )}
                        {!expert?.first_name && !expert?.last_name && <>{t("common.myProfile")}</>}
                      </h1>
                      {currentUser?.role && (
                        <span className={classNames(
                          currentUser?.role === "owner" ? "bg-pink-50 text-pink-700" : "bg-green-100 text-green-700",
                          "inline-flex flex-shrink-0 items-center rounded-full px-3 py-1.5 text-xs font-medium capitalize"
                        )}>
                          {t(`common.role.${currentUser?.role}`)}
                        </span>
                      )}
                      {/* <p className="text-sm font-medium text-gray-500">
                        {user?.city} ({user?.country})
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.firstName")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        autoComplete="given-name"
                        placeholder={t("profile.user.firstName")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        {...register("first_name")}
                      />
                      {errors.first_name && (
                        <p className="mt-2 text-xs text-red-600">{errors.first_name?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.lastName")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.user.lastName")}
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        {...register("last_name")}
                      />
                      {errors.last_name && (
                        <p className="mt-2 text-xs text-red-600">{errors.last_name?.message?.toString()}</p>
                      )}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.email")}
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100"
                        {...register("email")}
                        disabled
                      />
                      {errors.email && <p className="mt-2 text-xs text-red-600">{errors.email?.message?.toString()}</p>}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.user.language.title")}
                    </label>
                    <p className="text-sm text-gray-500">{t("profile.user.language.subtitle")}</p>
                    <fieldset className="mt-4">
                      <legend className="sr-only">{t("profile.user.language.title")}</legend>
                      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        {languages.map((e) => (
                          <div key={e.id} className="flex items-center">
                            <input
                              id={e.id}
                              type="radio"
                              value={e.id}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              {...register("language")}
                              onChange={handleLanguageChange}
                            />
                            <label htmlFor={e.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {t(`languages.${e.id}`)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-8">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("profile.expert.expertise.title")}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t("profile.expert.expertise.subtitle")}</p>

                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.expertise.university.title")}
                    </label>
                    <p className="text-sm text-gray-500">{t("profile.expert.expertise.university.subtitle")}</p>
                    <Controller
                      control={control}
                      defaultValue={selectedUniversity}
                      name="university"
                      render={({ field: { onChange } }) => (
                        <Combobox
                          as="div"
                          value={selectedUniversity}
                          onChange={(e) => {
                            onChange(e);
                            if (e?.id) {
                              setSelectedUniversity(e);
                            } else {
                              setSelectedUniversity({})
                            }
                          }}
                        >
                          <div className="relative mt-2">
                            <Combobox.Input
                              placeholder={t("profile.expert.expertise.university.title")}
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 placeholder:text-gray-400"
                              onChange={handleQueryChange}
                              displayValue={(university: any) => (university?.id ? `${university?.name} (${university?.country})` : "")}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => { setQuery(""); setUniversities([]) }}
                            >
                              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                {(!isFetchingUniversities && filteredUniversities.length === 0 && query.length > 1) ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    {t("profile.student.info.noUniversity")}
                                  </div>
                                ) : (
                                  filteredUniversities.map((university: any) => (
                                    <Combobox.Option
                                      key={`alma-mater-${university.name}`}
                                      value={university}
                                      className={({ active }) =>
                                        classNames(
                                          "relative cursor-default select-none py-2 pl-3 pr-9",
                                          active ? "bg-indigo-600 text-white" : "text-gray-900"
                                        )
                                      }
                                    >
                                      {({ active, selected }) => (
                                        <>
                                          <span className={classNames("block truncate", selected && "font-semibold")}>
                                            {university.name} ({university.country})
                                          </span>

                                          {selected && (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                                active ? "text-white" : "text-indigo-600"
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      )}
                    />
                    {errors.university && (
                      <p className="mt-2 text-xs text-red-600">{errors.university?.message?.toString()}</p>
                    )}
                    <div
                      className="mt-2 text-xs text-gray-500"
                    >
                      <Trans
                        i18nKey="profile.expert.universitiesFeedback"
                        components={[<NavLink className='text-indigo-400 font-medium' to='/feedback'></NavLink>]}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="job-title" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.expertise.degree.title")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.expert.expertise.degree.title")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        {...register("degree")}
                      />
                      {errors.degree && <p className="mt-2 text-xs text-red-600">{errors.degree?.message?.toString()}</p>}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="job-title" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.expertise.jobTitle.title")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.expert.expertise.jobTitle.title")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        {...register("title")}
                      />
                      {errors.title && <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>}
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.expertise.professionalNetworkProfile.title")}
                    </label>
                    <p className="text-sm text-gray-500">{t("profile.expert.expertise.professionalNetworkProfile.subtitle")}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder={t("profile.expert.expertise.professionalNetworkProfile.placeholder")}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        defaultValue={""}
                        {...register("professional_network_profile")}
                      />
                    </div>
                    {errors.professional_network_profile && (
                      <p className="mt-2 text-xs text-red-600">{errors.professional_network_profile?.message?.toString()}</p>
                    )}
                  </div>

                  <div className="hidden sm:col-span-4">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.expertise.job.title")}
                    </label>
                    <p className="text-sm text-gray-500">{t("profile.expert.expertise.job.subtitle")}</p>
                    <div className="mt-2">
                      <textarea
                        rows={4}
                        className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                        placeholder={t("profile.expert.expertise.job.title")}
                        defaultValue={""}
                        {...register("expertise")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-8">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("profile.expert.offer.title")}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t("profile.expert.offer.subtitle")}</p>

                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <Controller
                      control={control}
                      name="offer_interviews"
                      render={({ field }) => (
                        <Switch.Group as="div" className="flex items-center justify-between">
                          <span className="flex flex-grow flex-col">
                            <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                              {t("profile.expert.offer.interviews.title")}
                            </Switch.Label>
                            <Switch.Description as="span" className="text-sm text-gray-500">
                              {t("profile.expert.offer.interviews.subtitle")}
                            </Switch.Description>
                          </span>
                          <Switch
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              setOfferInterviews(e);
                            }}
                            className={classNames(
                              field.value ? "bg-indigo-600" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                field.value ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      )}
                    />
                    <textarea
                      rows={4}
                      placeholder={t("profile.expert.offer.interviews.placeholder")}
                      className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 mt-4 disabled:opacity-75 disabled:bg-gray-100"
                      defaultValue={""}
                      {...register("offer_interviews_description")}
                      disabled={!offerInterviews}
                    />
                  </div>
                  <div className="sm:col-span-4">
                    <Controller
                      control={control}
                      name="offer_internships"
                      render={({ field }) => (
                        <Switch.Group as="div" className="flex items-center justify-between">
                          <span className="flex flex-grow flex-col">
                            <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                              {t("profile.expert.offer.internships.title")}
                            </Switch.Label>
                            <Switch.Description as="span" className="text-sm text-gray-500">
                              {t("profile.expert.offer.internships.subtitle")}
                            </Switch.Description>
                          </span>
                          <Switch
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              setOfferInternships(e);
                            }}
                            className={classNames(
                              field.value ? "bg-indigo-600" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                field.value ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      )}
                    />
                    <label htmlFor="comment" className="block text-xs font-medium leading-6 text-gray-900 mt-2">
                      {t("profile.expert.offer.internships.subtext")}
                    </label>
                    <textarea
                      rows={4}
                      placeholder={t("profile.expert.offer.internships.placeholder")}
                      className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 mt-2 disabled:opacity-75 disabled:bg-gray-100"
                      defaultValue={""}
                      {...register("offer_internships_description")}
                      disabled={!offerInternships}
                    />
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-8">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("profile.expert.interests.title")}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t("profile.expert.interests.subtitle")}</p>

                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                      {t("profile.expert.interests.universities.title")}{" "}
                      {selectedUniversitiesOfInterest?.length > 0 && <>({selectedUniversitiesOfInterest?.length})</>}
                    </label>
                    <p className="text-sm text-gray-500 mb-6">{t("profile.expert.interests.universities.subtitle")}</p>
                    <Controller
                      control={control}
                      defaultValue={selectedUniversitiesOfInterest}
                      name="universitiesOfInterest"
                      render={({ field: { onChange } }) => (
                        <Combobox
                          as="div"
                          value={selectedUniversitiesOfInterest}
                          onChange={(e) => {
                            onChange(e);
                            setSelectedUniversitiesOfInterest(e);
                          }}
                          multiple
                        >
                          {selectedUniversitiesOfInterest?.length > 0 && (
                            <div className="pb-3.5">
                              {selectedUniversitiesOfInterest.map((e: any) => (
                                <span
                                  key={e.id}
                                  className="inline-flex items-center gap-x-1 rounded-full bg-gray-100 mb-2.5 mr-2.5 px-3 py-1.5 text-xs font-medium text-gray-600"
                                >
                                  {e.name} ({e.country})
                                  <button
                                    type="button"
                                    onClick={() => removeUniversity(e.id)}
                                    className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-800/20"
                                  >
                                    <span className="sr-only">Remove</span>
                                    <svg
                                      viewBox="0 0 14 14"
                                      className="h-3.5 w-3.5 stroke-gray-800/50 group-hover:stroke-gray-800/75"
                                    >
                                      <path d="M4 4l6 6m0-6l-6 6" />
                                    </svg>
                                    <span className="absolute -inset-1" />
                                  </button>
                                </span>
                              ))}
                            </div>
                          )}
                          <div className="relative">
                            <Combobox.Input
                              placeholder={t("profile.expert.interests.universities.placeholder")}
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 placeholder:text-gray-400 truncate"
                              onChange={handleQueryChange}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => { setQuery(""); setUniversities([]) }}
                            >
                              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                                {(!isFetchingUniversities && filteredUniversities.length === 0 && query.length > 1) ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    {t("profile.student.info.noUniversity")}
                                  </div>
                                ) : (
                                  filteredUniversities.map((university: any) => (
                                    <Combobox.Option
                                      key={university.name}
                                      value={university}
                                      className={({ active }) =>
                                        classNames(
                                          "relative cursor-default select-none py-2 pl-3 pr-9",
                                          active ? "bg-indigo-600 text-white" : "text-gray-900"
                                        )
                                      }
                                    >
                                      {({ active, selected }) => (
                                        <>
                                          <span className={classNames("block truncate", selected && "font-semibold")}>
                                            {university.name} ({university.country})
                                          </span>

                                          {selected && (
                                            <span
                                              className={classNames(
                                                "absolute inset-y-0 right-0 flex items-center pr-4",
                                                active ? "text-white" : "text-indigo-600"
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      )}
                    />
                    {errors.university && (
                      <p className="mt-2 text-xs text-red-600">{errors.university?.message?.toString()}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="my-6 flex items-center justify-end gap-x-6">
              {isUpdating && <Loader />}
              {!isUpdating && (
                <>
                  <button
                    type="button"
                    onClick={handleSubmit(onCancel)}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {t("common.cancel")}
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("common.save")}
                  </button>
                </>
              )}
            </div>
          </form>
          <div className="relative mt-16 mb-12">
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-3 text-sm font-medium text-gray-500">Settings</span>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold leading-7 text-gray-900">{t("profile.privacy.title")}</h3>
            <div className="mt-2 text-sm text-gray-500">
              <p>
                {t("profile.privacy.subtitle")}
              </p>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-10">
              <a id="privacy" href="#privacy" className="cky-banner-element text-sm/6 font-semibold text-gray-900">
                {t("profile.privacy.cookieSettings")}
              </a>
            </div>
          </div>
        </>
      )}
      <Transition show={openProfile} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenProfile}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-6">
                  <div className="absolute right-0 top-0 p-6 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpenProfile(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="text-center">
                    <DialogTitle as="h3" className="text-xl font-bold leading-6 text-gray-900 mb-6">
                      {t("common.myProfile")}
                    </DialogTitle>
                    {isUpdating && (
                      <div className="h-32 flex items-center justify-center">
                        <Loader />
                      </div>
                    )}
                    {!isUpdating && (
                      <div className="bg-white ring-1 ring-gray-100 rounded-full inline-block">
                        {expert.image && (
                          <img
                            className="h-24 w-24 rounded-full sm:h-28 sm:w-28 border object-cover"
                            src={`https://resources.thesisnavigator.com/${expert.image}`}
                            alt=""
                          />
                        )}
                        {!expert.image && (
                          <img
                            className="h-24 w-24 rounded-full sm:h-28 sm:w-28 border object-cover"
                            src={expert.picture}
                            alt=""
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 mb-2 sm:mb-0"
                      onClick={handleProfileImageClick}
                    >
                      {t("common.upload")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
                      onClick={handleRemoveProfileImage}
                      disabled={!expert.image}
                    >
                      {t("common.delete")}
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
