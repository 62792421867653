import {useForm} from "react-hook-form";
import {object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {MessageType, useMessagesContext} from "../../../contexts/messagesContext";
import {useParams} from "react-router-dom";
import Api from '../../../api/client';
import {AdminCompanyExpert, AdminCompanyExpertUpdate} from "@api/generated";
import {MinusCircleIcon} from "@heroicons/react/24/outline";
import ConfirmationDialog, {ConfirmationDialogType} from "../../../components/ConfirmationDialog";
import Modal from "../../../components/Modal";

export interface AdminCompanyUsersProps {
  onDelete: () => void;
  user: AdminCompanyExpert;
}

export default function AdminCompanyUserEdit({user, onDelete}: AdminCompanyUsersProps) {
  const {id} = useParams();
  const {t, i18n} = useTranslation();
  const {addMessage} = useMessagesContext();

  // Define the validation schema
  const validationSchema = object({
    firstName: string().min(2).required(),
    lastName: string().min(2).required(),
    role: string().nullable(),
  }).required();

  const {
    reset,
    register,
    handleSubmit,
    formState: {errors, dirtyFields},
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    },
    mode: "onChange"
  });

  const handleFieldBlur = async (fieldName: string, value: string) => {
    const isDirty: boolean | undefined = dirtyFields[fieldName];
    const error: any | undefined = errors[fieldName];
    if (isDirty === true && error === undefined) {
      const updateValue: AdminCompanyExpertUpdate = {}
      if (fieldName === 'role' && value === '') {
        updateValue[fieldName] = null
      } else {
        updateValue[fieldName] = value
      }
      await updateUser(updateValue);
    }
  }

  const [isUpdating, setIsUpdating] = useState(false);

  // Delete Confirmation
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);

  const updateUser = async (data: AdminCompanyExpertUpdate) => {
    setIsUpdating(true);
    try {
      const response = await Api.AdminCompanyExperts.updateAdminCompanyExpert(id, user.id, data);
      addMessage({
        type: MessageType.Success,
        title: "user.notification.update.title",
        description: "user.notification.update.description",
      });
      reset({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        role: response.data.role
      })
      setIsUpdating(false);
    } catch {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
      setIsUpdating(false);
    }
  };

  const onSubmit = async (data) => {
    await updateUser(data);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  async function deleteExpert() {
    closeDeleteConfirmation();
    try {
      setIsUpdating(true);
      await Api.AdminCompanyExperts.deleteAdminCompanyExpert(id, user.id)
      setIsUpdating(false);
      onDelete();
    } catch (e) {
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
      setIsUpdating(false);
      console.error(e);
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-6 lg:mt-2 grid md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-2 lg:gap-y-4">
        <div className="mt-2">
          <input
            type="text"
            autoComplete="given-name"
            placeholder={t("profile.user.firstName")}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100 read-only:bg-gray-50"
            {...register("firstName")}
            onBlur={(event) => handleFieldBlur('firstName', event.target.value)}
          />
          {errors.firstName && <p className="mt-2 text-xs text-red-600">{errors.firstName.message}</p>}
        </div>
        <div className="mt-2">
          <input
            type="text"
            autoComplete="given-name"
            placeholder={t("profile.user.lastName")}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100 read-only:bg-gray-50"
            {...register("lastName")}
            onBlur={(event) => handleFieldBlur('lastName', event.target.value)}
          />
          {errors.lastName && <p className="mt-2 text-xs text-red-600">{errors.lastName.message}</p>}
        </div>
        <div className={"md:col-span-2"}>
          <div className="sm:flex items-center justify-between gap-6">
            <div className="w-full">
              <div className="relative mt-2">
                <input
                  type="text"
                  value={user.email}
                  readOnly={true}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-75 disabled:bg-gray-100 read-only:bg-gray-50"
                />
                <div
                  className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                  aria-hidden="true"
                />
                <div className="absolute inset-y-0 right-0.5 flex items-center">
                  <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true"/>
                  <label htmlFor="role" className="sr-only">
                    Role
                  </label>
                  <select
                    id="role"
                    className="border-0 bg-white py-1 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 font-semibold cursor-pointer"
                    {...register("role")}
                    onBlur={(event) => handleFieldBlur('role', event.target.value)}
                  >
                    <option value="owner">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="">Expert</option>
                  </select>
                </div>
              </div>
              {errors.role && <p className="mt-2 text-xs text-red-600">{errors.role.message}</p>}
            </div>
            <div className="mt-4 sm:mt-2">
              <div className="flex items-center justify-between gap-6">
                <button
                  type="button"
                  disabled={user.lastLogin !== null || isUpdating}
                  onClick={() => {
                    setIsDeleteConfirmationOpen(true);
                  }}
                  className="inline-flex items-center rounded-md text-sm font-semibold text-indigo-700 disabled:opacity-75 disabled:cursor-not-allowed disabled:text-gray-300"
                >
                  <MinusCircleIcon className="h-6"/>
                  <span className="sm:hidden ml-1.5">{t("common.delete")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isDeleteConfirmationOpen} onClose={closeDeleteConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={`${t("company.team.delete.confirmation.title")} (${user.email})`}
          body={t("company.team.delete.confirmation.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteConfirmation}
          onSave={deleteExpert}
        />
      </Modal>
    </form>
  );
}
