import React, { PropsWithChildren } from "react";

// Define the prop types
interface ListItemLineEnumProps {
  items: string[];
  className?: string;
}

// Functional component with props
const ListItemLineEnum: React.FC<PropsWithChildren<ListItemLineEnumProps>> = ({ items, className = '', children }) => {
  return (
    <div className={`flex flex-wrap items-center gap-x-2 ${className}`}>
      {items.filter(item => item !== null && item !== undefined).map((item, index, array) => (
        <div key={item} className="hidden sm:flex flex-wrap items-center gap-x-2">
          <span className="truncate leading-6 text-gray-500">
            {item}
          </span>
          {index !== array.length - 1 && (
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
              <circle cx={1} cy={1} r={1} />
            </svg>
          )}
        </div>
      ))}
    </div>
  );
};

// Export the component
export default React.memo(ListItemLineEnum);
