import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../../contexts/userContext";
import Loader from "../../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../../config/https";
import { useTranslation } from "react-i18next";
import { CalendarIcon, CheckBadgeIcon, BuildingOffice2Icon, ArrowPathIcon, CheckIcon, ChevronUpDownIcon, Square3Stack3DIcon, AcademicCapIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react";

const PAGE_SIZE = 50;

const topicsStates = [
  { id: 1, value: 'all' },
  { id: 2, value: 'published' },
  { id: 3, value: 'draft' }
]

export default function AdminTopics() {
  const { currentUser } = useContext(CurrentUserContext);
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const [token, setToken] = useState("");
  const [topics, setTopics] = useState<any>([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [count, setCount] = useState(0);
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);
  const [companies, setCompanies] = useState<any>([]);
  const initialCompanyFromURL = searchParams.get("company");
  const initialCompanyFromLocalStorage = localStorage.getItem("admin-topics-company");
  const initialCompany = initialCompanyFromURL ? initialCompanyFromURL : initialCompanyFromLocalStorage;
  const [selectedCompany, setSelectedCompany] = useState(initialCompany);
  const [isFetchingCompanies, setIsFetchingCompanies] = useState(true);
  const initialTopicsStateFromURL = searchParams.get("state");
  const initialTopicsStateFromLocalStorage = localStorage.getItem("admin-topics-state");
  const initialTopicsState = initialTopicsStateFromURL || initialTopicsStateFromLocalStorage || 'all';
  const [selectedTopicsState, setSelectedTopicsState] = useState(initialTopicsState);

  const getTopics = async () => {
    setIsFetching(true);
    const params = new URLSearchParams();
    if (selectedCompany) {
      params.append("company", selectedCompany.toString());
    }
    if (selectedTopicsState === "published") {
      params.append("published", "true");
    }
    if (selectedTopicsState === "draft") {
      params.append("published", "false");
    }
    params.append("page", page.toString());
    params.append("size", PAGE_SIZE.toString());

    await httpsClient
      .get(`/api/v2/admin/topics?${params.toString()}`)
      .then((response) => {
        const sortedItems = response.data?.items?.sort((a: any, b: any) => {
          const aDate = a.updated ? new Date(a.updated) : new Date(a.created);
          const bDate = b.updated ? new Date(b.updated) : new Date(b.created);
          return bDate.getTime() - aDate.getTime();
        });
        setTopics(sortedItems || []);
        setCount(response.data?.total);
        setIsFetching(false);
      })
      .catch((error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 403) {
          navigate("/error/forbidden");
        }
        console.error("Error fetching data:", error);
      });
  };

  const getCompanies = async () => {
    setIsFetching(true);
    await httpsClient
      .get(`/api/v1/companies`)
      .then((response) => {
        setCompanies(response.data);
        setIsFetchingCompanies(false);
      })
      .catch((error) => {
        setIsFetchingCompanies(false);
      });
  };

  const handleCompanyChange = (id: string) => {
    setPage(1);
    setCount(0);
    setSelectedCompany(id);
  };

  const handleRemoveCompany = () => {
    setPage(1);
    setCount(0);
    setSelectedCompany("");
  };

  const handleTopicsStateChange = (value: string) => {
    setPage(1);
    setCount(0);
    setSelectedTopicsState(value);
  };

  const handleRemoveTopicsState = () => {
    setPage(1);
    setCount(0);
    setSelectedTopicsState("all");
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  const handleReloadTopics = () => {
    setCount(0);
    if (page > 1) {
      setPage(1);
    } else {
      getTopics();
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem("admin-topics-company", selectedCompany);
    } else {
      localStorage.removeItem("admin-topics-company");
    }
    const params = new URLSearchParams();

    if (selectedTopicsState === 'all') {
      localStorage.removeItem('admin-topics-state');
    } else {
      localStorage.setItem('admin-topics-state', selectedTopicsState);
    }

    if (selectedCompany) {
      params.append("company", selectedCompany.toString());
    }
    if (selectedTopicsState !== 'all') {
      params.append("state", selectedTopicsState.toString());
    }
    params.append("page", page.toString());
    navigate(`?${params.toString()}`, { replace: true });
  }, [selectedCompany, selectedTopicsState, page]);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getTopics();
  }, [currentUser, selectedTopicsState, selectedCompany, page]);

  useEffect(() => {
    getCompanies();
  }, []);

  const fetchToken = useCallback(async () => {
    const data = await getAccessTokenSilently();
    setToken(data);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="bg-white">
      <div className="pb-6 sm:pb-6">
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight">
              {t("common.topics")}
            </h2>
          </div>
        </div>
      </div>
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          {t("common.filters")}
        </h2>
        <div className="border-b border-gray-100 bg-white pb-6 mb-6">
          <div className="mx-auto flex items-center justify-between">
            <Listbox value={selectedCompany} onChange={handleCompanyChange}>
              <div className="relative w-44 sm:w-56">
                <ListboxButton className="relative w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none text-sm sm:leading-6 cursor-pointer">
                  <span className="block truncate">
                    {selectedCompany && !isFetchingCompanies ? (
                      <>{companies.filter((company: any) => company.id === selectedCompany)[0]?.name}</>
                    ) : (
                      <span className="text-gray-400">{t("common.allCompanies")}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  </span>
                </ListboxButton>
                <ListboxOptions
                  transition
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
                >
                  <ListboxOption
                    key={"allCompanies"}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer"
                      )
                    }
                    value={null}
                    onClick={() => handleCompanyChange("")}
                  >
                    <>
                      <span className="font-normal block truncate">{t("common.allCompanies")}</span>
                    </>
                  </ListboxOption>
                  {companies.map((company: any) => (
                    <ListboxOption
                      key={company.id}
                      value={company.id}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white cursor-pointer"
                    >
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {company.name}
                      </span>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                      </span>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
            <div className="block">
              <div className="flex items-center">
                <div className="w-32 sm:w-56">
                  <Listbox value={selectedTopicsState} onChange={setSelectedTopicsState}>
                    <div className="relative">
                      <ListboxButton className="relative w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none text-sm sm:leading-6 cursor-pointer">
                        <span className={classNames(selectedTopicsState === 'all' ? "text-gray-400" : "text-gray-900", "block truncate")}>{t(`common.${selectedTopicsState}`)}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </span>
                      </ListboxButton>
                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in text-sm"
                      >
                        {topicsStates.map((state) => (
                          <ListboxOption
                            key={state.id}
                            value={state.value}
                            className="group relative cursor-pointer select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                            onClick={() => handleTopicsStateChange(state.value)}
                          >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold">{t(`common.${state.value}`)}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                </div>
                <button
                  type="button"
                  className="ml-6 h-6 w-6 text-gray-400 hover:text-gray-600"
                  onClick={() => handleReloadTopics()}
                >
                  <ArrowPathIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        {(selectedCompany || selectedTopicsState !== 'all') && (
          <div className="bg-gray-50 -mx-8 mb-6">
            <div className="mx-auto px-8 py-3 sm:flex sm:items-center">
              <h3 className="text-sm font-medium text-gray-500 hidden sm:block">
                {t("common.filters")}
                <span className="sr-only">, active</span>
              </h3>
              <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block" />
              <div className="mt-0 sm:ml-4">
                <div className="-m-1 flex flex-wrap items-center">
                  {selectedCompany && !isFetchingCompanies && (
                    <span
                      key={selectedCompany}
                      className="m-1 inline-flex items-center rounded-full border border-yellow-600/20 bg-yellow-50 py-1.5 pl-3 pr-2 text-sm font-medium text-yellow-800"
                    >
                      <span>{companies.filter((company: any) => company.id === selectedCompany)[0]?.name}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-yellow-700 hover:bg-yellow-200 hover:text-yellow-800"
                        onClick={() => handleRemoveCompany()}
                      >
                        <span className="sr-only">Remove filter for {selectedCompany}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  )}
                  {selectedTopicsState !== "all" && (
                    <span
                      key={selectedTopicsState}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{t(`common.${selectedTopicsState}`)}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={handleRemoveTopicsState}
                      >
                        <span className="sr-only">Remove filter for {selectedTopicsState}</span>
                        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {isFetching && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <ul role="list" className="divide-y divide-gray-100 [&>*:first-child]:pt-0">
          {topics?.map((topic: any) => (
            <li
              key={topic.id}
              className="flex items-start justify-between gap-y-8 py-8"
            >
              <div className="mr-6 sm:mr-8 flex-shrink-0">
                <NavLink
                  to={`/companies/${topic.company?.id}`}
                  className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75"
                >
                  {topic.company?.picture && (
                    <img
                      className="h-14 w-14 sm:h-20 sm:w-20 object-contain"
                      src={`https://resources.thesisnavigator.com/${topic.company?.picture}`}
                      alt=""
                    />
                  )}
                  {!topic.company?.picture && (
                    <div className="h-14 w-14 sm:h-20 sm:w-20 p-2 rounded-md">
                      <BuildingOffice2Icon className="fill-gray-200" />
                    </div>
                  )}
                </NavLink>
              </div>
              <div className="group relative w-full">
                <div className="flex items-start gap-x-2 text-xs text-gray-500">
                  <div className="flex flex-wrap items-center gap-x-2">
                    <NavLink
                      to={`/companies/${topic.company?.id}`}
                      className="whitespace-nowrap flex items-center gap-x-1 hover:opacity-75"
                    >
                      <span className="font-semibold text-gray-900">{topic.company?.name}</span>{" "}
                      <CheckBadgeIcon className="h-5 text-yellow-400" />
                    </NavLink>
                    {topic.company?.addresses && (
                      <>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <div className="hidden sm:flex flex-wrap items-center gap-x-2">
                          <span className="truncate leading-6 text-gray-500">
                            {topic.company?.addresses?.city}
                          </span>
                        </div>
                      </>
                    )}
                    {topic.company?.size && (
                      <>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-gray-300 hidden sm:flex">
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <div className="hidden sm:flex flex-wrap items-center gap-x-2 ">
                          <span className="leading-6 text-gray-500">
                            {t(`company.size.type.${topic.company?.size}`)}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="min-w-0 flex-auto">
                  <div className="flex items-start gap-x-3 mb-2">
                    <h2 className="text-xl font-bold leading-7 text-gray-900">
                      <NavLink to={`/admin/topics/${topic.id}`}>{topic.title}</NavLink>
                    </h2>
                  </div>
                  <div className="flex flex-wrap gap-x-4 gap-y-1 items-center">
                    <div className="flex items-center text-sm text-gray-400 space-x-4">
                      <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      {
                        topic.created === topic.updated
                          ? <>{moment(topic.created).locale(currentLanguage).format("MMM DD, YYYY")}</>
                          : <>{t("common.updated")} {moment(topic.updated).locale(currentLanguage).format("MMM DD, YYYY")}</>
                      }
                    </div>
                    {topic.experts?.length > 0 && (
                      <div className="flex overflow-hidden -space-x-1.5 flex-shrink-0">
                        {topic.experts?.map((e: any) =>
                          e?.user.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${e.user.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={e.user.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                    )}
                    {!topic.published && (
                      <p className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1.5 text-xs font-medium text-yellow-800">
                        {t("common.draft")}
                      </p>
                    )}
                    {topic.thesis_projects?.length > 0 && (
                      <div className="relative z-10 text-sm text-gray-400 flex items-center">
                        {topic.thesis_projects?.length === 1 ? t("common.thesisProject") : t("common.thesisProjects")}
                        <span className="bg-red-500 text-white text-2xs font-medium rounded-full h-5 w-5 flex items-center justify-center absolute -top-2 -right-2.5 border-2 border-white rounded-full transform translate-x-1/2">
                          {topic.thesis_projects?.length}
                        </span>
                      </div>
                    )}
                  </div>
                  {topic.supervisor_topic_interests?.length > 0 && (
                    <div className="mt-3 flex items-center space-x-4">
                      <div className="relative group/bookmark">
                        <AcademicCapIcon
                          className="text-yellow-400 hover:text-yellow-300 w-5 flex-shrink-0 z-10"
                          aria-hidden="true"
                        />
                        <div className="hidden sm:block absolute transition delay-300 duration-300 left-1/2 bottom-8 text-center transform -translate-x-1/2 bg-gray-800 text-white rounded-md px-2.5 py-1.5 text-xs font-medium w-28 z-20 pointer-events-none opacity-0 transition-opacity group-hover/bookmark:opacity-100">
                          {t("topics.supervisor.interestedInSupervising")}
                          <div className="invisible absolute left-12 bg-inherit before:visible before:absolute before:h-2.5 before:w-2.5 before:rotate-45 before:bg-inherit before:content-['']"></div>
                        </div>
                      </div>
                      <div className="flex -space-x-1.5 overflow-hidden items-center flex-shrink-0">
                        {topic.supervisor_topic_interests.slice(0, 2).map((e: any) =>
                          e?.supervisor?.user?.image ? (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={`https://resources.thesisnavigator.com/${e.supervisor?.user?.image}`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="inline-block h-6 w-6 rounded-full border object-cover"
                              src={e.supervisor?.user?.picture}
                              alt=""
                            />
                          )
                        )}
                      </div>
                      <div className="text-gray-500 text-xs inline-block">
                        {topic.supervisor_topic_interests.length === 1 ? t("topics.supervisor.interestedOne", { value: `${topic.supervisor_topic_interests[0].supervisor?.user?.first_name} ${topic.supervisor_topic_interests[0].supervisor?.user?.last_name}` }) : t("topics.supervisor.interestedMany", { value1: `${topic.supervisor_topic_interests[0]?.supervisor?.user.first_name} ${topic.supervisor_topic_interests[0]?.supervisor?.user?.last_name}`, value2: topic.supervisor_topic_interests.length - 1 })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {isAuthenticated && !isLoading && !isFetching && topics?.length === 0 && (
        <div className="text-center">
          <Square3Stack3DIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">{t("common.noTopics")}</h3>
        </div>
      )}
      {count > 0 && (
        <nav className="flex items-center justify-between bg-white py-6" aria-label="Pagination">
          <div className="sm:block">
            <p className="text-sm text-gray-700">
              {count >= PAGE_SIZE && (
                <>
                  <span className="font-semibold">{PAGE_SIZE * (page - 1) + 1}</span> {t("common.to")}{" "}
                  <span className="font-semibold">{PAGE_SIZE * page}</span> {t("common.of")}
                </>
              )}{" "}
              <span className="font-semibold">{count}</span> {count === 1 ? t("common.topic") : t("common.topics")}
            </p>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-slate-100 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              {t("common.previous")}
            </button>
            <button
              onClick={handleNextPage}
              disabled={page >= Math.ceil(count / PAGE_SIZE)}
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-slate-100 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              {t("common.next")}
            </button>
          </div>
        </nav>
      )}
    </div>
  );
}
