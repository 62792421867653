/* tslint:disable */
/* eslint-disable */
/**
 * Thesis Navigator API
 * All documentation for the Thesis Navigator API.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postal_code': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'latitude': number | null;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'longitude': number | null;
}
/**
 * 
 * @export
 * @interface AdminCompanyCreate
 */
export interface AdminCompanyCreate {
    /**
     * The domains of the company.
     * @type {Array<string>}
     * @memberof AdminCompanyCreate
     */
    'domains'?: Array<string>;
    /**
     * The name of the company.
     * @type {string}
     * @memberof AdminCompanyCreate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AdminCompanyExpert
 */
export interface AdminCompanyExpert {
    /**
     * The role of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'role'?: AdminCompanyExpertRoleEnum;
    /**
     * The id of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'id': string;
    /**
     * The user email.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'email': string;
    /**
     * The user first name.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'firstName': string;
    /**
     * The user last name.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'lastName': string;
    /**
     * The last login date of the expert.
     * @type {string}
     * @memberof AdminCompanyExpert
     */
    'lastLogin'?: string | null;
}

export const AdminCompanyExpertRoleEnum = {
    Owner: 'owner',
    Admin: 'admin'
} as const;

export type AdminCompanyExpertRoleEnum = typeof AdminCompanyExpertRoleEnum[keyof typeof AdminCompanyExpertRoleEnum];

/**
 * 
 * @export
 * @interface AdminCompanyExpertCreate
 */
export interface AdminCompanyExpertCreate {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertCreate
     */
    'role'?: AdminCompanyExpertCreateRoleEnum;
    /**
     * The user email.
     * @type {string}
     * @memberof AdminCompanyExpertCreate
     */
    'email': string;
    /**
     * The user first name.
     * @type {string}
     * @memberof AdminCompanyExpertCreate
     */
    'firstName': string;
    /**
     * The user last name.
     * @type {string}
     * @memberof AdminCompanyExpertCreate
     */
    'lastName': string;
}

export const AdminCompanyExpertCreateRoleEnum = {
    Owner: 'owner',
    Admin: 'admin'
} as const;

export type AdminCompanyExpertCreateRoleEnum = typeof AdminCompanyExpertCreateRoleEnum[keyof typeof AdminCompanyExpertCreateRoleEnum];

/**
 * 
 * @export
 * @interface AdminCompanyExpertPage
 */
export interface AdminCompanyExpertPage {
    /**
     * Returns the page content as List.
     * @type {Array<AdminCompanyExpert>}
     * @memberof AdminCompanyExpertPage
     */
    'items': Array<AdminCompanyExpert>;
    /**
     * Returns the number of the current page.
     * @type {number}
     * @memberof AdminCompanyExpertPage
     */
    'page': number;
    /**
     * Returns the size of the page.
     * @type {number}
     * @memberof AdminCompanyExpertPage
     */
    'size': number;
    /**
     * Returns the total amount of elements.
     * @type {number}
     * @memberof AdminCompanyExpertPage
     */
    'total': number;
    /**
     * Returns the total amount of pages.
     * @type {number}
     * @memberof AdminCompanyExpertPage
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface AdminCompanyExpertUpdate
 */
export interface AdminCompanyExpertUpdate {
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyExpertUpdate
     */
    'role'?: AdminCompanyExpertUpdateRoleEnum | null;
    /**
     * The user first name.
     * @type {string}
     * @memberof AdminCompanyExpertUpdate
     */
    'firstName'?: string;
    /**
     * The user last name.
     * @type {string}
     * @memberof AdminCompanyExpertUpdate
     */
    'lastName'?: string;
}

export const AdminCompanyExpertUpdateRoleEnum = {
    Owner: 'owner',
    Admin: 'admin'
} as const;

export type AdminCompanyExpertUpdateRoleEnum = typeof AdminCompanyExpertUpdateRoleEnum[keyof typeof AdminCompanyExpertUpdateRoleEnum];

/**
 * 
 * @export
 * @interface AdminCompanyUpdate
 */
export interface AdminCompanyUpdate {
    /**
     * The domains of the company.
     * @type {Array<string>}
     * @memberof AdminCompanyUpdate
     */
    'domains'?: Array<string>;
    /**
     * The name of the company.
     * @type {string}
     * @memberof AdminCompanyUpdate
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'picture': string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'uid': string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'background': string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'size': string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'updated': string;
    /**
     * 
     * @type {Address}
     * @memberof Company
     */
    'address'?: Address;
    /**
     * 
     * @type {Array<Industry>}
     * @memberof Company
     */
    'industries'?: Array<Industry>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    'domains'?: Array<string>;
    /**
     * 
     * @type {CompanyCount}
     * @memberof Company
     */
    '_count'?: CompanyCount;
}
/**
 * 
 * @export
 * @interface CompanyCount
 */
export interface CompanyCount {
    /**
     * 
     * @type {number}
     * @memberof CompanyCount
     */
    'experts': number;
}
/**
 * 
 * @export
 * @interface CompanyPage
 */
export interface CompanyPage {
    /**
     * Returns the page content as List.
     * @type {Array<Company>}
     * @memberof CompanyPage
     */
    'items': Array<Company>;
    /**
     * Returns the number of the current page.
     * @type {number}
     * @memberof CompanyPage
     */
    'page': number;
    /**
     * Returns the size of the page.
     * @type {number}
     * @memberof CompanyPage
     */
    'size': number;
    /**
     * Returns the total amount of elements.
     * @type {number}
     * @memberof CompanyPage
     */
    'total': number;
    /**
     * Returns the total amount of pages.
     * @type {number}
     * @memberof CompanyPage
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface CreateSupervisorTopicInterest
 */
export interface CreateSupervisorTopicInterest {
    /**
     * The id of the topic the supervisor express interest.
     * @type {string}
     * @memberof CreateSupervisorTopicInterest
     */
    'topic_id': string;
}
/**
 * 
 * @export
 * @interface CreateThesisProjectComment
 */
export interface CreateThesisProjectComment {
    /**
     * The description of the comment.
     * @type {string}
     * @memberof CreateThesisProjectComment
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateTopic
 */
export interface CreateTopic {
    /**
     * The internship of the topic. Possible values: yes, open, no
     * @type {string}
     * @memberof CreateTopic
     */
    'internship'?: CreateTopicInternshipEnum;
    /**
     * The level of the topic. Possible values: bsc, msc, phd
     * @type {Array<string>}
     * @memberof CreateTopic
     */
    'levels'?: Array<CreateTopicLevelsEnum>;
    /**
     * The title of the topic.
     * @type {string}
     * @memberof CreateTopic
     */
    'title': string;
    /**
     * The description of the topic.
     * @type {string}
     * @memberof CreateTopic
     */
    'description'?: string;
    /**
     * The start date of the topic.
     * @type {string}
     * @memberof CreateTopic
     */
    'timeframe_start_date'?: string;
    /**
     * The end date of the topic.
     * @type {string}
     * @memberof CreateTopic
     */
    'timeframe_end_date'?: string;
    /**
     * The quantitative state of the topic.
     * @type {boolean}
     * @memberof CreateTopic
     */
    'quantitative': boolean;
    /**
     * The published state of the topic.
     * @type {boolean}
     * @memberof CreateTopic
     */
    'published': boolean;
}

export const CreateTopicInternshipEnum = {
    Yes: 'yes',
    Open: 'open',
    No: 'no'
} as const;

export type CreateTopicInternshipEnum = typeof CreateTopicInternshipEnum[keyof typeof CreateTopicInternshipEnum];
export const CreateTopicLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;

export type CreateTopicLevelsEnum = typeof CreateTopicLevelsEnum[keyof typeof CreateTopicLevelsEnum];

/**
 * 
 * @export
 * @interface Expert
 */
export interface Expert {
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'expertise': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'offer_interviews': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Expert
     */
    'offer_internships': boolean;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'offer_internships_description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'degree': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'university_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'offer_interviews_description': string | null;
    /**
     * 
     * @type {string}
     * @memberof Expert
     */
    'professional_network_profile': string | null;
    /**
     * 
     * @type {User}
     * @memberof Expert
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface Faculty
 */
export interface Faculty {
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    'abbreviation': string;
}
/**
 * 
 * @export
 * @interface Industry
 */
export interface Industry {
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface InviteSupervisor
 */
export interface InviteSupervisor {
    /**
     * The email of the supervisor to invite.
     * @type {string}
     * @memberof InviteSupervisor
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'research_area': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'skills': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'field_of_study': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'university_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'goal': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'internship': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'timeframe_start_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'timeframe_end_date': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Student
     */
    'is_primary': boolean;
    /**
     * 
     * @type {User}
     * @memberof Student
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface Supervisor
 */
export interface Supervisor {
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'research_area': string | null;
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'professional_network_profile': string | null;
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'research_network_profile': string | null;
    /**
     * 
     * @type {string}
     * @memberof Supervisor
     */
    'invited': string;
    /**
     * 
     * @type {boolean}
     * @memberof Supervisor
     */
    'is_primary': boolean;
    /**
     * 
     * @type {User}
     * @memberof Supervisor
     */
    'user'?: User;
    /**
     * 
     * @type {University}
     * @memberof Supervisor
     */
    'university'?: University;
    /**
     * 
     * @type {Supervisor}
     * @memberof Supervisor
     */
    'invited_by'?: Supervisor;
}
/**
 * 
 * @export
 * @interface SupervisorTopicInterest
 */
export interface SupervisorTopicInterest {
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicInterest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicInterest
     */
    'topic_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicInterest
     */
    'supervisor_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof SupervisorTopicInterest
     */
    'created': string;
    /**
     * 
     * @type {Supervisor}
     * @memberof SupervisorTopicInterest
     */
    'supervisor'?: Supervisor | null;
}
/**
 * 
 * @export
 * @interface ThesisProject
 */
export interface ThesisProject {
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'updated': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'company_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'topic_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'student_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'motivation': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'cv_file': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'cv_filename': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'notes': string | null;
    /**
     * 
     * @type {object}
     * @memberof ThesisProject
     */
    'state': object | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'state_reason_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'state_reason_notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProject
     */
    'updated_by': string | null;
    /**
     * 
     * @type {Array<Expert>}
     * @memberof ThesisProject
     */
    'experts'?: Array<Expert>;
    /**
     * 
     * @type {Student}
     * @memberof ThesisProject
     */
    'student'?: Student;
    /**
     * 
     * @type {Array<Company>}
     * @memberof ThesisProject
     */
    'companies'?: Array<Company>;
}
/**
 * 
 * @export
 * @interface ThesisProjectActivity
 */
export interface ThesisProjectActivity {
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectActivity
     */
    'type': ThesisProjectActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectActivity
     */
    'date': string;
    /**
     * 
     * @type {ThesisProjectComment}
     * @memberof ThesisProjectActivity
     */
    'comment'?: ThesisProjectComment;
    /**
     * 
     * @type {ThesisProjectHistory}
     * @memberof ThesisProjectActivity
     */
    'history'?: ThesisProjectHistory;
}

export const ThesisProjectActivityTypeEnum = {
    Comment: 'comment',
    History: 'history'
} as const;

export type ThesisProjectActivityTypeEnum = typeof ThesisProjectActivityTypeEnum[keyof typeof ThesisProjectActivityTypeEnum];

/**
 * 
 * @export
 * @interface ThesisProjectComment
 */
export interface ThesisProjectComment {
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'updated': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'thesis_project_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'student_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'expert_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectComment
     */
    'description': string;
    /**
     * 
     * @type {Expert}
     * @memberof ThesisProjectComment
     */
    'expert'?: Expert;
    /**
     * 
     * @type {Student}
     * @memberof ThesisProjectComment
     */
    'student'?: Student;
    /**
     * 
     * @type {ThesisProject}
     * @memberof ThesisProjectComment
     */
    'thesis_project'?: ThesisProject;
}
/**
 * 
 * @export
 * @interface ThesisProjectHistory
 */
export interface ThesisProjectHistory {
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'change_date': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'updated': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'thesis_project_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'motivation': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'cv_file': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'cv_filename': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'old_state_reason_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'new_state_reason_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'state_reason_notes': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectHistory
     */
    'updated_by': string | null;
    /**
     * 
     * @type {object}
     * @memberof ThesisProjectHistory
     */
    'changes': object | null;
    /**
     * 
     * @type {ThesisProjectStateReason}
     * @memberof ThesisProjectHistory
     */
    'new_state_reason'?: ThesisProjectStateReason | null;
    /**
     * 
     * @type {ThesisProjectStateReason}
     * @memberof ThesisProjectHistory
     */
    'old_state_reason'?: ThesisProjectStateReason | null;
    /**
     * 
     * @type {ThesisProject}
     * @memberof ThesisProjectHistory
     */
    'thesis_project'?: ThesisProject | null;
    /**
     * 
     * @type {User}
     * @memberof ThesisProjectHistory
     */
    'user'?: User | null;
}
/**
 * 
 * @export
 * @interface ThesisProjectPage
 */
export interface ThesisProjectPage {
    /**
     * 
     * @type {Array<Array<ThesisProject>>}
     * @memberof ThesisProjectPage
     */
    'items': Array<Array<ThesisProject>>;
    /**
     * Returns the number of the current page.
     * @type {number}
     * @memberof ThesisProjectPage
     */
    'page': number;
    /**
     * Returns the size of the page.
     * @type {number}
     * @memberof ThesisProjectPage
     */
    'size': number;
    /**
     * Returns the total amount of elements.
     * @type {number}
     * @memberof ThesisProjectPage
     */
    'total': number;
    /**
     * Returns the total amount of pages.
     * @type {number}
     * @memberof ThesisProjectPage
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface ThesisProjectStateReason
 */
export interface ThesisProjectStateReason {
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectStateReason
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectStateReason
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof ThesisProjectStateReason
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * The internship of the topic.
     * @type {string}
     * @memberof Topic
     */
    'internship'?: TopicInternshipEnum;
    /**
     * The level of the topic.
     * @type {Array<string>}
     * @memberof Topic
     */
    'levels'?: Array<TopicLevelsEnum>;
    /**
     * The id of the topic.
     * @type {string}
     * @memberof Topic
     */
    'id': string;
    /**
     * The active state of the topic.
     * @type {boolean}
     * @memberof Topic
     */
    'active': boolean;
    /**
     * 
     * @type {Company}
     * @memberof Topic
     */
    'company'?: Company;
    /**
     * The created date of the topic.
     * @type {string}
     * @memberof Topic
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'deeplink'?: string;
    /**
     * The description of the topic.
     * @type {string}
     * @memberof Topic
     */
    'description'?: string;
    /**
     * The university name of the topic.
     * @type {string}
     * @memberof Topic
     */
    'university'?: string;
    /**
     * 
     * @type {Faculty}
     * @memberof Topic
     */
    'faculty'?: Faculty;
    /**
     * The bookmarked state of the topic.
     * @type {boolean}
     * @memberof Topic
     */
    'isBookmarked': boolean;
    /**
     * The keywords of the topic.
     * @type {Array<string>}
     * @memberof Topic
     */
    'keywords'?: Array<string>;
    /**
     * The published state of the topic.
     * @type {boolean}
     * @memberof Topic
     */
    'published': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    'quantitative': boolean;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'research_field'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Topic
     */
    'supervisors'?: Array<User>;
    /**
     * 
     * @type {Array<Expert>}
     * @memberof Topic
     */
    'experts'?: Array<Expert>;
    /**
     * 
     * @type {Array<ThesisProject>}
     * @memberof Topic
     */
    'thesis_projects'?: Array<ThesisProject>;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'timeframe_end_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'timeframe_start_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    'updated': string;
    /**
     * 
     * @type {Array<SupervisorTopicInterest>}
     * @memberof Topic
     */
    'supervisor_topic_interests'?: Array<SupervisorTopicInterest>;
}

export const TopicInternshipEnum = {
    Yes: 'yes',
    Open: 'open',
    No: 'no'
} as const;

export type TopicInternshipEnum = typeof TopicInternshipEnum[keyof typeof TopicInternshipEnum];
export const TopicLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;

export type TopicLevelsEnum = typeof TopicLevelsEnum[keyof typeof TopicLevelsEnum];

/**
 * 
 * @export
 * @interface TopicPage
 */
export interface TopicPage {
    /**
     * Returns the page content as List.
     * @type {Array<Topic>}
     * @memberof TopicPage
     */
    'items': Array<Topic>;
    /**
     * Returns the number of the current page.
     * @type {number}
     * @memberof TopicPage
     */
    'page': number;
    /**
     * Returns the size of the page.
     * @type {number}
     * @memberof TopicPage
     */
    'size': number;
    /**
     * Returns the total amount of elements.
     * @type {number}
     * @memberof TopicPage
     */
    'total': number;
    /**
     * Returns the total amount of pages.
     * @type {number}
     * @memberof TopicPage
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface University
 */
export interface University {
    /**
     * 
     * @type {string}
     * @memberof University
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface UpdateThesisProjectComment
 */
export interface UpdateThesisProjectComment {
    /**
     * The description of the comment.
     * @type {string}
     * @memberof UpdateThesisProjectComment
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface UpdateTopic
 */
export interface UpdateTopic {
    /**
     * The internship of the topic. Possible values: yes, open, no
     * @type {string}
     * @memberof UpdateTopic
     */
    'internship'?: UpdateTopicInternshipEnum;
    /**
     * The level of the topic. Possible values: bsc, msc, phd
     * @type {Array<string>}
     * @memberof UpdateTopic
     */
    'levels'?: Array<UpdateTopicLevelsEnum>;
    /**
     * The title of the topic.
     * @type {string}
     * @memberof UpdateTopic
     */
    'title'?: string;
    /**
     * The description of the topic.
     * @type {string}
     * @memberof UpdateTopic
     */
    'description'?: string;
    /**
     * The start date of the topic.
     * @type {string}
     * @memberof UpdateTopic
     */
    'timeframe_start_date'?: string;
    /**
     * The end date of the topic.
     * @type {string}
     * @memberof UpdateTopic
     */
    'timeframe_end_date'?: string;
    /**
     * The quantitative state of the topic.
     * @type {boolean}
     * @memberof UpdateTopic
     */
    'quantitative'?: boolean;
    /**
     * The published state of the topic.
     * @type {boolean}
     * @memberof UpdateTopic
     */
    'published'?: boolean;
}

export const UpdateTopicInternshipEnum = {
    Yes: 'yes',
    Open: 'open',
    No: 'no'
} as const;

export type UpdateTopicInternshipEnum = typeof UpdateTopicInternshipEnum[keyof typeof UpdateTopicInternshipEnum];
export const UpdateTopicLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;

export type UpdateTopicLevelsEnum = typeof UpdateTopicLevelsEnum[keyof typeof UpdateTopicLevelsEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'picture': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string | null;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'language': object;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_login': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'image': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'sysadmin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'policy_accepted': boolean;
}

/**
 * AdminCompaniesApi - axios parameter creator
 * @export
 */
export const AdminCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompanyCreate} adminCompanyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompany: async (adminCompanyCreate: AdminCompanyCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCompanyCreate' is not null or undefined
            assertParamExists('createAdminCompany', 'adminCompanyCreate', adminCompanyCreate)
            const localVarPath = `/api/v2/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompany: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminCompany', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminCompanyById', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Companies
         * @param {string} [search] Search terms.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanies: async (search?: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {string} id 
         * @param {AdminCompanyUpdate} adminCompanyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompany: async (id: string, adminCompanyUpdate: AdminCompanyUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminCompany', 'id', id)
            // verify required parameter 'adminCompanyUpdate' is not null or undefined
            assertParamExists('updateAdminCompany', 'adminCompanyUpdate', adminCompanyUpdate)
            const localVarPath = `/api/v2/admin/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompaniesApi - functional programming interface
 * @export
 */
export const AdminCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompanyCreate} adminCompanyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminCompany(adminCompanyCreate: AdminCompanyCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminCompany(adminCompanyCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.createAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminCompany(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminCompany(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.deleteAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminCompanyById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminCompanyById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.findAdminCompanyById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Companies
         * @param {string} [search] Search terms.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminCompanies(search?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminCompanies(search, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.findAllAdminCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {string} id 
         * @param {AdminCompanyUpdate} adminCompanyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminCompany(id: string, adminCompanyUpdate: AdminCompanyUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminCompany(id, adminCompanyUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompaniesApi.updateAdminCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminCompaniesApi - factory interface
 * @export
 */
export const AdminCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Company.
         * @param {AdminCompanyCreate} adminCompanyCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompany(adminCompanyCreate: AdminCompanyCreate, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.createAdminCompany(adminCompanyCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Company by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompany(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAdminCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Company by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.findAdminCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Companies
         * @param {string} [search] Search terms.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanies(search?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<CompanyPage> {
            return localVarFp.findAllAdminCompanies(search, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Company by ID.
         * @param {string} id 
         * @param {AdminCompanyUpdate} adminCompanyUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompany(id: string, adminCompanyUpdate: AdminCompanyUpdate, options?: RawAxiosRequestConfig): AxiosPromise<Company> {
            return localVarFp.updateAdminCompany(id, adminCompanyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCompaniesApi - object-oriented interface
 * @export
 * @class AdminCompaniesApi
 * @extends {BaseAPI}
 */
export class AdminCompaniesApi extends BaseAPI {
    /**
     * 
     * @summary Create a Company.
     * @param {AdminCompanyCreate} adminCompanyCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public createAdminCompany(adminCompanyCreate: AdminCompanyCreate, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).createAdminCompany(adminCompanyCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Company by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public deleteAdminCompany(id: string, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).deleteAdminCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Company by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public findAdminCompanyById(id: string, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).findAdminCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Companies
     * @param {string} [search] Search terms.
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public findAllAdminCompanies(search?: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).findAllAdminCompanies(search, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Company by ID.
     * @param {string} id 
     * @param {AdminCompanyUpdate} adminCompanyUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompaniesApi
     */
    public updateAdminCompany(id: string, adminCompanyUpdate: AdminCompanyUpdate, options?: RawAxiosRequestConfig) {
        return AdminCompaniesApiFp(this.configuration).updateAdminCompany(id, adminCompanyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminCompanyExpertsApi - axios parameter creator
 * @export
 */
export const AdminCompanyExpertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {string} companyId 
         * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompanyExpert: async (companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'adminCompanyExpertCreate' is not null or undefined
            assertParamExists('createAdminCompanyExpert', 'adminCompanyExpertCreate', adminCompanyExpertCreate)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyExpertCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompanyExpert: async (companyId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminCompanyExpert', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyExpertById: async (companyId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('findAdminCompanyExpertById', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminCompanyExpertById', 'id', id)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {string} companyId 
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanyExperts: async (companyId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('findAllAdminCompanyExperts', 'companyId', companyId)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {string} companyId 
         * @param {string} id 
         * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompanyExpert: async (companyId: string, id: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'id', id)
            // verify required parameter 'adminCompanyExpertUpdate' is not null or undefined
            assertParamExists('updateAdminCompanyExpert', 'adminCompanyExpertUpdate', adminCompanyExpertUpdate)
            const localVarPath = `/api/v2/admin/companies/{companyId}/experts/{id}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCompanyExpertUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminCompanyExpertsApi - functional programming interface
 * @export
 */
export const AdminCompanyExpertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminCompanyExpertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {string} companyId 
         * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminCompanyExpert(companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminCompanyExpert(companyId, adminCompanyExpertCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.createAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminCompanyExpert(companyId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminCompanyExpert(companyId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.deleteAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminCompanyExpertById(companyId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminCompanyExpertById(companyId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.findAdminCompanyExpertById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {string} companyId 
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminCompanyExperts(companyId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpertPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminCompanyExperts(companyId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.findAllAdminCompanyExperts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {string} companyId 
         * @param {string} id 
         * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminCompanyExpert(companyId: string, id: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyExpert>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminCompanyExpert(companyId, id, adminCompanyExpertUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminCompanyExpertsApi.updateAdminCompanyExpert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminCompanyExpertsApi - factory interface
 * @export
 */
export const AdminCompanyExpertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminCompanyExpertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Company Expert.
         * @param {string} companyId 
         * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminCompanyExpert(companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.createAdminCompanyExpert(companyId, adminCompanyExpertCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Company Expert
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminCompanyExpert(companyId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAdminCompanyExpert(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Company Expert by ID
         * @param {string} companyId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminCompanyExpertById(companyId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.findAdminCompanyExpertById(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Company Experts
         * @param {string} companyId 
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminCompanyExperts(companyId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpertPage> {
            return localVarFp.findAllAdminCompanyExperts(companyId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Company Expert.
         * @param {string} companyId 
         * @param {string} id 
         * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminCompanyExpert(companyId: string, id: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options?: RawAxiosRequestConfig): AxiosPromise<AdminCompanyExpert> {
            return localVarFp.updateAdminCompanyExpert(companyId, id, adminCompanyExpertUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminCompanyExpertsApi - object-oriented interface
 * @export
 * @class AdminCompanyExpertsApi
 * @extends {BaseAPI}
 */
export class AdminCompanyExpertsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Company Expert.
     * @param {string} companyId 
     * @param {AdminCompanyExpertCreate} adminCompanyExpertCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public createAdminCompanyExpert(companyId: string, adminCompanyExpertCreate: AdminCompanyExpertCreate, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).createAdminCompanyExpert(companyId, adminCompanyExpertCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Company Expert
     * @param {string} companyId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public deleteAdminCompanyExpert(companyId: string, id: string, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).deleteAdminCompanyExpert(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Company Expert by ID
     * @param {string} companyId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public findAdminCompanyExpertById(companyId: string, id: string, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).findAdminCompanyExpertById(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Company Experts
     * @param {string} companyId 
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public findAllAdminCompanyExperts(companyId: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).findAllAdminCompanyExperts(companyId, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Company Expert.
     * @param {string} companyId 
     * @param {string} id 
     * @param {AdminCompanyExpertUpdate} adminCompanyExpertUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminCompanyExpertsApi
     */
    public updateAdminCompanyExpert(companyId: string, id: string, adminCompanyExpertUpdate: AdminCompanyExpertUpdate, options?: RawAxiosRequestConfig) {
        return AdminCompanyExpertsApiFp(this.configuration).updateAdminCompanyExpert(companyId, id, adminCompanyExpertUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminThesisProjectsApi - axios parameter creator
 * @export
 */
export const AdminThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminThesisProject: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminThesisProject', 'id', id)
            const localVarPath = `/api/v2/admin/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Thesis Project Activity by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminThesisProjectActivity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminThesisProjectActivity', 'id', id)
            const localVarPath = `/api/v2/admin/thesis-projects/{id}/activity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Thesis Projects
         * @param {Array<FindAllAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {string} [company] Company ID
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminThesisProjects: async (state?: Array<FindAllAdminThesisProjectsStateEnum>, company?: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admin/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminThesisProjectsApi - functional programming interface
 * @export
 */
export const AdminThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminThesisProject(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminThesisProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminThesisProjectsApi.findAdminThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Thesis Project Activity by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminThesisProjectActivity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThesisProjectActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminThesisProjectActivity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminThesisProjectsApi.findAdminThesisProjectActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Thesis Projects
         * @param {Array<FindAllAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {string} [company] Company ID
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminThesisProjects(state?: Array<FindAllAdminThesisProjectsStateEnum>, company?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminThesisProjects(state, company, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminThesisProjectsApi.findAllAdminThesisProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminThesisProjectsApi - factory interface
 * @export
 */
export const AdminThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminThesisProject(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.findAdminThesisProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Thesis Project Activity by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminThesisProjectActivity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ThesisProjectActivity>> {
            return localVarFp.findAdminThesisProjectActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Thesis Projects
         * @param {Array<FindAllAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {string} [company] Company ID
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminThesisProjects(state?: Array<FindAllAdminThesisProjectsStateEnum>, company?: string, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectPage> {
            return localVarFp.findAllAdminThesisProjects(state, company, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminThesisProjectsApi - object-oriented interface
 * @export
 * @class AdminThesisProjectsApi
 * @extends {BaseAPI}
 */
export class AdminThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Find a Thesis Project by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminThesisProjectsApi
     */
    public findAdminThesisProject(id: string, options?: RawAxiosRequestConfig) {
        return AdminThesisProjectsApiFp(this.configuration).findAdminThesisProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Thesis Project Activity by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminThesisProjectsApi
     */
    public findAdminThesisProjectActivity(id: string, options?: RawAxiosRequestConfig) {
        return AdminThesisProjectsApiFp(this.configuration).findAdminThesisProjectActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Thesis Projects
     * @param {Array<FindAllAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
     * @param {string} [company] Company ID
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminThesisProjectsApi
     */
    public findAllAdminThesisProjects(state?: Array<FindAllAdminThesisProjectsStateEnum>, company?: string, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return AdminThesisProjectsApiFp(this.configuration).findAllAdminThesisProjects(state, company, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllAdminThesisProjectsStateEnum = {
    Applied: 'applied',
    Withdrawn: 'withdrawn',
    Rejected: 'rejected',
    Agreed: 'agreed',
    Canceled: 'canceled',
    Completed: 'completed'
} as const;
export type FindAllAdminThesisProjectsStateEnum = typeof FindAllAdminThesisProjectsStateEnum[keyof typeof FindAllAdminThesisProjectsStateEnum];


/**
 * AdminTopicsApi - axios parameter creator
 * @export
 */
export const AdminTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminTopicById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAdminTopicById', 'id', id)
            const localVarPath = `/api/v2/admin/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Topics
         * @param {string} [search] Search terms.
         * @param {string} [company] Company ID
         * @param {boolean} [published] If the topic is published
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminTopics: async (search?: string, company?: string, published?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admin/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTopicsApi - functional programming interface
 * @export
 */
export const AdminTopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAdminTopicById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAdminTopicById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTopicsApi.findAdminTopicById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Topics
         * @param {string} [search] Search terms.
         * @param {string} [company] Company ID
         * @param {boolean} [published] If the topic is published
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllAdminTopics(search?: string, company?: string, published?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllAdminTopics(search, company, published, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTopicsApi.findAllAdminTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminTopicsApi - factory interface
 * @export
 */
export const AdminTopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTopicsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAdminTopicById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.findAdminTopicById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Topics
         * @param {string} [search] Search terms.
         * @param {string} [company] Company ID
         * @param {boolean} [published] If the topic is published
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminTopics(search?: string, company?: string, published?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<TopicPage> {
            return localVarFp.findAllAdminTopics(search, company, published, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTopicsApi - object-oriented interface
 * @export
 * @class AdminTopicsApi
 * @extends {BaseAPI}
 */
export class AdminTopicsApi extends BaseAPI {
    /**
     * 
     * @summary Find a Topic by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTopicsApi
     */
    public findAdminTopicById(id: string, options?: RawAxiosRequestConfig) {
        return AdminTopicsApiFp(this.configuration).findAdminTopicById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Topics
     * @param {string} [search] Search terms.
     * @param {string} [company] Company ID
     * @param {boolean} [published] If the topic is published
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTopicsApi
     */
    public findAllAdminTopics(search?: string, company?: string, published?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return AdminTopicsApiFp(this.configuration).findAllAdminTopics(search, company, published, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommonThesisProjectsApi - axios parameter creator
 * @export
 */
export const CommonThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {CreateThesisProjectComment} createThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThesisProjectComment: async (thesisProjectId: string, createThesisProjectComment: CreateThesisProjectComment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thesisProjectId' is not null or undefined
            assertParamExists('createThesisProjectComment', 'thesisProjectId', thesisProjectId)
            // verify required parameter 'createThesisProjectComment' is not null or undefined
            assertParamExists('createThesisProjectComment', 'createThesisProjectComment', createThesisProjectComment)
            const localVarPath = `/api/v2/thesis-projects/{thesisProjectId}/comments`
                .replace(`{${"thesisProjectId"}}`, encodeURIComponent(String(thesisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createThesisProjectComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThesisProjectComment: async (thesisProjectId: string, commentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thesisProjectId' is not null or undefined
            assertParamExists('deleteThesisProjectComment', 'thesisProjectId', thesisProjectId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteThesisProjectComment', 'commentId', commentId)
            const localVarPath = `/api/v2/thesis-projects/{thesisProjectId}/comments/{commentId}`
                .replace(`{${"thesisProjectId"}}`, encodeURIComponent(String(thesisProjectId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Thesis Project Comments
         * @param {string} thesisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllThesisProjectComments: async (thesisProjectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thesisProjectId' is not null or undefined
            assertParamExists('findAllThesisProjectComments', 'thesisProjectId', thesisProjectId)
            const localVarPath = `/api/v2/thesis-projects/{thesisProjectId}/comments`
                .replace(`{${"thesisProjectId"}}`, encodeURIComponent(String(thesisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findThesisProjectComment: async (thesisProjectId: string, commentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thesisProjectId' is not null or undefined
            assertParamExists('findThesisProjectComment', 'thesisProjectId', thesisProjectId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('findThesisProjectComment', 'commentId', commentId)
            const localVarPath = `/api/v2/thesis-projects/{thesisProjectId}/comments/{commentId}`
                .replace(`{${"thesisProjectId"}}`, encodeURIComponent(String(thesisProjectId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {UpdateThesisProjectComment} updateThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThesisProjectComment: async (thesisProjectId: string, commentId: string, updateThesisProjectComment: UpdateThesisProjectComment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thesisProjectId' is not null or undefined
            assertParamExists('updateThesisProjectComment', 'thesisProjectId', thesisProjectId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateThesisProjectComment', 'commentId', commentId)
            // verify required parameter 'updateThesisProjectComment' is not null or undefined
            assertParamExists('updateThesisProjectComment', 'updateThesisProjectComment', updateThesisProjectComment)
            const localVarPath = `/api/v2/thesis-projects/{thesisProjectId}/comments/{commentId}`
                .replace(`{${"thesisProjectId"}}`, encodeURIComponent(String(thesisProjectId)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateThesisProjectComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonThesisProjectsApi - functional programming interface
 * @export
 */
export const CommonThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {CreateThesisProjectComment} createThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThesisProjectComment(thesisProjectId: string, createThesisProjectComment: CreateThesisProjectComment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThesisProjectComment(thesisProjectId, createThesisProjectComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonThesisProjectsApi.createThesisProjectComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThesisProjectComment(thesisProjectId, commentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonThesisProjectsApi.deleteThesisProjectComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Thesis Project Comments
         * @param {string} thesisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllThesisProjectComments(thesisProjectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThesisProjectComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllThesisProjectComments(thesisProjectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonThesisProjectsApi.findAllThesisProjectComments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findThesisProjectComment(thesisProjectId, commentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonThesisProjectsApi.findThesisProjectComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {UpdateThesisProjectComment} updateThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateThesisProjectComment(thesisProjectId: string, commentId: string, updateThesisProjectComment: UpdateThesisProjectComment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateThesisProjectComment(thesisProjectId, commentId, updateThesisProjectComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonThesisProjectsApi.updateThesisProjectComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommonThesisProjectsApi - factory interface
 * @export
 */
export const CommonThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {CreateThesisProjectComment} createThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThesisProjectComment(thesisProjectId: string, createThesisProjectComment: CreateThesisProjectComment, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectComment> {
            return localVarFp.createThesisProjectComment(thesisProjectId, createThesisProjectComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteThesisProjectComment(thesisProjectId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Thesis Project Comments
         * @param {string} thesisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllThesisProjectComments(thesisProjectId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ThesisProjectComment>> {
            return localVarFp.findAllThesisProjectComments(thesisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.findThesisProjectComment(thesisProjectId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Thesis Project Comment
         * @param {string} thesisProjectId 
         * @param {string} commentId 
         * @param {UpdateThesisProjectComment} updateThesisProjectComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThesisProjectComment(thesisProjectId: string, commentId: string, updateThesisProjectComment: UpdateThesisProjectComment, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectComment> {
            return localVarFp.updateThesisProjectComment(thesisProjectId, commentId, updateThesisProjectComment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonThesisProjectsApi - object-oriented interface
 * @export
 * @class CommonThesisProjectsApi
 * @extends {BaseAPI}
 */
export class CommonThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Thesis Project Comment
     * @param {string} thesisProjectId 
     * @param {CreateThesisProjectComment} createThesisProjectComment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonThesisProjectsApi
     */
    public createThesisProjectComment(thesisProjectId: string, createThesisProjectComment: CreateThesisProjectComment, options?: RawAxiosRequestConfig) {
        return CommonThesisProjectsApiFp(this.configuration).createThesisProjectComment(thesisProjectId, createThesisProjectComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Thesis Project Comment
     * @param {string} thesisProjectId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonThesisProjectsApi
     */
    public deleteThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig) {
        return CommonThesisProjectsApiFp(this.configuration).deleteThesisProjectComment(thesisProjectId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Thesis Project Comments
     * @param {string} thesisProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonThesisProjectsApi
     */
    public findAllThesisProjectComments(thesisProjectId: string, options?: RawAxiosRequestConfig) {
        return CommonThesisProjectsApiFp(this.configuration).findAllThesisProjectComments(thesisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Thesis Project Comment
     * @param {string} thesisProjectId 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonThesisProjectsApi
     */
    public findThesisProjectComment(thesisProjectId: string, commentId: string, options?: RawAxiosRequestConfig) {
        return CommonThesisProjectsApiFp(this.configuration).findThesisProjectComment(thesisProjectId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Thesis Project Comment
     * @param {string} thesisProjectId 
     * @param {string} commentId 
     * @param {UpdateThesisProjectComment} updateThesisProjectComment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonThesisProjectsApi
     */
    public updateThesisProjectComment(thesisProjectId: string, commentId: string, updateThesisProjectComment: UpdateThesisProjectComment, options?: RawAxiosRequestConfig) {
        return CommonThesisProjectsApiFp(this.configuration).updateThesisProjectComment(thesisProjectId, commentId, updateThesisProjectComment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExpertThesisProjectsApi - axios parameter creator
 * @export
 */
export const ExpertThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all Expert Thesis Projects
         * @param {Array<FindAllExpertAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {boolean} [me] Is Thesis Project assigned to the current user.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExpertAdminThesisProjects: async (state?: Array<FindAllExpertAdminThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/expert/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (me !== undefined) {
                localVarQueryParameter['me'] = me;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExpertThesisProject: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findExpertThesisProject', 'id', id)
            const localVarPath = `/api/v2/expert/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertThesisProjectsApi - functional programming interface
 * @export
 */
export const ExpertThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all Expert Thesis Projects
         * @param {Array<FindAllExpertAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {boolean} [me] Is Thesis Project assigned to the current user.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllExpertAdminThesisProjects(state?: Array<FindAllExpertAdminThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllExpertAdminThesisProjects(state, me, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertThesisProjectsApi.findAllExpertAdminThesisProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExpertThesisProject(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExpertThesisProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertThesisProjectsApi.findExpertThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExpertThesisProjectsApi - factory interface
 * @export
 */
export const ExpertThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all Expert Thesis Projects
         * @param {Array<FindAllExpertAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {boolean} [me] Is Thesis Project assigned to the current user.
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExpertAdminThesisProjects(state?: Array<FindAllExpertAdminThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectPage> {
            return localVarFp.findAllExpertAdminThesisProjects(state, me, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExpertThesisProject(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.findExpertThesisProject(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertThesisProjectsApi - object-oriented interface
 * @export
 * @class ExpertThesisProjectsApi
 * @extends {BaseAPI}
 */
export class ExpertThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Find all Expert Thesis Projects
     * @param {Array<FindAllExpertAdminThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
     * @param {boolean} [me] Is Thesis Project assigned to the current user.
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertThesisProjectsApi
     */
    public findAllExpertAdminThesisProjects(state?: Array<FindAllExpertAdminThesisProjectsStateEnum>, me?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return ExpertThesisProjectsApiFp(this.configuration).findAllExpertAdminThesisProjects(state, me, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Thesis Project by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertThesisProjectsApi
     */
    public findExpertThesisProject(id: string, options?: RawAxiosRequestConfig) {
        return ExpertThesisProjectsApiFp(this.configuration).findExpertThesisProject(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllExpertAdminThesisProjectsStateEnum = {
    Applied: 'applied',
    Withdrawn: 'withdrawn',
    Rejected: 'rejected',
    Agreed: 'agreed',
    Canceled: 'canceled',
    Completed: 'completed'
} as const;
export type FindAllExpertAdminThesisProjectsStateEnum = typeof FindAllExpertAdminThesisProjectsStateEnum[keyof typeof FindAllExpertAdminThesisProjectsStateEnum];


/**
 * ExpertTopicsApi - axios parameter creator
 * @export
 */
export const ExpertTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {CreateTopic} createTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExpertTopic: async (createTopic: CreateTopic, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTopic' is not null or undefined
            assertParamExists('createExpertTopic', 'createTopic', createTopic)
            const localVarPath = `/api/v2/expert/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTopic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertTopic: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExpertTopic', 'id', id)
            const localVarPath = `/api/v2/expert/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all Topics
         * @param {Array<FindAllExpertTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {Array<string>} [industries] Industries ID
         * @param {FindAllExpertTopicsTypeEnum} [type] Filter type, possible values: me, company
         * @param {string} [search] Search terms.
         * @param {string} [university] University ID
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {boolean} [quantitative] Is Topic quantitative
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {boolean} [published] If the topic is published
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExpertTopics: async (levels?: Array<FindAllExpertTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllExpertTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/expert/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (industries) {
                localVarQueryParameter['industries'] = industries;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (university !== undefined) {
                localVarQueryParameter['university'] = university;
            }

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (bookmarked !== undefined) {
                localVarQueryParameter['bookmarked'] = bookmarked;
            }

            if (quantitative !== undefined) {
                localVarQueryParameter['quantitative'] = quantitative;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExpertTopicById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findExpertTopicById', 'id', id)
            const localVarPath = `/api/v2/expert/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {string} id 
         * @param {UpdateTopic} updateTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpertTopic: async (id: string, updateTopic: UpdateTopic, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExpertTopic', 'id', id)
            // verify required parameter 'updateTopic' is not null or undefined
            assertParamExists('updateExpertTopic', 'updateTopic', updateTopic)
            const localVarPath = `/api/v2/expert/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTopic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertTopicsApi - functional programming interface
 * @export
 */
export const ExpertTopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpertTopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {CreateTopic} createTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExpertTopic(createTopic: CreateTopic, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExpertTopic(createTopic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertTopicsApi.createExpertTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExpertTopic(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExpertTopic(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertTopicsApi.deleteExpertTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all Topics
         * @param {Array<FindAllExpertTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {Array<string>} [industries] Industries ID
         * @param {FindAllExpertTopicsTypeEnum} [type] Filter type, possible values: me, company
         * @param {string} [search] Search terms.
         * @param {string} [university] University ID
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {boolean} [quantitative] Is Topic quantitative
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {boolean} [published] If the topic is published
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllExpertTopics(levels?: Array<FindAllExpertTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllExpertTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllExpertTopics(levels, industries, type, search, university, faculty, bookmarked, quantitative, page, size, published, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertTopicsApi.findAllExpertTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExpertTopicById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExpertTopicById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertTopicsApi.findExpertTopicById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {string} id 
         * @param {UpdateTopic} updateTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExpertTopic(id: string, updateTopic: UpdateTopic, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExpertTopic(id, updateTopic, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpertTopicsApi.updateExpertTopic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExpertTopicsApi - factory interface
 * @export
 */
export const ExpertTopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpertTopicsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Topic
         * @param {CreateTopic} createTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExpertTopic(createTopic: CreateTopic, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.createExpertTopic(createTopic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExpertTopic(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteExpertTopic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all Topics
         * @param {Array<FindAllExpertTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {Array<string>} [industries] Industries ID
         * @param {FindAllExpertTopicsTypeEnum} [type] Filter type, possible values: me, company
         * @param {string} [search] Search terms.
         * @param {string} [university] University ID
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {boolean} [quantitative] Is Topic quantitative
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {boolean} [published] If the topic is published
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExpertTopics(levels?: Array<FindAllExpertTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllExpertTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<TopicPage> {
            return localVarFp.findAllExpertTopics(levels, industries, type, search, university, faculty, bookmarked, quantitative, page, size, published, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Topic by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExpertTopicById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.findExpertTopicById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Topic by ID
         * @param {string} id 
         * @param {UpdateTopic} updateTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExpertTopic(id: string, updateTopic: UpdateTopic, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.updateExpertTopic(id, updateTopic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpertTopicsApi - object-oriented interface
 * @export
 * @class ExpertTopicsApi
 * @extends {BaseAPI}
 */
export class ExpertTopicsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Topic
     * @param {CreateTopic} createTopic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertTopicsApi
     */
    public createExpertTopic(createTopic: CreateTopic, options?: RawAxiosRequestConfig) {
        return ExpertTopicsApiFp(this.configuration).createExpertTopic(createTopic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Topic by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertTopicsApi
     */
    public deleteExpertTopic(id: string, options?: RawAxiosRequestConfig) {
        return ExpertTopicsApiFp(this.configuration).deleteExpertTopic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all Topics
     * @param {Array<FindAllExpertTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
     * @param {Array<string>} [industries] Industries ID
     * @param {FindAllExpertTopicsTypeEnum} [type] Filter type, possible values: me, company
     * @param {string} [search] Search terms.
     * @param {string} [university] University ID
     * @param {string} [faculty] Faculty ID
     * @param {boolean} [bookmarked] Is Topic Bookmarked
     * @param {boolean} [quantitative] Is Topic quantitative
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {boolean} [published] If the topic is published
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertTopicsApi
     */
    public findAllExpertTopics(levels?: Array<FindAllExpertTopicsLevelsEnum>, industries?: Array<string>, type?: FindAllExpertTopicsTypeEnum, search?: string, university?: string, faculty?: string, bookmarked?: boolean, quantitative?: boolean, page?: number, size?: number, published?: boolean, options?: RawAxiosRequestConfig) {
        return ExpertTopicsApiFp(this.configuration).findAllExpertTopics(levels, industries, type, search, university, faculty, bookmarked, quantitative, page, size, published, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Topic by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertTopicsApi
     */
    public findExpertTopicById(id: string, options?: RawAxiosRequestConfig) {
        return ExpertTopicsApiFp(this.configuration).findExpertTopicById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Topic by ID
     * @param {string} id 
     * @param {UpdateTopic} updateTopic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertTopicsApi
     */
    public updateExpertTopic(id: string, updateTopic: UpdateTopic, options?: RawAxiosRequestConfig) {
        return ExpertTopicsApiFp(this.configuration).updateExpertTopic(id, updateTopic, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllExpertTopicsLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;
export type FindAllExpertTopicsLevelsEnum = typeof FindAllExpertTopicsLevelsEnum[keyof typeof FindAllExpertTopicsLevelsEnum];
/**
 * @export
 */
export const FindAllExpertTopicsTypeEnum = {
    Me: 'me',
    Company: 'company'
} as const;
export type FindAllExpertTopicsTypeEnum = typeof FindAllExpertTopicsTypeEnum[keyof typeof FindAllExpertTopicsTypeEnum];


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.healthControllerPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerPing(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.healthControllerPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public healthControllerPing(options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).healthControllerPing(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StudentThesisProjectsApi - axios parameter creator
 * @export
 */
export const StudentThesisProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentThesisProjects: async (state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/student/thesis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentThesisProject: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStudentThesisProject', 'id', id)
            const localVarPath = `/api/v2/student/thesis-projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentThesisProjectsApi - functional programming interface
 * @export
 */
export const StudentThesisProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentThesisProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllStudentThesisProjects(state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllStudentThesisProjects(state, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.findAllStudentThesisProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStudentThesisProject(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThesisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStudentThesisProject(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentThesisProjectsApi.findStudentThesisProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StudentThesisProjectsApi - factory interface
 * @export
 */
export const StudentThesisProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentThesisProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all Student Thesis Projects
         * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentThesisProjects(state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProjectPage> {
            return localVarFp.findAllStudentThesisProjects(state, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Thesis Project by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentThesisProject(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ThesisProject> {
            return localVarFp.findStudentThesisProject(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentThesisProjectsApi - object-oriented interface
 * @export
 * @class StudentThesisProjectsApi
 * @extends {BaseAPI}
 */
export class StudentThesisProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Find all Student Thesis Projects
     * @param {Array<FindAllStudentThesisProjectsStateEnum>} [state] The state of the Thesis Project. Possible values: applied, agreed
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public findAllStudentThesisProjects(state?: Array<FindAllStudentThesisProjectsStateEnum>, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).findAllStudentThesisProjects(state, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Thesis Project by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentThesisProjectsApi
     */
    public findStudentThesisProject(id: string, options?: RawAxiosRequestConfig) {
        return StudentThesisProjectsApiFp(this.configuration).findStudentThesisProject(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllStudentThesisProjectsStateEnum = {
    Applied: 'applied',
    Withdrawn: 'withdrawn',
    Rejected: 'rejected',
    Agreed: 'agreed',
    Canceled: 'canceled',
    Completed: 'completed'
} as const;
export type FindAllStudentThesisProjectsStateEnum = typeof FindAllStudentThesisProjectsStateEnum[keyof typeof FindAllStudentThesisProjectsStateEnum];


/**
 * StudentTopicsApi - axios parameter creator
 * @export
 */
export const StudentTopicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {Array<string>} [industries] Industries ID
         * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {string} [search] Search terms.
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentTopics: async (industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/student/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (industries) {
                localVarQueryParameter['industries'] = industries;
            }

            if (levels) {
                localVarQueryParameter['levels'] = levels;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (faculty !== undefined) {
                localVarQueryParameter['faculty'] = faculty;
            }

            if (bookmarked !== undefined) {
                localVarQueryParameter['bookmarked'] = bookmarked;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentTopicById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStudentTopicById', 'id', id)
            const localVarPath = `/api/v2/student/topics/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentTopicsApi - functional programming interface
 * @export
 */
export const StudentTopicsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentTopicsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {Array<string>} [industries] Industries ID
         * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {string} [search] Search terms.
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllStudentTopics(industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopicPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllStudentTopics(industries, levels, search, faculty, bookmarked, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentTopicsApi.findAllStudentTopics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStudentTopicById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStudentTopicById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentTopicsApi.findStudentTopicById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StudentTopicsApi - factory interface
 * @export
 */
export const StudentTopicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentTopicsApiFp(configuration)
    return {
        /**
         * 
         * @summary Find all Topics
         * @param {Array<string>} [industries] Industries ID
         * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
         * @param {string} [search] Search terms.
         * @param {string} [faculty] Faculty ID
         * @param {boolean} [bookmarked] Is Topic Bookmarked
         * @param {number} [page] Page number, default is 1
         * @param {number} [size] Page size, default is 50
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllStudentTopics(industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): AxiosPromise<TopicPage> {
            return localVarFp.findAllStudentTopics(industries, levels, search, faculty, bookmarked, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a Topic by ID.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStudentTopicById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.findStudentTopicById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentTopicsApi - object-oriented interface
 * @export
 * @class StudentTopicsApi
 * @extends {BaseAPI}
 */
export class StudentTopicsApi extends BaseAPI {
    /**
     * 
     * @summary Find all Topics
     * @param {Array<string>} [industries] Industries ID
     * @param {Array<FindAllStudentTopicsLevelsEnum>} [levels] The level of the topic. Possible values: bsc, msc, phd
     * @param {string} [search] Search terms.
     * @param {string} [faculty] Faculty ID
     * @param {boolean} [bookmarked] Is Topic Bookmarked
     * @param {number} [page] Page number, default is 1
     * @param {number} [size] Page size, default is 50
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentTopicsApi
     */
    public findAllStudentTopics(industries?: Array<string>, levels?: Array<FindAllStudentTopicsLevelsEnum>, search?: string, faculty?: string, bookmarked?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig) {
        return StudentTopicsApiFp(this.configuration).findAllStudentTopics(industries, levels, search, faculty, bookmarked, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a Topic by ID.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentTopicsApi
     */
    public findStudentTopicById(id: string, options?: RawAxiosRequestConfig) {
        return StudentTopicsApiFp(this.configuration).findStudentTopicById(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindAllStudentTopicsLevelsEnum = {
    Bsc: 'bsc',
    Msc: 'msc',
    Phd: 'phd'
} as const;
export type FindAllStudentTopicsLevelsEnum = typeof FindAllStudentTopicsLevelsEnum[keyof typeof FindAllStudentTopicsLevelsEnum];


/**
 * SupervisorApi - axios parameter creator
 * @export
 */
export const SupervisorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a Supervisor by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisor: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSupervisor', 'id', id)
            const localVarPath = `/api/v2/supervisor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite a Supervisor
         * @param {InviteSupervisor} inviteSupervisor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSupervisor: async (inviteSupervisor: InviteSupervisor, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteSupervisor' is not null or undefined
            assertParamExists('inviteSupervisor', 'inviteSupervisor', inviteSupervisor)
            const localVarPath = `/api/v2/supervisor/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteSupervisor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupervisorApi - functional programming interface
 * @export
 */
export const SupervisorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupervisorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a Supervisor by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupervisor(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupervisor(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorApi.deleteSupervisor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Invite a Supervisor
         * @param {InviteSupervisor} inviteSupervisor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteSupervisor(inviteSupervisor: InviteSupervisor, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Supervisor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteSupervisor(inviteSupervisor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorApi.inviteSupervisor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupervisorApi - factory interface
 * @export
 */
export const SupervisorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupervisorApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a Supervisor by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisor(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSupervisor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite a Supervisor
         * @param {InviteSupervisor} inviteSupervisor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteSupervisor(inviteSupervisor: InviteSupervisor, options?: RawAxiosRequestConfig): AxiosPromise<Supervisor> {
            return localVarFp.inviteSupervisor(inviteSupervisor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupervisorApi - object-oriented interface
 * @export
 * @class SupervisorApi
 * @extends {BaseAPI}
 */
export class SupervisorApi extends BaseAPI {
    /**
     * 
     * @summary Delete a Supervisor by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorApi
     */
    public deleteSupervisor(id: string, options?: RawAxiosRequestConfig) {
        return SupervisorApiFp(this.configuration).deleteSupervisor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite a Supervisor
     * @param {InviteSupervisor} inviteSupervisor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorApi
     */
    public inviteSupervisor(inviteSupervisor: InviteSupervisor, options?: RawAxiosRequestConfig) {
        return SupervisorApiFp(this.configuration).inviteSupervisor(inviteSupervisor, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupervisorTopicInterestsApi - axios parameter creator
 * @export
 */
export const SupervisorTopicInterestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Supervisor Topic Interest
         * @param {CreateSupervisorTopicInterest} createSupervisorTopicInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupervisorTopicInterest: async (createSupervisorTopicInterest: CreateSupervisorTopicInterest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSupervisorTopicInterest' is not null or undefined
            assertParamExists('createSupervisorTopicInterest', 'createSupervisorTopicInterest', createSupervisorTopicInterest)
            const localVarPath = `/api/v2/supervisor/topics/interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupervisorTopicInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Supervisor Topic Interest by Topic ID
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisorTopicInterest: async (topicId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('deleteSupervisorTopicInterest', 'topicId', topicId)
            const localVarPath = `/api/v2/supervisor/topics/interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (topicId !== undefined) {
                localVarQueryParameter['topic_id'] = topicId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupervisorTopicInterestsApi - functional programming interface
 * @export
 */
export const SupervisorTopicInterestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupervisorTopicInterestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Supervisor Topic Interest
         * @param {CreateSupervisorTopicInterest} createSupervisorTopicInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupervisorTopicInterest(createSupervisorTopicInterest: CreateSupervisorTopicInterest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Topic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupervisorTopicInterest(createSupervisorTopicInterest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicInterestsApi.createSupervisorTopicInterest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a Supervisor Topic Interest by Topic ID
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupervisorTopicInterest(topicId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupervisorTopicInterest(topicId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupervisorTopicInterestsApi.deleteSupervisorTopicInterest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupervisorTopicInterestsApi - factory interface
 * @export
 */
export const SupervisorTopicInterestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupervisorTopicInterestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Supervisor Topic Interest
         * @param {CreateSupervisorTopicInterest} createSupervisorTopicInterest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupervisorTopicInterest(createSupervisorTopicInterest: CreateSupervisorTopicInterest, options?: RawAxiosRequestConfig): AxiosPromise<Topic> {
            return localVarFp.createSupervisorTopicInterest(createSupervisorTopicInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Supervisor Topic Interest by Topic ID
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupervisorTopicInterest(topicId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSupervisorTopicInterest(topicId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupervisorTopicInterestsApi - object-oriented interface
 * @export
 * @class SupervisorTopicInterestsApi
 * @extends {BaseAPI}
 */
export class SupervisorTopicInterestsApi extends BaseAPI {
    /**
     * 
     * @summary Create a Supervisor Topic Interest
     * @param {CreateSupervisorTopicInterest} createSupervisorTopicInterest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicInterestsApi
     */
    public createSupervisorTopicInterest(createSupervisorTopicInterest: CreateSupervisorTopicInterest, options?: RawAxiosRequestConfig) {
        return SupervisorTopicInterestsApiFp(this.configuration).createSupervisorTopicInterest(createSupervisorTopicInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Supervisor Topic Interest by Topic ID
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupervisorTopicInterestsApi
     */
    public deleteSupervisorTopicInterest(topicId: string, options?: RawAxiosRequestConfig) {
        return SupervisorTopicInterestsApiFp(this.configuration).deleteSupervisorTopicInterest(topicId, options).then((request) => request(this.axios, this.basePath));
    }
}



