import React, {PropsWithChildren} from "react";

// Define the prop types
export interface BreadcrumbListProps {
  className?: string;
}

// Functional component with props
const BreadcrumbList: React.FC<PropsWithChildren<BreadcrumbListProps>> = ({className = '', children}) => {
  return (
    <ol role="list" className={`flex items-center sm:gap-2.5 ${className}`}>
      {children}
    </ol>
  );
};

// Export the component
export default React.memo(BreadcrumbList);
