import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import { setLocale } from "yup";
import { DOMAIN_REGEXP } from "./utils/validators";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

if (!localStorage.getItem("i18nextLng")) {
  localStorage.setItem("i18nextLng", "en");
}

const resources = {
  "en": {
    "translation": {
      "common": {
        "login": "Log in",
        "sign_up": "Sign up",
        "sign_out": "Sign out",
        "students": "Students",
        "companies": "Companies",
        "users": "Users",
        "faculties": "Faculties",
        "dashboard": "Dashboard",
        "courses": "Courses",
        "topics": "Topics",
        "expert": "Expert",
        "experts": "Experts",
        "modules": "Modules",
        "cancel": "Cancel",
        "save": "Save",
        "saved": "Saved",
        "feedback": "Feedback",
        "noItems": "No items",
        "attachments": "Attachments",
        "download": "Download",
        "required": "This field is required",
        "upload": "Upload",
        "delete": "Delete",
        "company": "Company",
        "profile": "Profile",
        "thesisProject": "Thesis Project",
        "thesisProjects": "Thesis Projects",
        "close": "Close",
        "created": "Created on",
        "updated": "Updated on",
        "edit": "Edit",
        "publish": "Publish",
        "view": "View",
        "unpublish": "Unpublish",
        "draft": "Draft",
        "error": {
          "title": "Oops! Something went wrong",
          "description": "We encountered an error while processing your request. Please try again."
        },
        "apply": "Apply",
        "continue": "Continue",
        "noThesisProjects": "No Thesis Projects",
        "myProfile": "My Profile",
        "myTopics": "My Topics",
        "myThesisProjects": "My Thesis Projects",
        "readMore": "Read more",
        "readLess": "Read less",
        "remove": "Remove",
        "to": "to",
        "of": "of",
        "previous": "Previous",
        "next": "Next",
        "total": "Total",
        "filters": "Filters",
        "allCompanies": "All Companies",
        "noTopics": "No Topics",
        "noCompanies": "No Companies",
        "topic": "Topic",
        "all": "All",
        "published": "Published",
        "send": "Send",
        "university": "University",
        "confirm": "Confirm",
        "you": "It's you",
        "role": {
          "owner": "Owner",
          "admin": "Admin"
        },
        "activeProfile": "Active profile"
      },
      "dashboard": {
        "students": {
          "welcome": {
            "title": "Welcome to ThesisNavigator (Beta)",
            "description": "We just started! Please check out our support for your thesis writing and provide feedback.<br />What do you like? What not? What do you miss?"
          },
          "topics": {
            "title": "Thesis Topics",
            "description": "All publicly available Thesis Topics of all Institutes in one place.",
            "button": "Explore all Topics",
            "favorites": {
              "title": "My Favorite Topics",
              "view": "View",
              "viewAll": "View all"
            }
          },
          "courses": {
            "title": "E-Learning Courses",
            "description": "The first videos of our video series on academic writing are available.",
            "button": "Start Learning",
            "favorites": {
              "title": "My Favorite Courses",
              "view": "View",
              "viewAll": "View all"
            }
          },
          "features": {
            "subtitle": "Everything you need"
          }
        },
        "companies": {
          "welcome": {
            "title": "Welcome to ThesisNavigator (Beta)",
            "description": "Get to know the talents and skills of the next generation."
          },
          "profile": {
            "title": "Students want to know",
            "description": "Students are looking for specific real-world challenges to create genuine value with their thesis or to validate their theory through interviews with the appropriate counterpart. For this purpose, they need to know:",
            "item1": {
              "title": "Who are you",
              "description": "What did you study where? What are your responsibilities today?"
            },
            "item2": {
              "title": "What you offer",
              "description": "Are you open for interviews? Do you share specific challenges for students to work on with or without internship?"
            },
            "item3": {
              "title": "What are you looking for",
              "description": "Which topics you are interested in? What degrees from what universities are particularly interesting for you to work with."
            },
            "button": "Complete your profile"
          },
          "activity": {
            "title": "What’s going on",
            "publishedTopics": {
              "title": "Create a new Topic",
              "create": {
                "description": "Get started by creating a new Topic."
              },
              "preview": {
                "single": {
                  "description": "You have <0>{{count}}</0> published Topic."
                },
                "multiple": {
                  "description": "You have <0>{{count}}</0> published Topics."
                }
              }
            },
            "draftTopics": {
              "title": "Edit your draft Topics",
              "preview": {
                "single": {
                  "description": "You have <0>{{count}}</0> draft Topic."
                },
                "multiple": {
                  "description": "You have <0>{{count}}</0> draft Topics."
                }
              }
            },
            "inviteExperts": {
              "admin": {
                "title": "Invite new team Experts",
                "description": "As an admin of this company, you can manage the Company Experts and their permissions."
              },
              "expert": {
                "title": "Preview your team Experts",
                "description": "Preview all your team Experts and start collaborating with them."
              }
            },
            "previewAllTopics": "Preview all Company Topics"
          }
        },
        "supervisors": {
          "welcome": {
            "title": "Welcome to ThesisNavigator (Beta)",
            "description": "We connect you with students and industry experts."
          },
          "profile": {
            "title": "What Students & Industry Experts want to know about you",
            "description": "On ThesisNavigator, students and industry experts are looking to solve real-world challenges together. As a supervisor, you too can find exciting topics and new corporate partners on our platform. To make your search easier, we need to know a few things about you: ",
            "item1": {
              "title": "Who are you",
              "description": "Tell us about your role & research focus."
            },
            "item2": {
              "title": "What you offer",
              "description": "Are you open to supervise a thesis or to give interviews in your field of expertise?"
            },
            "item3": {
              "title": "What are you looking for",
              "description": "Which topics you are interested in?"
            },
            "button": "Complete your profile"
          },
          "activity": {
            "title": "What’s going on",
            "topics": {
              "title": "Preview the Topics from your University",
              "description": "Preview the Topics from your University."
            },
            "inviteSupervisors": {
              "title": "Preview the Supervisors at your University",
              "description": "Preview the Supervisors at your University."
            },
            "previewAllTopics": "Preview the Topics from your University"
          }
        }
      },
      "courses": {
        "noResults": {
          "title": "No Courses found",
          "description": "Sorry, we couldn't find any matching results."
        },
        "onboarding": {
          "title": "The course program has started",
          "item1": "Here are videos on academic writing divided into 5 modules",
          "item2": "The first videos are available online, with more to follow shortly",
          "item3": "If you have any wishes, suggestions, or questions, please contact us through the feedback form :)",
          "button": "Got it"
        }
      },
      "topics": {
        "search": {
          "button": "Search",
          "placeholder": "Search for Topics",
          "filters": "Filters",
          "university": "University",
          "faculty": "Faculty",
          "level": "Level",
          "selectFaculty": "Select Faculty",
          "clearAll": "Clear all",
          "allFaculties": "All Faculties",
          "selectUniversity": "Select University",
          "allUniversities": "All Universities",
          "industries": "Industries",
          "selectIndustries": "Select Industries",
          "noResults": {
            "title": "No Topics found",
            "description": "Sorry, we couldn't find any matching results.",
            "button": "Browse all Topics",
            "secondaryButton": "Contact support"
          },
          "chatgpt": "Summarized and categorized by ChatGTP",
          "publishedBy": "Published by",
          "inactive": "Inactive"
        },
        "onboarding": {
          "expert": {
            "title": "Find your topic",
            "item1": "All available thesis topics, whether from universities or companies, are listed",
            "item2": "Students can only see topics that have been posted by their university or by experts for their university",
            "item3": "For students, an <strong>Apply</strong> button is displayed on topics from companies",
            "item4": "Responsible persons in the company are informed about new applications via email",
            "button": "Got it"
          },
          "student": {
            "title": "Find your topic",
            "item1": "Here is a list of all publicly available Thesis Topics at your university and first Topics from companies",
            "item2": "Use the search and filter function to find your topic",
            "item3": "For topics from companies, you can apply directly - for other topics, follow the institutional link provided",
            "button": "Got it"
          },
          "supervisor": {
            "title": "Find your topic",
            "item1": "All available thesis topics are listed here",
            "item2": "When you show interest in supervising a company topic, this information becomes visible to students and industry experts. Once they connect, they may approach you accordingly.",
            "item3": "You will decide on the actual supervision only when the student applies to you with a research proposal for the supervision of their thesis",
            "button": "Got it"
          }
        },
        "topic": {
          "supervisedBy": "Supervised by",
          "openSource": "Open source",
          "publish": {
            "notification": {
              "title": "The topic was successfully published",
              "description": "Good to know:<ul class=\"list-disc mt-2 ml-3 space-y-2\"><li>The topic is now visible to everyone in the list of all topics.</li><li>The \"Apply\" link is displayed only to students.</li><li>ThesisNavigator will inform you via email as soon as a student applies.</li><li>Students can only apply to ONE topic at a time. They are serious about their application and rely on a prompt response from you.</li></ul>"
            }
          },
          "unpublish": {
            "notification": {
              "title": "The topic was unpublished",
              "description": "The topic was successfully unpublished and is now in draft mode again."
            }
          },
          "create": {
            "notification": {
              "title": "The topic was successfully created",
              "description": "It can now be published."
            }
          },
          "update": {
            "notification": {
              "title": "The topic was successfully updated",
              "description": "Thanks."
            }
          },
          "delete": {
            "notification": {
              "title": "The topic was successfully deleted",
              "description": "Appreciation from Marie Kondo."
            },
            "confirmation": {
              "title": "Delete Topic",
              "description": "Are you sure you want to delete this topic? This topic will be permanently removed from our servers forever. This action cannot be undone."
            }
          },
          "apply": {
            "notEligible": "You can apply to only one topic at a time.",
            "notAvailable": {
              "title": "This Topic is not available at the moment.",
              "action": "Please mark it as favorite and you'll be notified as soon as the application is possible again."
            },
            "confirm": {
              "title": "Confirm application",
              "description": "You can apply to only one topic at a time. Are you sure that this is your topic?"
            }
          },
          "supervisor": {
            "title": "Potential Supervisors",
            "description": "These people are interested in supervising this topic."
          }
        },
        "supervisor": {
          "interested": "Interested",
          "interestedInSupervising": "Interested in supervising",
          "apply": {
            "confirm": {
              "title": "Show Interest",
              "description": "Showing interest in this topic this is visible to students and industry experts and they might contact you accordingly."
            }
          },
          "interestedOne": "{{value}} is interested in supervising this topic.",
          "interestedMany": "{{value1}} and {{value2}} more are interested in supervising this topic."
        }
      },
      "profile": {
        "user": {
          "firstName": "First name",
          "lastName": "Last name",
          "email": "Email address",
          "language": {
            "title": "Language",
            "subtitle": "My preferred language to use on ThesisNavigator."
          }
        },
        "student": {
          "info": {
            "title": "Where am I at?",
            "subtitle": "This information helps to effectively filter the content/videos, and it is being shared with the matching experts.",
            "university": "School / University",
            "noUniversity": "Nothing found",
            "field": "Field of Study",
            "goal": {
              "title": "Current Project",
              "subtitle": "What am I about to write?",
              "research": "Research Paper",
              "bsc": "Bachelor’s Thesis",
              "msc": "Master’s Thesis",
              "phd": "Doctoral Thesis"
            }
          },
          "thesis": {
            "title": "My Thesis",
            "subtitle": "This information helps to find the right experts with real life topcis for you, and it is being shared with them.",
            "research": {
              "title": "Research Area",
              "placeholder": "All areas of interest I’d like to write my thesis in (e.g. Technology, Blockchain, Sports, Food Tech, Climate Change)"
            },
            "skills": {
              "title": "Skills",
              "placeholder": "Skills that I would love to use (e.g. Python, R, Orbis, Bloomberg, specific practical experience)"
            },
            "timeframe": {
              "title": "Timeframe I plan to write my thesis (Begin date, End date)"
            },
            "internship": {
              "title": "Internship",
              "subtitle": "How about pursuing an internship in the context of thesis writing?",
              "yes": "I definitely want an internship",
              "open": "I am open to internship opportunities",
              "no": "I am not considering an internship"
            }
          },
          "requestSupervisorRole": "Are you a Supervisor? Click <0>here</0> to register."
        },
        "expert": {
          "expertise": {
            "title": "My Expertise",
            "subtitle": "This information gets shared with interested students.",
            "university": {
              "title": "Alma Mater",
              "subtitle": "School you got your highest education from."
            },
            "degree": {
              "title": "Field of study"
            },
            "jobTitle": {
              "title": "Job title"
            },
            "job": {
              "title": "Job",
              "subtitle": "Very short what you do."
            },
            "professionalNetworkProfile": {
              "title": "LinkedIn (or XING)",
              "subtitle": "Add your LinkedIn (or XING) profile URL.",
              "placeholder": "https://www.linkedin.com/in/…",
              "error": "Invalid LinkedIn (or XING) profile URL"
            }
          },
          "offer": {
            "title": "My Offer",
            "subtitle": "What would you like to offer the students? This information gets shared with the students and helps them to find you.",
            "interviews": {
              "title": "Expert interviews",
              "subtitle": "Students can approach me for interviews. My preferred topics are...",
              "placeholder": "Artificial Intelligence, Sustainability / ESG, Decarbonization, Agile Organization, Business Resilience, Diversity and Inclusion, Home Office, Inflation, IT Security, Data Protection, Specific Technologies, Business Development, Business Trends, ..."
            },
            "internships": {
              "title": "Internship position",
              "subtitle": "Internship Offer Description.",
              "subtext": "Describe the opportunities within your company or provide links to them",
              "placeholder": "Duration, Requirements, Compensation, Links to further information, ..."
            }
          },
          "interests": {
            "title": "My Visibility",
            "subtitle": "Your profile and topics are visible to all students and professors. Here you can restrict this visibility.",
            "universities": {
              "title": "Limit visibility to students of these schools",
              "subtitle": "Pick the schools your profile and topics shall be visible for. No schools selected means that your profile is presented to students and professors of all universities.",
              "placeholder": "Select schools"
            }
          },
          "universitiesFeedback": "Your school is not in the list? No problem. Send us the name via the <0>Feedback Formular</0> and we will add it to our list."
        },
        "supervisor": {
          "expertise": {
            "title": "My Expertise",
            "subtitle": "This information gets shared with interested students & industry experts.",
            "university": {
              "title": "School / University"
            },
            "job": {
              "title": "Job Title"
            },
            "research": {
              "title": "Research Focus",
              "subtitle": "Briefly tell us what you do."
            },
            "professionalNetworkProfile": {
              "title": "LinkedIn (or XING)",
              "subtitle": "Add your LinkedIn (or XING) profile URL.",
              "placeholder": "https://www.linkedin.com/in/…",
              "error": "Invalid LinkedIn (or XING) profile URL"
            },
            "researchNetworkProfile": {
              "title": "ResearchGate",
              "subtitle": "Add your ResearchGate profile URL.",
              "placeholder": "https://www.researchgate.net/profile/…",
              "error": "Invalid ResearchGate profile URL"
            }
          }
        },
        "notification": {
          "update": {
            "title": "Profile updated",
            "description": "Profile successfully updated"
          }
        },
        "privacy": {
          "title": "Privacy & Preferences",
          "subtitle": "Control your cookie preferences or permanently delete your account and all of your data.",
          "cookieSettings": "Cookie Settings",
          "deleteAccount": "Delete account",
          "delete": {
            "confirmation": {
              "title": "Delete account",
              "description": "Are you sure you want to delete your account? The account will be permanently removed from our servers forever. This action cannot be undone."
            }
          }
        }
      },
      "myTopics": {
        "form": {
          "newTopicTitle": "New Topic",
          "editTopicTitle": "Edit Topic",
          "subtitle": "Step 1: Create the new topic, Step 2: Publish the topic for students.",
          "topic": {
            "title": {
              "title": "Title",
              "placeholder": "What is the title of your Topic?"
            },
            "description": {
              "title": "Description",
              "placeholder": "Expected results? Available datasets? Relevant fields of study? Desired skills/knowledge? Collaboration setup?"
            },
            "requirements": {
              "title": "Mandatory requirements",
              "placeholder": "Required skills? Prerequisite courses? Internships/lab work offered/required? Ideal start time? Expected deliverables? Required methodologies?"
            },
            "internship": {
              "title": "Internship",
              "subtitle": "Do you offer or require an internship in combination with the Thesis Project? ",
              "yes": "Required",
              "open": "Possible",
              "no": "Not possible"
            }
          },
          "more": "More"
        }
      },
      "languages": {
        "en": "English",
        "de": "Deutsch"
      },
      "feedback": {
        "title": "Feedback",
        "description": "Please send us your feedback.",
        "email": "Email",
        "message": "Message",
        "button": "Send",
        "success": {
          "title": "Succesfully sent",
          "description": "Your feedback has been successfully sent out. We appreciate it and get back to you if needed. Many Thanks!"
        }
      },
      "level": {
        "bsc": "Bachelor’s Thesis",
        "msc": "Master’s Thesis",
        "phd": "Doctoral Thesis"
      },
      "company": {
        "form": {
          "new": "New Company",
          "edit": "Edit Company",
          "subtitle": "Step 1: Create the new company, Step 2: Add company owner and users",
          "name": {
            "label": "Name",
            "placeholder": "What is the name of the company?"
          },
          "domains": {
            "label": "Website / Domain",
            "placeholder": "Domain"
          }
        },
        "confirmation": {
          "delete": {
            "title": "Delete Company",
            "description": "Are you sure you want to delete this company? This company will be permanently removed from our servers forever. This action cannot be undone."
          }
        },
        "profile": "Our Profile",
        "experts": "Our Experts",
        "topics": "Our Topics",
        "thesisProjects": "Our Thesis Projects",
        "name": "Name",
        "description": "Description",
        "search": {
          "placeholder": "Search for Companies"
        },
        "address": {
          "title": "Address",
          "country": {
            "title": "Country",
            "placeholder": "Select country"
          },
          "city": "City",
          "street": "Street",
          "number": "Number",
          "postalCode": "Postal code"
        },
        "uid": "UID",
        "industries": "Industries",
        "size": {
          "title": "Size",
          "type": {
            "a": "Self-employed",
            "b": "1-10 employees",
            "c": "11-50 employees",
            "d": "51-200 employees",
            "e": "201-500 employees",
            "f": "501-1000 employees",
            "g": "1001-5000 employees",
            "h": "5001-10,000 employees",
            "i": "10,000+ employees"
          },
          "empty": "No size",
          "select": "Select size"
        },
        "domain": {
          "title": "Domain",
          "button": "Add new domain"
        },
        "logo": "Company picture",
        "notification": {
          "update": {
            "title": "Company updated",
            "description": "Company successfully updated"
          },
          "create": {
            "title": "The company was successfully created",
            "description": "You can now add a company owner"
          }
        },
        "topicsList": {
          "newTopic": "New Topic",
          "create": {
            "title": "No Topics",
            "subtitle": "Get started by creating a new Topic."
          }
        },
        "team": {
          "title": "Add Experts",
          "subtitle": "As an admin of this company, you can manage the Company Users and their permissions (admin or expert).",
          "domainValidation": "Email domain should be one of",
          "placeholder": "Enter an email using one of the domains",
          "button": "Add User",
          "delete": {
            "confirmation": {
              "title": "Delete User",
              "description": "Are you sure you want to delete the User? The User will be permanently removed from our servers forever. This action cannot be undone."
            }
          }
        }
      },
      "user": {
        "form": {
          "firstName": {
            "label": "First name",
            "placeholder": "What is first name?"
          },
          "lastName": {
            "label": "Last name",
            "placeholder": "What is last name?"
          },
          "email": {
            "label": "Email",
            "placeholder": "What is email?"
          }
        },
        "notification": {
          "update": {
            "title": "User updated",
            "description": "User successfully updated"
          },
          "create": {
            "title": "The User was successfully created",
            "description": "User successfully created"
          }
        }
      },
      "supervisor": {
        "invited": "Invited by <0>{{invitedBy}}</0> on {{invited}}"
      },
      "university": {
        "profile": "Profile",
        "supervisors": "Supervisors",
        "team": {
          "title": "Add Colleagues",
          "subtitle": "Add colleagues who also wish to use ThesisNavigator as supervisors. If you or your institute are interested in becoming a university partner, please contact us.",
          "domainValidation": "Email domain should be one of",
          "placeholder": "Enter an email using one of the domains",
          "button": "Add Colleague",
          "delete": {
            "confirmation": {
              "title": "Delete User",
              "description": "Are you sure you want to delete the User? The User will be permanently removed from our servers forever. This action cannot be undone."
            }
          },
          "supervisor": "Supervisor",
          "student": "Student"
        }
      },
      "banner": {
        "title": "Startfeld Diamant",
        "description": "The prize of the St.Galler Kantonalbank (SGKB) for young entrepreneurs.",
        "action": "Vote for us"
      },
      "thesisProjects": {
        "title": {
          "placeholder": "Title"
        },
        "description": {
          "title": "Description",
          "placeholder": "Description"
        },
        "motivation": {
          "title": "My motivation",
          "placeholder": "Share what motivates you and what you would like to contribute. Perhaps you already have specific suggestions for the implementation."
        },
        "cv": {
          "title": "CV",
          "button": {
            "upload": "Upload",
            "change": "Change"
          },
          "placeholder": "No file chosen",
          "info": "Please select a pdf file."
        },
        "notes": {
          "title": "My availability for a first video call?",
          "placeholder": "Add here your scheduling URL (Calendly or similar) or your usual availabilities (e.g. Monday afternoon,...) for a 20 Min Video Call. Goal of the call is to get a first impression and to allign your expectations.",
          "info": "Scheduling URL (<a class='text-indigo-400 font-medium' href='https://calendly.com/' target='blank'>Calendly</a> or similar)"
        },
        "activity": {
          "title": "Activity",
          "applied": "applied to <span class='font-semibold text-gray-900'>{{value}}</span>",
          "updated": {
            "title": "updated the",
            "fields": {
              "title": "title",
              "description": "description",
              "motivation": "motivation",
              "notes": "availability",
              "cv_file": "CV"
            }
          },
          "updatedState": "updated the state to <span class='font-semibold text-gray-900'>{{value}}</span>",
          "commented": "commented",
          "comment": {
            "placeholder": "Add your comment - other participants get notified"
          }
        },
        "update": {
          "notification": {
            "title": "The Thesis Project was successfully updated",
            "description": "Thanks."
          }
        },
        "apply": {
          "notification": {
            "title": "Application successfully sent out",
            "description": "Good to know:<ul class=\"list-disc mt-2 ml-3 space-y-2\"><li>The expert has been informed about your application via email</li><li>A thesis project in the status \"application\" has been created, which both of you can access</li><li>ThesisNavigator will inform you via email whenever there is an update</li><li>In the best case, you will directly receive an invitation to a call through your appointment booking link</li></ul>"
          }
        },
        "state": {
          "applied": "Application",
          "withdrawn": "Application withdrawn",
          "rejected": "Application rejected",
          "agreed": "Project agreed",
          "canceled": "Project canceled",
          "completed": "Project completed"
        },
        "steps": {
          "applied": {
            "item1": "Expert invites to a discussion",
            "item2": "Getting to know each other and adjusting the project",
            "item3": "Student seeks supervisor and submits Research Proposal"
          },
          "agreed": {
            "item1": "Student writes Thesis",
            "item2": "Regular coordination"
          }
        },
        "action": {
          "state": {
            "withdrawn": "Withdraw Application",
            "rejected": "Reject Application",
            "agreed": "Agree on Cooperation",
            "canceled": "Cancel Project",
            "completed": "Complete Project"
          }
        },
        "updateState": {
          "reason": {
            "title": "Reason",
            "placeholder": "Select a reason",
            "withdrawn": {
              "applied_by_mistake": "Applied by mistake",
              "changed_my_mind": "Changed my mind",
              "no_response": "No response",
              "no_match": "No match",
              "other_reason": "Other reason"
            },
            "rejected": {
              "application_incomplete": "Application incomplete",
              "application_insufficient": "Application insufficient",
              "no_match": "No match",
              "other_reason": "Other reason"
            },
            "canceled": {
              "inactivity": "Inactivity",
              "disagreement": "Disagreement",
              "no_supervisor_found": "No supervisor found",
              "other_reason": "Other reason"
            }
          },
          "notes": {
            "title": "Notes",
            "placeholder": "Notes"
          }
        },
        "students": {
          "apply": "Get started by applying to a Topic."
        },
        "project": {
          "title": "Project Description"
        },
        "application": {
          "title": "Application"
        }
      },
      "error": {
        "403": {
          "title": "Forbidden",
          "description": "Sorry, you're not authorized to access this resource.",
          "button": "Go back to Dasboard",
          "secondaryButton": "Contact support"
        }
      },
      "activeProfile": {
        "supervisor": {
          "title": "You are now a Supervisor",
          "description": "You have been invited by <0>{{invitedBy}}</0> to use ThesisNavigator in the role of a Supervisor. If you would like to change your role again, you can do this in your profile settings anytime.",
          "continue": "Continue",
          "studentRole": "Keep Student role"
        }
      },
      "form": {
        "error": {
          "mixed": {
            "required": "This field is required"
          },
          "string": {
            "email": "This field must be a valid email",
            "min": "This field must be at least {{min}} characters",
            "matches": {
              "default": "This field must match the following: \"{{regex}}\"",
              "domain": "This field must match the domain pattern"
            },
            "test": {
              "allowedDomains": "Email domain is not in allowed list: {{domains}}"
            }
          },
          "array": {
            "min": "This field must have at least {{min}} items"
          }
        }
      }
    }
  },
  "de": {
    "translation": {
      "common": {
        "login": "Anmelden",
        "sign_up": "Registrieren",
        "sign_out": "Ausloggen",
        "students": "Studierende",
        "companies": "Firmen",
        "users": "Benutzer",
        "faculties": "Fakultäten",
        "dashboard": "Dashboard",
        "courses": "Kurse",
        "topics": "Themen",
        "expert": "Expert:in",
        "experts": "Expert:innen",
        "modules": "Module",
        "cancel": "Abbrechen",
        "save": "Speichern",
        "saved": "Gespeicherte",
        "feedback": "Feedback",
        "noItems": "Keine Elemente",
        "attachments": "Anhänge",
        "download": "Herunterladen",
        "required": "Dieses Feld ist erforderlich",
        "upload": "Hochladen",
        "delete": "Löschen",
        "company": "Firma",
        "profile": "Profil",
        "thesisProject": "Thesis Projekt",
        "thesisProjects": "Thesis Projekte",
        "close": "Schließen",
        "created": "Erstellt am",
        "updated": "Aktualisiert am",
        "edit": "Bearbeiten",
        "publish": "Veröffentlichen",
        "view": "Anschauen",
        "unpublish": "Offline nehmen",
        "draft": "Entwurf",
        "error": {
          "title": "Hoppla! Etwas ist schiefgegangen",
          "description": "Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        "apply": "Bewerben",
        "continue": "Weiter",
        "noThesisProjects": "Keine Thesis Projekte",
        "myProfile": "Mein Profil",
        "myTopics": "Meine Themen",
        "myThesisProjects": "Meine Thesis Projekte",
        "readMore": "Mehr lesen",
        "readLess": "Weniger lesen",
        "remove": "Entfernen",
        "to": "zu",
        "of": "von",
        "previous": "Zurück",
        "next": "Weiter",
        "total": "Gesamt",
        "filters": "Filter",
        "allCompanies": "Alle Firmen",
        "noTopics": "Keine Themen",
        "noCompanies": "Keine Firmen",
        "topic": "Thema",
        "all": "Alle",
        "published": "Veröffentlicht",
        "send": "Senden",
        "university": "Universität",
        "confirm": "Alles klar",
        "you": "Das bist du",
        "role": {
          "owner": "Besitzer",
          "admin": "Admin"
        },
        "activeProfile": "Aktives Profil"
      },
      "dashboard": {
        "students": {
          "welcome": {
            "title": "Willkommen bei ThesisNavigator (Beta)",
            "description": "Wir sind seit Oktober 2023 live! Bitte schau dir unsere Unterstützung für das Verfassen deiner Abschlussarbeit an und gib uns Feedback.<br />Was gefällt dir? Was nicht? Was fehlt dir?"
          },
          "topics": {
            "title": "Thesis Themen",
            "description": "Alle öffentlich ausgeschriebenen Thesis Themen aller Institute an einem Ort.",
            "button": "Geh zu Themen",
            "favorites": {
              "title": "Meine favorisierten Themen",
              "view": "Anzeigen",
              "viewAll": "Alle anzeigen"
            }
          },
          "courses": {
            "title": "E-Learning Kurse",
            "description": "Die ersten Videos unserer Videoserie zum wissenschaftlichen Schreiben sind verfügbar.",
            "button": "Geh zu Kursen",
            "favorites": {
              "title": "Meine favorisierten Kurse",
              "view": "Anzeigen",
              "viewAll": "Alle anzeigen"
            }
          },
          "features": {
            "subtitle": "Alles, was du brauchst"
          }
        },
        "companies": {
          "welcome": {
            "title": "Willkommen bei ThesisNavigator (Beta)",
            "description": "Talente und Skills der nächsten Generation finden."
          },
          "profile": {
            "title": "Was Studierende wissen müssen",
            "description": "Um mit den richtigen Studierenden in Kontakt zu kommen, musst du drei Informationen kommunizieren:",
            "item1": {
              "title": "Wer du bist",
              "description": "Was hast du wo studiert? Was verantwortest du heute?"
            },
            "item2": {
              "title": "Was du anbietest",
              "description": "Bietest du Themen für Abschlussarbeiten, Praktikas oder Experteninterviews?"
            },
            "item3": {
              "title": "Wonach du suchst",
              "description": "Welche Themen interessieren dich? Welche Studierenden von welchen Unis sind für dich spannend?"
            },
            "button": "Vervollständige dein Profil"
          },
          "activity": {
            "title": "Was auf der Platform läuft",
            "publishedTopics": {
              "title": "Erfasse ein Thema",
              "create": {
                "description": "Erfasse ein erstes Thema."
              },
              "preview": {
                "single": {
                  "description": "Du hast <0>{{count}}</0> publiziertes Thema."
                },
                "multiple": {
                  "description": "Du hast <0>{{count}}</0> publizierte Themen."
                }
              }
            },
            "draftTopics": {
              "title": "Bearbeite deine unpublizierten Themen.",
              "preview": {
                "single": {
                  "description": "Du hast <0>{{count}}</0> unpubliziertes Thema."
                },
                "multiple": {
                  "description": "Du hast <0>{{count}}</0> unpublizierte Themen."
                }
              }
            },
            "inviteExperts": {
              "admin": {
                "title": "Lade weitere Personen ein",
                "description": "Als Administrator:in deines Unternehmens kannst du Profilie und Berechtigungen weiterer Personen verwalten."
              },
              "expert": {
                "title": "Schau dir die Profile deiner Kolleg:innen an",
                "description": "Schau dir an wer sich sonst als Experte / Expertin in deinem Unternehmen engagiert."
              }
            },
            "previewAllTopics": "Schau dir alle Themen deines Unternehmens an"
          }
        },
        "supervisors": {
          "welcome": {
            "title": "Willkommen bei ThesisNavigator (Beta)",
            "description": "Wir verknüpfen Dich mit Studierenden und Expert:innen aus der Praxis"
          },
          "profile": {
            "title": "Was Studierende & Praxispartner über dich wissen wollen",
            "description": "Auf ThesisNavigator finden sich Studierende und Expert:innen aus der Praxis, um durch Abschlussarbeiten gemeinsam echten Mehrwert zu erzeugen. Als Betreuer:in findest auch du auf unserer Plattform spannendende Themen und neue Unternehmenspartner. Um dir die Suche zu erleichtern, müssten wir ein paar Dinge über dich wissen:",
            "item1": {
              "title": "Wer du bist",
              "description": "Was ist deine aktuelle Rolle? Was ist dein Forschungs-Fokus?"
            },
            "item2": {
              "title": "Was du anbietest",
              "description": "Bist du bereit, eine Abschlussarbeit zu betreuen oder Interviews in deinem Fachgebiet zu geben?"
            },
            "item3": {
              "title": "Wonach du suchst",
              "description": "Welche Themen interessieren dich? Welche Studierenden und Unternehmen sind für dich spannend?"
            },
            "button": "Vervollständige dein Profil"
          },
          "activity": {
            "title": "Was auf der Plattform läuft",
            "topics": {
              "title": "Prüfe die Themen deiner Universität",
              "description": "Prüfe die Themen deiner Universität."
            },
            "inviteSupervisors": {
              "title": "Prüfe die Betreuer:innen deiner Universität",
              "description": "Prüfe die Betreuer:innen deiner Universität."
            },
            "previewAllTopics": "Prüfe die Themen deiner Universität"
          }
        }
      },
      "courses": {
        "noResults": {
          "title": "Keine Kurse gefunden",
          "description": "Leider wurden keine passenden Resultate gefunden."
        },
        "onboarding": {
          "title": "Das Kursprogramm ist gestartet",
          "item1": "Hier sind Videos zum wissenschaftlichen Schreiben in 5 Modulen",
          "item2": "Die ersten Videos sind online, weitere folgen in Kürze",
          "item3": "Bei Wünschen, Anregungen oder Fragen, kontaktiere uns bitte über das Feedback Formular :)",
          "button": "Alles klar"
        }
      },
      "topics": {
        "search": {
          "button": "Suchen",
          "placeholder": "Thema suchen",
          "filters": "Filter",
          "university": "Universität",
          "faculty": "Fakultät",
          "level": "Stufe",
          "selectFaculty": "Fakultät auswählen",
          "clearAll": "Alle Filter löschen",
          "allFaculties": "Alle Fakultäten",
          "selectUniversity": "Universität auswählen",
          "allUniversities": "Alle Universitäten",
          "industries": "Branchen",
          "selectIndustries": "Branchen auswählen",
          "noResults": {
            "title": "Keine Themen gefunden",
            "description": "Leider wurden keine passenden Resultate gefunden.",
            "button": "Suche zurücksetzen",
            "secondaryButton": "Support kontaktieren"
          },
          "chatgpt": "Zusammengefasst und kategorisiert mit ChatGPT",
          "publishedBy": "Herausgegeben von",
          "inactive": "Inaktiv"
        },
        "onboarding": {
          "expert": {
            "title": "Hier finden Studierende ihr Thema",
            "item1": "Es sind alle verfügbaren Thesis Topics aufgelistet, ob von Universitäten oder von Unternehmen",
            "item2": "Studierende sehen nur Themen, welche von ihrer Uni oder von Expert:innen für ihre Uni ausgeschrieben wurden",
            "item3": "Für Studierende wird auf Themen von Unternehmen ein <strong>Bewerben</strong> Button angezeigt",
            "item4": "Über neue Bewerbungen werden die verantwortlichen Personen im Unternehmen per E-Mail informiert",
            "button": "Alles klar"
          },
          "student": {
            "title": "Finde dein Thema",
            "item1": "Hier findest du alle öffentlich verfügbaren Thesis Topics deiner Universität sowie erste Themen von Unternehmen",
            "item2": "Nutze die Such- und Filterfunktionen um dein Thema zu finden",
            "item3": "Bei Themen von Unternehmen kannst du dich direkt bewerben, bei den anderen geht der Weg über den Instituts-Link",
            "button": "Alles klar"
          },
          "supervisor": {
            "title": "Hier findest du deine Themen",
            "item1": "Hier sind alle verfügbaren Thesis Topics aufgelistet",
            "item2": "Wenn du dich für die Betreuung eines Themas aus der Praxis interessierst, dann können Studierende und Experten dies sehen sobald sie sich gefunden haben und entsprechend mit dir Kontakt aufnehmen.",
            "item3": "Über die effektive Betreuung entscheidest du erst, wenn sich der/die Studierende bei dir für die Betreuung seiner Thesis mit einem Research Proposal bewirbt",
            "button": "Alles klar"
          }
        },
        "topic": {
          "supervisedBy": "Betreut durch",
          "openSource": "Quelle öffnen",
          "publish": {
            "notification": {
              "title": "Das Thema wurde erfolgreich veröffentlicht",
              "description": "Gut zu wissen:<ul class=\"list-disc mt-2 ml-3 space-y-2\"><li>Das Thema ist nun für alle sichtbar in der Liste aller Themen</li><li>Der \"Bewerben\" Link wird nur für Studierende angezeigt.</li><li>ThesisNavigator informiert dich per E-Mail, sobald sich ein Student oder eine Studentin bewirbt.</li><li>Studierende können sich nur auf EIN Thema gleichzeitig bewerben. Sie meinen es also ernst mit ihrer Bewerbung und sind auf eine zügige Reaktion von dir angewiesen.</li></ul>"
            }
          },
          "unpublish": {
            "notification": {
              "title": "Das Thema wurde erfolgreich offline genommen",
              "description": "Es ist nun wieder im Entwurfsmodus."
            }
          },
          "create": {
            "notification": {
              "title": "Das Thema wurde erfolgreich erstellt",
              "description": "Es kann nun bearbeitet und veröffentlicht werden."
            }
          },
          "update": {
            "notification": {
              "title": "Das Thema wurde erfolgreich aktualisiert",
              "description": "Bravo."
            }
          },
          "delete": {
            "notification": {
              "title": "Das Thema wurde erfolgreich gelöscht",
              "description": "Marie Kondo dankt."
            },
            "confirmation": {
              "title": "Thema löschen",
              "description": "Bist du ganz sicher, dass dieses Thema gelöscht werden soll? Es wird unwiederbringlich von unseren Servern gelöscht. Diese Aktion kann daher nicht rückgängig gemacht werden."
            }
          },
          "apply": {
            "notEligible": "Du kannst dich nur auf ein Thema auf einmal bewerben.",
            "notAvailable": {
              "title": "Dieses Thema ist aktuell nicht frei für neue Bewerbungen.",
              "action": "Markiere das Thema als Favorit und wir wir informieren dich, sobald es für Bewerbungen freigegeben wurde."
            },
            "confirm": {
              "title": "Bewerbung bestätigen",
              "description": "Du kannst dich nur auf EIN Thema auf einmal bewerben. Sicher, dass dies dein Thema sein soll?"
            }
          },
          "supervisor": {
            "title": "Mögliche Betreuer:innen",
            "description": "Diese Personen zeigen Interesse an der Betreuung dieses Themas."
          }
        },
        "supervisor": {
          "interested": "Interessiert",
          "interestedInSupervising": "Interessiert zu betreuen",
          "apply": {
            "confirm": {
              "title": "Interesse zeigen",
              "description": "Wenn du dich für ein Thema interessierst, dann können Studierende und Experten dies sehen und entsprechend mit dir Kontakt aufnehmen."
            }
          },
          "interestedOne": "{{value}} würde dieses Thema betreuen.",
          "interestedMany": "{{value1}} und {{value2}} weitere würden dieses Thema betreuen."
        }
      },
      "profile": {
        "user": {
          "firstName": "Vorname",
          "lastName": "Nachname",
          "email": "E-Mail",
          "language": {
            "title": "Sprache",
            "subtitle": "Meine bevorzugte Benutzersprache."
          }
        },
        "student": {
          "info": {
            "title": "Wo stehe ich?",
            "subtitle": "Diese Informationen helfen dabei, die Kurse für dich zu filtern und sie werden mit passenden Expert:innen geteilt.",
            "university": "Schule / Universität",
            "noUniversity": "Nichts gefunden",
            "field": "Studiengang",
            "goal": {
              "title": "Aktuelles Projekt",
              "subtitle": "Welche Thesis steht an?",
              "research": "Wissenschaftliche Arbeit",
              "bsc": "Bachelorarbeit",
              "msc": "Masterarbeit",
              "phd": "Dissertation"
            }
          },
          "thesis": {
            "title": "Meine Thesis",
            "subtitle": "Diese Informationen helfen dabei, passende Expert:innen Themen aus der Praxis für dich zu finden, und werden mit ihnen geteilt.",
            "research": {
              "title": "Themengebiete",
              "placeholder": "Alle Interessengebiete zu denen du dir vorstellen könntest eine Arbeit zu schreiben (z.B. Technologie, Blockchain, Sport, Food Tech, Klimawandel)"
            },
            "skills": {
              "title": "Kompetenzen",
              "placeholder": "Kompetenzen, die du im Rahmen der Thesis einsetzen möchtest (z.B. Python, R, Orbis, Bloomberg, spezifische praktische Erfahrungen,...)"
            },
            "timeframe": {
              "title": "Zeitfenster in welchem du planst deine Arbeit zu schreiben (Beginn, Ende)"
            },
            "internship": {
              "title": "Praktikum",
              "subtitle": "Wie wäre es mit einem Praktikum im Rahmen der Thesis?",
              "yes": "Ein Praktikum wäre für mich Pflicht",
              "open": "Ich wäre offen für ein Praktikum",
              "no": "Ein Praktikum kommt für mich eher nicht in Frage"
            }
          },
          "requestSupervisorRole": "Du betreust Abschlussarbeiten? Registriere dich <0>hier</0> als Betreuer:in."
        },
        "expert": {
          "expertise": {
            "title": "Meine Expertise",
            "subtitle": "Diese Informationen werden interessierten Studierenden angezeigt",
            "university": {
              "title": "Alma Mater",
              "subtitle": "Wo hast du deinen höchsten Abschluss gemacht?"
            },
            "degree": {
              "title": "Studiengang"
            },
            "jobTitle": {
              "title": "Stellentitel"
            },
            "job": {
              "title": "Tätigkeit",
              "subtitle": "Deine wichtigsten Tätigkeiten in Kürze."
            },
            "professionalNetworkProfile": {
              "title": "LinkedIn (oder XING)",
              "subtitle": "Füge deinen LinkedIn (oder XING) Profil URL ein.",
              "placeholder": "https://www.linkedin.com/in/…",
              "error": "Ungültige LinkedIn (oder XING) Profil URL"
            }
          },
          "offer": {
            "title": "Mein Angebot",
            "subtitle": "Was möchtest du Studierenden anbieten? Diese Information wird für Studierende angezeigt und hilft ihnen, dich zu finden.",
            "interviews": {
              "title": "Expert:innen interviews",
              "subtitle": "Studierende können mich für Interviews anfragen. Mit Vorliebe zu Themen wie...",
              "placeholder": "Künstliche Intelligenz, Nachhaltigkeit / ESG, Dekarbonisierung, Agile Organisation, Resilienz, Diversität und Inklusion, Home-Office, Inflation, IT Sicherheit, Datenschutz, spezifische Technologien, Marktentwicklungen, Trends, ..."
            },
            "internships": {
              "title": "Praktikantenstellen",
              "subtitle": "Beschreibung des Praktikumsangebots.",
              "subtext": "Beschreibe oder verlinke die Möglichkeiten in deinem Unternehmen",
              "placeholder": "Dauer, Voraussetzungen, Vergütung, Links auf weitere Informationen, ..."
            }
          },
          "interests": {
            "title": "Meine Sichtbarkeit",
            "subtitle": "Dein Profil und deine Themen werden grundsätzlich allen Studierenden und Dozierenden angezeigt. Diese Sichtbarkeit kannst du hier einschränken.",
            "universities": {
              "title": "Einschränkung auf Studierende dieser Schulen",
              "subtitle": "Wähle die Schulen aus, an welchen dein Profil und deine Themen sichtbar sein sollen. Ohne die Auswahl konkreter Schulen wird dein Profil allen Studierenden und Dozierenden angezeigt.",
              "placeholder": "Schulen auswählen"
            }
          },
          "universitiesFeedback": "Eine Schule fehlt in der Liste? Kein Problem. Schick sie uns über das <0>Feedback Formular</0> und wir ergänzen sie in unserer Liste."
        },
        "supervisor": {
          "expertise": {
            "title": "Meine Expertise",
            "subtitle": "Diese Informationen werden interessierten Studierenden und Experten aus der Praxis angezeigt.",
            "university": {
              "title": "Schule / Universität"
            },
            "job": {
              "title": "Stellentitel"
            },
            "research": {
              "title": "Forschungs-Fokus",
              "subtitle": "Deine wichtigsten Tätigkeiten in Kürze."
            },
            "professionalNetworkProfile": {
              "title": "LinkedIn (oder XING)",
              "subtitle": "Füge deinen LinkedIn (oder XING) Profil URL ein.",
              "placeholder": "https://www.linkedin.com/in/…",
              "error": "Ungültige LinkedIn (oder XING) Profil URL"
            },
            "researchNetworkProfile": {
              "title": "ResearchGate",
              "subtitle": "Füge deinen ResearchGate Profil URL ein",
              "placeholder": "https://www.researchgate.net/profile/…",
              "error": "Ungültige ResearchGate Profil URL"
            }
          }
        },
        "notification": {
          "update": {
            "title": "Profil aktualisiert",
            "description": "Profil erfolgreich aktualisiert"
          }
        },
        "privacy": {
          "title": "Privatsphäre & Präferenzen",
          "subtitle": "Verwalten Sie Ihre Cookie-Präferenzen oder löschen Sie Ihr Konto und alle zugehörigen Daten endgültig.",
          "cookieSettings": "Cookie Settings",
          "deleteAccount": "Konto löschen",
          "delete": {
            "confirmation": {
              "title": "Konto löschen",
              "description": "Bist du sicher, dass du dein Konto löschen möchtest? Es wird unwiederbringlich von unseren Servern gelöscht. Diese Aktion kann daher nicht rückgängig gemacht werden."
            }
          }
        }
      },
      "myTopics": {
        "form": {
          "newTopicTitle": "Neues Thema",
          "editTopicTitle": "Thema Bearbeiten",
          "subtitle": "Schritt 1: Erfasse das neue Thema, Schritt 2: Veröffentliche das neue Thema für Studierende.",
          "topic": {
            "title": {
              "title": "Titel",
              "placeholder": "Was wäre ein griffiger Titel für dein Thema?"
            },
            "description": {
              "title": "Beschreibung",
              "placeholder": "Erwartete Ergebnisse? Verfügbare Datensätze? Relevante Studienrichtungen? Gewünschte Fähigkeiten/Vorkenntnisse? Art der Zusammenarbeit?"
            },
            "requirements": {
              "title": "Pflichtanforderungen",
              "placeholder": "Erforderliche Fähigkeiten? Vorausgesetzte Kurse? Angebotene/Erforderliche Praktika oder Laborarbeit? Idealer Startzeitpunkt? Erwartete Arbeitspakete/Deliverables? Erforderliche Methoden?"
            },
            "internship": {
              "title": "Praktikum",
              "subtitle": "Bietet oder erfordert dein Unternehmen ein Praktikum in der Zusammenarbeit zur Abschlussarbeit?",
              "yes": "Erforderlich",
              "open": "Möglich",
              "no": "Nicht möglich"
            }
          },
          "more": "Mehr"
        }
      },
      "languages": {
        "en": "English",
        "de": "Deutsch"
      },
      "feedback": {
        "title": "Feedback",
        "description": "Bitte gib uns Feedback.",
        "email": "E-Mail",
        "message": "Nachricht",
        "button": "Absenden",
        "success": {
          "title": "Erfolgreich gesendet",
          "description": "Dein Feedback wurde erfolgreich abgeschickt. Wir wissen es zu schätzen und melden uns, wenn angebracht. Vielen Dank!"
        }
      },
      "level": {
        "bsc": "Bachelorarbeit",
        "msc": "Masterarbeit",
        "phd": "Dissertation"
      },
      "company": {
        "form": {
          "new": "Neues Unternehmen",
          "edit": "Firma bearbeiten",
          "subtitle": "Schritt 1: Erstelle neues Unternehmen, Schritt 2: Füge nun ein:e Besitzer:in und weitere Benutzer:innen hinzu",
          "name": {
            "label": "Name",
            "placeholder": "Wie lautet der Name des Unternehmens?"
          },
          "domains": {
            "label": "Webseite / Domäne",
            "placeholder": "Domäne"
          }
        },
        "confirmation": {
          "delete": {
            "title": "Firma löschen",
            "description": "Sind Sie sicher, dass Sie dieses Unternehmen löschen möchten? Dieses Unternehmen wird für immer von unseren Servern entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
          }
        },
        "profile": "Unser Profil",
        "experts": "Unsere Expert:innen",
        "topics": "Unsere Themen",
        "thesisProjects": "Unsere Thesis Projekte",
        "name": "Name",
        "description": "Beschreibung",
        "search": {
          "placeholder": "Unternehmen suchen"
        },
        "address": {
          "title": "Adresse",
          "country": {
            "title": "Land",
            "placeholder": "Land auswählen"
          },
          "city": "Ort",
          "street": "Strasse",
          "number": "Hausnummer",
          "postalCode": "PLZ"
        },
        "uid": "UID",
        "industries": "Branchen",
        "size": {
          "title": "Grösse",
          "type": {
            "a": "Sebstständig",
            "b": "1-10 Mitarbeitende",
            "c": "11-50 Mitarbeitende",
            "d": "51-200 Mitarbeitende",
            "e": "201-500 Mitarbeitende",
            "f": "501-1000 Mitarbeitende",
            "g": "1001-5000 Mitarbeitende",
            "h": "5001-10,000 Mitarbeitende",
            "i": "10,000+ Mitarbeitende"
          },
          "empty": "Keine Grösse",
          "select": "Grösse wählen"
        },
        "domain": {
          "title": "Domain",
          "button": "Domain hinzufügen"
        },
        "logo": "Firmenlogo",
        "notification": {
          "update": {
            "title": "Unternehmen aktualisiert",
            "description": "Unternehmensprofil erfolgreich gespeichert"
          },
          "create": {
            "title": "Das Unternehmen wurde erfolgreich erstellt",
            "description": "Füge nun ein:e Besitzer:in des Unternehmens hinzu"
          }
        },
        "topicsList": {
          "newTopic": "Thema erfassen",
          "create": {
            "title": "Keine Themen",
            "subtitle": "Mach den Anfang und erfasse ein erstes Thema."
          }
        },
        "team": {
          "title": "Benutzer:in hinzufügen",
          "subtitle": "Als Administrator:in deines Unternehmens kannst du Profilie und Berechtigungen weiterer Personen verwalten.",
          "domainValidation": "Es gehen nur E-Mail Domains wie",
          "placeholder": "Geschäfts E-Mail-Adresse eintragen mit Domains wie",
          "button": "Benutzer:in hinzufügen",
          "delete": {
            "confirmation": {
              "title": "Benutzer:in löschen",
              "description": "Bist du sicher, dass du den Experten / die Expertin löschen möchtest? Das Benutzerprofil wird definitiv von unseren Servern gelöscht und die Aktion kann nicht rückgängig gemacht werden!"
            }
          }
        }
      },
      "user": {
        "form": {
          "firstName": {
            "label": "Vorname",
            "placeholder": "Was ist der Vorname?"
          },
          "lastName": {
            "label": "Nachname",
            "placeholder": "Was ist der Nachname?"
          },
          "email": {
            "label": "E-Mail",
            "placeholder": "Was ist E-Mail?"
          }
        },
        "notification": {
          "update": {
            "title": "Benutzer aktualisiert",
            "description": "Benutzer erfolgreich aktualisiert"
          },
          "create": {
            "title": "Der Benutzer wurde erfolgreich erstellt",
            "description": "Benutzer erfolgreich erstellt"
          }
        }
      },
      "supervisor": {
        "invited": "Eingeladen von <0>{{invitedBy}}</0> am {{invited}}"
      },
      "university": {
        "profile": "Profil",
        "supervisors": "Betreuer:innen",
        "team": {
          "title": "Betreuer:in hinzufügen",
          "subtitle": "Fügen Sie Kolleg:innen hinzu, welche ThesisNavigator ebenfalls als Thesis Betreuer nutzen möchten. Wenn Sie/Ihr Institut daran interessiert sind, Hochschulpartner zu werden, nehmen Sie bitte Kontakt mit uns auf.",
          "domainValidation": "Es gehen nur E-Mail Domains wie",
          "placeholder": "Geschäfts E-Mail-Adresse eintragen mit Domains wie",
          "button": "Benutzer:in einladen",
          "delete": {
            "confirmation": {
              "title": "Benutzer:in löschen",
              "description": "Bist du sicher, dass du den Experten / die Expertin löschen möchtest? Das Benutzerprofil wird definitiv von unseren Servern gelöscht und die Aktion kann nicht rückgängig gemacht werden!"
            }
          },
          "supervisor": "Betreuer:in",
          "student": "Student:in"
        }
      },
      "banner": {
        "title": "Startfeld Diamant",
        "description": "Der Preis der St.Galler Kantonalbank (SGKB) für Jungunternehmen.",
        "action": "Stimme für uns"
      },
      "thesisProjects": {
        "title": {
          "placeholder": "Titel"
        },
        "description": {
          "title": "Beschreibung",
          "placeholder": "Beschreibung"
        },
        "motivation": {
          "title": "Meine Motivation",
          "placeholder": "Teile was dich motiviert und was du einbringen möchtest. Vielleicht hast du auch schon konkrete Vorschläge für die Umsetzung."
        },
        "cv": {
          "title": "CV",
          "button": {
            "upload": "Hochladen",
            "change": "Ändern"
          },
          "placeholder": "Keine Datei ausgewählt",
          "info": "Bitte wähle eine PDF Datei aus."
        },
        "notes": {
          "title": "Meine Verfügbarkeit für ein erstes Gespräch",
          "placeholder": "Füge hier deinen Terminbuchungs-Link (Calendly or ähnliche) oder deine üblichen Verfügbarkeiten (z.B. Montagnachmittag,...) für einen ersten 20 Minuten Video Call ein. Ziel dieses Calls ist einen Eindruck zu bekommen und die gegenseitigen Erwartungen abzugleichen.",
          "info": "Terminbuchungs-Link (<a class='text-indigo-400 font-medium' href='https://calendly.com/' target='blank'>Calendly</a> or ähnliche)"
        },
        "activity": {
          "title": "Aktivität",
          "applied": "hat sich auf <span class='font-semibold text-gray-900'>{{value}}</span> beworben",
          "updated": {
            "title": "aktualisierte:",
            "fields": {
              "title": "den Titel",
              "description": "die Beschreibung",
              "motivation": "die Motivation",
              "notes": "die Verfügbarkeit",
              "cv_file": "den CV"
            }
          },
          "updatedState": "hat den Status auf <span class='font-semibold text-gray-900'>{{value}}</span> aktualisiert",
          "commented": "kommentierte",
          "comment": {
            "placeholder": "Füge deinen Kommentar hinzu - Projektteilnehmer:innen werden benachrichtigt"
          }
        },
        "update": {
          "notification": {
            "title": "Das Thesis Project wurde erfolgreich aktualisiert",
            "description": "Bravo."
          }
        },
        "apply": {
          "notification": {
            "title": "Bewerbung erfolgreich abgeschickt",
            "description": "Gut zu wissen:<ul class=\"list-disc mt-2 ml-3 space-y-2\"><li>Die Expertin / Der Experte wurde per E-Mail über deine Bewerbung informiert</li><li>Es wurde ein Thesis Projekt im Status \"Bewerbung\" angelegt, auf welches ihr beide zugreifen könnt</li><li>ThesisNavigator informiert dich per E-Mail wenn immer sich etwas tut</li><li>Im besten Fall bekommst du direkt eine Einladung zum Call über deinen Terminbuchungs-Link</li></ul>"
          }
        },
        "state": {
          "applied": "Bewerbung",
          "withdrawn": "Bewerbung zurückgezogen",
          "rejected": "Bewerbung abgelehnt",
          "agreed": "Projekt vereinbart",
          "canceled": "Projekt abgebrochen",
          "completed": "Projekt abgeschlossen"
        },
        "steps": {
          "applied": {
            "item1": "Expert:in lädt zu Gespräch ein",
            "item2": "Kennenlernen und Projekt anpassen",
            "item3": "Student:in sucht Betreuer:in und reicht Research Proposal ein"
          },
          "agreed": {
            "item1": "Student:in schreibt Thesis",
            "item2": "Regelmässige Abstimmung"
          }
        },
        "action": {
          "state": {
            "withdrawn": "Bewerbung zurückziehen",
            "rejected": "Bewerbung ablehnen",
            "agreed": "Zusammenarbeit vereinbaren",
            "canceled": "Projekt abbrechen",
            "completed": "Projekt abschliessen"
          }
        },
        "updateState": {
          "reason": {
            "title": "Grund",
            "placeholder": "Wähle einen Grund",
            "withdrawn": {
              "applied_by_mistake": "Unbeabsichtigte Bewerbung",
              "changed_my_mind": "Meinung geändert",
              "no_response": "Keine Antwort erhalten",
              "no_match": "Hat nicht gepasst",
              "other_reason": "Anderer Grund"
            },
            "rejected": {
              "application_incomplete": "Unvollständige Bewerbung",
              "application_insufficient": "Ungenügende Bewerbung",
              "no_match": "Hat nicht gepasst",
              "other_reason": "Anderer Grund"
            },
            "canceled": {
              "inactivity": "Inaktivität",
              "disagreement": "Meinungsverschiedenheit",
              "no_supervisor_found": "Kein Betreuer gefunden",
              "other_reason": "Anderer Grund"
            }
          },
          "notes": {
            "title": "Anmerkungen",
            "placeholder": "Anmerkungen"
          }
        },
        "students": {
          "apply": "Leg los und bewirb dich auf ein Thema."
        },
        "project": {
          "title": "Projektbeschreibung"
        },
        "application": {
          "title": "Bewerbung"
        }
      },
      "error": {
        "403": {
          "title": "Verboten",
          "description": "Entschuldigung, Sie sind nicht berechtigt, auf diese Ressource zuzugreifen.",
          "button": "Zurück zum Dashboard",
          "secondaryButton": "Support kontaktieren"
        }
      },
      "activeProfile": {
        "supervisor": {
          "title": "Deine Rolle hat gewechselt",
          "description": "Du wurdest von <0>{{invitedBy}}</0> eingeladen ThesisNavigator zukünftig als Thesis Betreuer:in zu nutzen. Falls du deine Rolle ändern möchtest, kannst du dies jederzeit in deinen Einstellungen tun.",
          "continue": "Als Supervisor nutzen",
          "studentRole": "Student bleiben"
        }
      },
      "form": {
        "error": {
          "mixed": {
            "required": "Dieses Feld ist erforderlich"
          },
          "string": {
            "email": "Dieses Feld muss eine gültige E-Mail-Adresse sein",
            "min": "Dieses Feld muss mindestens {{min}} Zeichen lang sein",
            "matches": {
              "default": "Dieses Feld muss mit Folgendem übereinstimmen: \"{{regex}}\"",
              "domain": "Dieses Feld muss mit dem Domänenmuster übereinstimmen"
            }
          },
          "array": {
            "min": "Dieses Feld muss mindestens {{min}} Elemente enthalten"
          }
        }
      }
    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

setLocale(
  {
    mixed: {
      required: i18n.t("form.error.mixed.required"),
    },
    string: {
      email: () => i18n.t("form.error.string.email"),
      min: ({ min }) => i18n.t('form.error.string.min', { min }),
      matches: ({ regex }) => {
        if (regex === DOMAIN_REGEXP) {
          return i18n.t('form.error.string.matches.domain');
        }
        return i18n.t('form.error.string.matches.default', { regex });
      },
    },
    array: {
      min: ({ min }) => i18n.t('form.error.array.min', { min }),
    }
  },
);

export default i18n;
