import { useContext } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import { UserType } from "../../models";
import StudentDashboard from "../student/StudentDashboard";
import ExpertDashboard from "../expert/ExpertDashboard";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader";
import SupervisorDashboard from "../supervisor/SupervisorDashboard";
import ActiveProfile from "../ActiveProfile";

export default function Dashboard() {
  const { isAuthenticated, isLoading } = useAuth0();
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <>
      {(isLoading || (isAuthenticated && !currentUser.id)) && <div className="flex justify-center items-center"><Loader /></div>}
      {isAuthenticated && !isLoading && currentUser.id && currentUser.type === null && (<ActiveProfile />)}
      {!isAuthenticated && !isLoading && <StudentDashboard />}
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Student && <StudentDashboard />}
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Expert && <ExpertDashboard />}
      {isAuthenticated && !isLoading && currentUser && currentUser.type === UserType.Supervisor && <SupervisorDashboard />}
    </>
  );
}
