import { createContext } from "react";
import { UserType } from "../models";

export interface CurrentUserContextType {
  currentUser: {
    id: string;
    entityId: string;
    role?: string | null;
    type?: UserType | null;
    image?: string | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    company?: string | null;
    companyId?: string | null;
    university?: string | null;
    sysadmin?: boolean
  }
  setCurrentUser: (user: Partial<CurrentUserContextType["currentUser"]>) => void;
}

export const CurrentUserContext = createContext<any>({currentUser: {}, setCurrentUser: ()=> {}});
