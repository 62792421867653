import React, {PropsWithChildren} from "react";
import {Square3Stack3DIcon} from "@heroicons/react/24/solid";

// Define the prop types
interface ListNoDataProps {
  className?: string;
}

// Functional component with props
const ListNoData: React.FC<PropsWithChildren<ListNoDataProps>> = ({className = '', children}) => {
  return (
    <div className={`text-center ${className}`}>
      <Square3Stack3DIcon className="mx-auto h-12 w-12 text-gray-400"/>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        {children}
      </h3>
    </div>
  );
};

// Export the component
export default React.memo(ListNoData);
