import React, {PropsWithChildren} from "react";

// Define the prop types
interface ListItemLineProps {
  className?: string;
}

// Functional component with props
const ListItemLine: React.FC<PropsWithChildren<ListItemLineProps>> = ({className = '', children}) => {
  return (
    <div className={`flex items-start gap-x-2 text-xs text-gray-500 ${className}`}>
      {children}
    </div>
  );
};

// Export the component
export default React.memo(ListItemLine);
