import { object, string } from "yup";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../contexts/userContext";
import Loader from "../../components/Loader";
import httpsClient, { addAccessTokenInterceptor } from "../../config/https";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { CheckBadgeIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useMessagesContext, MessageType } from "../../contexts/messagesContext";
import { TextEditor } from "../../components/TipTap/TextEditor";

export const ThesisProjectFormExperts: React.FC<any> = () => {
  const { id } = useParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(false);
  const [company, setCompany] = useState<any>({});
  const [thesisProject, setThesisProject] = useState<any>({});
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isCompanyRoute = location.pathname.includes("/company/thesis-projects");
  const { addMessage } = useMessagesContext();

  const validationSchema = object().shape({
    title: string().required(t("common.required")),
    description: string().required(t("common.required")),
  });

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const getThesisProject = async () => {
    try {
      setIsFetching(true);
      const response = await httpsClient.get(`/api/v1/thesis-projects/${id}`);
      setThesisProject(response.data);
      setCompany(response.data.companies);
      setIsFetching(false);
    } catch (error: any) {
      setIsFetching(false);
      if (error?.response?.status === 403) {
        navigate("/error/forbidden");
      }
    }
  };

  const updateThesisProject = async (data: any) => {
    try {
      setIsUpdating(true);
      await httpsClient.patch(`/api/v1/thesis-projects/${id}`, {
        title: data.title,
        description: data.description,
      });
      setIsUpdating(false);
      addMessage({
        type: MessageType.Success,
        title: "thesisProjects.update.notification.title",
        description: "thesisProjects.update.notification.description",
      });
      navigate(isCompanyRoute ? `/company/thesis-projects/${id}` : `/user/thesis-projects/${id}`);
    } catch {
      setIsUpdating(false);
      addMessage({
        type: MessageType.Error,
        title: "common.error.title",
        description: "common.error.description",
      });
    }
  };

  const onCancel = () => {
    if (id) {
      navigate(isCompanyRoute ? `/company/thesis-projects/${id}` : `/user/thesis-projects/${id}`);
    }
  };

  const onSubmit = (data: any) => {
    updateThesisProject(data);
  };

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const defaultValues: any = {};
    defaultValues.topic_id = thesisProject.topic_id;
    defaultValues.title = thesisProject.title;
    defaultValues.description = thesisProject.description;
    reset({ ...defaultValues });
  }, [thesisProject]);

  useEffect(() => {
    if (id) {
      getThesisProject();
    }
  }, [currentUser]);

  return (
    <div className="bg-white">
      <nav aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex whitespace-nowrap">
              <NavLink
                to={isCompanyRoute ? `/company/profile` : `/user/profile`}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.profile") : t("common.myProfile")}
              </NavLink>
            </div>
          </li>
          <li>
            <div className="flex whitespace-nowrap items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <NavLink
                to={isCompanyRoute ? "/company/thesis-projects" : "/user/thesis-projects"}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {isCompanyRoute ? t("company.thesisProjects") : t("common.myThesisProjects")}
              </NavLink>
            </div>
          </li>
          {id && !isFetching && (
            <li className="overflow-hidden">
              <div className="flex whitespace-nowrap items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <NavLink
                  to={isCompanyRoute ? `/company/thesis-projects/${id}` : `/user/thesis-projects/${id}`}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 truncate"
                >
                  {thesisProject?.title}
                </NavLink>
              </div>
            </li>
          )}
        </ol>
      </nav>
      {isFetching && (
        <div className="flex justify-center items-center mt-6">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <form autoComplete="off">
          <div>
            <div className="py-6">
              <div className="flex items-start gap-x-6">
                {company?.picture && (
                  <img
                    className="h-16 w-16 object-contain"
                    src={`https://resources.thesisnavigator.com/${company?.picture}`}
                    alt=""
                  />
                )}
                <div className="w-full">
                  <NavLink
                    to={`/companies/${thesisProject?.companies?.id}`}
                    className="whitespace-nowrap flex items-center gap-x-1 mb-2 hover:opacity-75"
                  >
                    <span className="font-semibold text-xs text-gray-900">{thesisProject?.companies?.name}</span>{" "}
                    <CheckBadgeIcon className="h-5 text-yellow-400" />
                  </NavLink>
                  <div>
                    <input
                      type="text"
                      autoComplete="given-name"
                      placeholder={t("thesisProjects.title.placeholder")}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
                      {...register("title")}
                    />
                    {errors.title && <p className="mt-2 text-xs text-red-600">{errors.title?.message?.toString()}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <div className="sm:col-span-full">
                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                  {t("thesisProjects.description.title")}
                </label>
                <div className="mt-2">
                  {/* <textarea
                    rows={8}
                    placeholder={t("thesisProjects.description.placeholder")}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6 whitespace-pre-line"
                    defaultValue={""}
                    {...register("description")}
                  /> */}
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <TextEditor
                        content={field.value}
                        placeholder={t("thesisProjects.description.placeholder")}
                        onChange={field.onChange}
                      />
                    )}
                    name="description"
                  />
                  {errors.description && (
                    <p className="mt-2 text-xs text-red-600">{errors.description?.message?.toString()}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            {isUpdating && <Loader />}
            {!isUpdating && (
              <>
                <button type="button" onClick={onCancel} className="text-sm font-semibold leading-6 text-gray-900">
                  {t("common.cancel")}
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {id ? t("common.save") : t("common.apply")}
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
