import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "moment/locale/de";
import Api from '../../../api/client';
import { Company, CompanyPage } from "@api/generated";
import {
  List,
  ListItem,
  ListItemActions,
  ListItemAvatar,
  ListItemContent,
  ListItemLine,
  ListItemLineEnum,
  ListItemLineStatus,
  ListItemTitle,
  ListNoData,
  ListPagination
} from "../../../components/list";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import ConfirmationDialog, { ConfirmationDialogType } from "../../../components/ConfirmationDialog";
import Modal from "../../../components/Modal";

export default function AdminCompanies() {

  const { isAuthenticated, isLoading } = useAuth0();
  const [isFetching, setIsFetching] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [companyPage, setCompanyPage] = useState<CompanyPage | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  // Search Parameters
  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const pageParam = searchParams.get("page");
  const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  const [page, setPage] = useState<number>(parsedPage);

  // Delete Confirmation
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);


  // Refs
  const scrollRef: any = useRef(null);

  const scrollToTop = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const getCompanies = async () => {
    setIsFetching(true);
    setCompanyPage(undefined);
    const companySearch = search.trim() !== '' ? search.trim() : undefined;
    const response = await Api.AdminCompanies.findAllAdminCompanies(companySearch, page);
    setCompanyPage(response.data);
    setIsFetching(false);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(Math.max(page - 1, 1));
  };

  useEffect(() => {
    getCompanies();
    scrollToTop();

    // Update URL
    const params = new URLSearchParams();
    if (search !== '') {
      params.append("search", search.trim());
    }
    params.append("page", page.toString());
    navigate(`?${params.toString()}`);
  }, [page]);

  // useEffect(() => {
  //   console.log("location", location);
  //   const searchParams = new URLSearchParams(location.search);
  //   const search = searchParams.get("search") || "";
  //   const pageParam = searchParams.get("page");
  //   const parsedPage = pageParam ? parseInt(pageParam, 10) || 1 : 1;
  //   setSearch(search);
  //   setPage(parsedPage);
  //   getCompanies();
  // }, [location]);

  // Search
  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearch = async () => {
    setPage(1);
    const params = new URLSearchParams();
    if (search) {
      params.append("search", search.trim());
    }
    params.append("page", page.toString());
    navigate(`?${params.toString()}`);
    await getCompanies();
  };

  const handleReloadCompanies = async () => {
    if (page > 1) {
      setPage(1);
    } else {
      await getCompanies();
    }
  };

  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  async function deleteCompany() {
    closeDeleteConfirmation();
    if (selectedCompany) {
      try {
        await Api.AdminCompanies.deleteAdminCompany(selectedCompany.id)
        await getCompanies();
      } catch (e) {
        console.error(e);
      }
    }
  }

  return (
    <div className="bg-white" ref={scrollRef}>
      <div className="pb-6 sm:pb-6">
        <div className="mt-2 flex items-center justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl tracking-tight">
              {t("common.companies")}
            </h2>
          </div>
        </div>
      </div>
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          {t("common.filters")}
        </h2>
        <div className="flex space-x-6 justify-between items-center">
          <div className="relative flex items-center w-70 sm:w-none w-full">
            <input
              type="search"
              enterkeyhint="search"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder={t("company.search.placeholder")}
              className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <button
                onClick={() => {
                  setPage(1);
                  handleSearch();
                }}
                className="inline-flex items-center px-1 text-xs text-gray-400 hover:text-gray-500 font-medium"
              >
                <MagnifyingGlassIcon className="w-5" />
              </button>
            </div>
          </div>
          <div className="flex space-x-6 justify-end items-center">
            <div className="flex flex-shrink-0">
              <NavLink
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 overflow-hidden"
                to="/admin/companies/new"
              >
                <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                <span className="truncate max-w-16 sm:max-w-none">{t("company.form.new")}</span>
              </NavLink>
            </div>
            <button
              type="button"
              className="h-6 w-6 text-gray-400 hover:text-gray-600"
              onClick={() => handleReloadCompanies()}
            >
              <ArrowPathIcon />
            </button>
          </div>
        </div>
        <div className="border-b border-gray-100 bg-white pb-6 mb-6"></div>
      </section>
      {(isFetching || isLoading) && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}
      {isAuthenticated && !isLoading && !isFetching && (
        <List className="[&>*:first-child]:pt-0 items-start">
          {
            companyPage?.items.map((company) => (
              <ListItem key={company.id}>
                <ListItemAvatar avatar={company.picture}></ListItemAvatar>
                <ListItemContent className="items-start">
                  <ListItemLine>
                    <ListItemLineEnum items={[
                      company.address?.city,
                      company.size ? t(`company.size.type.${company.size}`) : null
                    ]}></ListItemLineEnum>
                  </ListItemLine>
                  <NavLink to={`/admin/companies/${company.id}`}><ListItemTitle>{company.name}</ListItemTitle></NavLink>
                  <ListItemLineStatus created={company.created} updated={company.updated} experts={company._count?.experts}></ListItemLineStatus>
                </ListItemContent>
                <ListItemActions>
                  <Menu as="div" className="relative flex-none">
                    <MenuButton className="-m-2.5 block pl-2.5 text-gray-500 hover:text-gray-900">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                    <MenuItems anchor="bottom end"
                      className="mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <MenuItem>
                        <button
                          className={"block w-full px-3 py-1 text-sm leading-6 text-left hover:bg-gray-50"}
                          onClick={() => {
                            navigate(`/admin/companies/${company.id}`)
                          }}>
                          {t("common.edit")}
                        </button>
                      </MenuItem>
                      <MenuItem >
                        <button
                          className={"block w-full px-3 py-1 text-sm leading-6 text-red-600 text-left hover:bg-gray-50 disabled:opacity-75 disabled:cursor-not-allowed"}
                          disabled={company._count.experts > 0 || company._count.topics > 0}
                          onClick={() => {
                            setSelectedCompany(company);
                            setIsDeleteConfirmationOpen(true);
                          }}>
                          {t("common.delete")}
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </ListItemActions>
              </ListItem>
            ))
          }
        </List>
      )}
      {isAuthenticated && !isLoading && !isFetching && companyPage?.items.length === 0 && (
        <ListNoData>
          {t("common.noCompanies")}
        </ListNoData>
      )}
      {
        !isLoading && !isFetching && companyPage && companyPage.total > 0 && (
          <ListPagination
            page={companyPage.page}
            size={companyPage.size}
            total={companyPage.total}
            totalPage={companyPage.totalPages}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            singularKey="common.company"
            pluralKey="common.companies"
          />
        )
      }
      <Modal isOpen={isDeleteConfirmationOpen} onClose={closeDeleteConfirmation}>
        <ConfirmationDialog
          type={ConfirmationDialogType.Delete}
          title={`${t("company.confirmation.delete.title")} (${selectedCompany?.name})`}
          body={t("company.confirmation.delete.description")}
          cancel={t("common.cancel")}
          save={t("common.delete")}
          onCancel={closeDeleteConfirmation}
          onSave={deleteCompany}
        />
      </Modal>
    </div>
  );
}
