import { useContext } from "react";
import { CurrentUserContext } from "../contexts/userContext";
import { UserType } from "../models";
import Expert from "./expert/Expert";
import Student from "./student/Student";
import Supervisor from "./supervisor/Supervisor";

export default function Profile() {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      {currentUser && currentUser.type === UserType.Student && <Student />}
      {currentUser && currentUser.type === UserType.Expert && <Expert />}
      {currentUser && currentUser.type === UserType.Supervisor && <Supervisor />}
    </>
  );
}
