import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = (props: any) => {
  const videoRef: any = React.useRef(null);
  const playerRef: any = React.useRef(null);
  const { options, onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
      player.playbackRates([1, 1.2, 1.5]);

    //   player.on('timeupdate', function() {
    //     // timestamps
    //     console.log('currentTime: ', player.currentTime.);
    //     console.log('duration: ', player.duration);
    
    //     // stream data
    //     // console.log('array of blobs: ', player.);
    //     // or construct a single blob:
    //     // var blob = new Blob(blobs, {
    //     //     type: 'video/webm'
    //     // });
    // });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);

    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="border border-gray-100" data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
