import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import i18n from "../i18n";

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const currentLanguage = i18n.language;

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: { ui_locales: currentLanguage, screen_hint: "login" },
    });
  }, []);

  return <></>;
}
